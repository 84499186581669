import ImageWrapper from "@/shared/components/ImageWrapper";
import TextWrapper from "@/shared/components/TextWrapper";
import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";


interface FeatureItemProps {
  id: number;
  title: string;
  description: string;
  images: any;
  reverse?: boolean;
  editablePath?: string;
}

function FeatureItem({ id, title, description, images, reverse = false, editablePath }: FeatureItemProps) {
  return(
    <div
    className={`flex flex-col ${reverse ? 'md:flex-row-reverse' : 'md:flex-row'} gap-8 md:gap-20 mt-20 md:mt-28 first:mt-0`}
    >
      <div className="relative w-full md:w-[60%]">
        <ImageWrapper
          src={images.items[id - 1].src}
          styles={images.items[id - 1].styles}
          alt="hero"
          className="w-full !aspect-[16/9]"
          loading="lazy"
          editable={
            editablePath
              ? {
                  type: 'image',
                  path: `${editablePath}.images.items[${id - 1}]`,
                }
              : undefined
          }
          animation="none"
        />
      </div>
      <div className="flex flex-col justify-center md:flex-1 gap-4 md:gap-8">
        <TextWrapper
          content={title}
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.content.items[${ id - 1 }].title`
          } : undefined}
        />
        <TextWrapper
          content={description}
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.content.items[${ id - 1 }].description`
          } : undefined}
        />
      </div>
    </div>
  )
}

const Features03 = ({
  content,
  images,
  editablePath
}: any) => {
  const groupId = useGroupAnimation(100);

  return (
    <div>
      {content.items.map((item: any, index: any) => (
        <FeatureItem
          key={item.id}
          id={index + 1}
          title={item.title}
          description={item.description}
          images={images}
          reverse={index % 2 !== 1}
          editablePath={editablePath}
        />
      ))}
    </div>
  );
 };

export default Features03;


