import React, { useState } from 'react';
import { Plus, Trash2 } from 'lucide-react';

interface FaqItem {
  id: string;
  question: string;
  answer: string;
}

interface FaqContent {
  items: FaqItem[];
}

interface FaqEditorProps {
  content: FaqContent;
  onChange: (content: FaqContent) => void;
}

const FaqEditor: React.FC<FaqEditorProps> = ({ content, onChange }) => {
  const [faqData, setFaqData] = useState<FaqContent>(content);

  // 새 FAQ 항목 추가
  const addFaqItem = () => {
    const newItem: FaqItem = {
      id: `faq_${Date.now()}`,
      question: '새로운 질문',
      answer: '새로운 답변'
    };

    const updatedItems = [...faqData.items, newItem];
    const updatedContent = { ...faqData, items: updatedItems };
    setFaqData(updatedContent);
    onChange(updatedContent);
  };

  // FAQ 항목 삭제
  const removeFaqItem = (index: number) => {
    const updatedItems = faqData.items.filter((_, idx) => idx !== index);
    const updatedContent = { ...faqData, items: updatedItems };
    setFaqData(updatedContent);
    onChange(updatedContent);
  };

  // FAQ 항목 수정
  const updateFaqItem = (index: number, field: 'question' | 'answer', value: string) => {
    const updatedItems = faqData.items.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [field]: value
        };
      }
      return item;
    });

    const updatedContent = { ...faqData, items: updatedItems };
    setFaqData(updatedContent);
    onChange(updatedContent);
  };

  return (
    <div className="w-full space-y-6">
      <div className="space-y-4">
        {faqData.items.map((item, index) => (
          <div key={item.id} className="border rounded-lg p-4 bg-gray-50 shadow-sm">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium">Q&A {index + 1}</h3>
              <button
                onClick={() => removeFaqItem(index)}
                className="p-2 text-red-600 hover:bg-red-50 rounded"
                title="FAQ 항목 삭제"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  질문
                </label>
                <input
                  type="text"
                  value={item.question}
                  onChange={(e) => updateFaqItem(index, 'question', e.target.value)}
                  className="w-full py-2 px-3 border rounded"
                  placeholder="질문을 입력하세요"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  답변
                </label>
                <textarea
                  value={item.answer}
                  onChange={(e) => updateFaqItem(index, 'answer', e.target.value)}
                  className="w-full py-2 px-3 border rounded h-32 resize-none"
                  placeholder="답변을 입력하세요"
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-end">
        <button
          onClick={addFaqItem}
          className="flex items-center gap-2 text-blue-600 hover:text-blue-700"
        >
          <Plus className="w-5 h-5" />
          <span>새로운 FAQ 추가</span>
        </button>
      </div>
    </div>
  );
};

export default FaqEditor;