import { loadTossPayments } from '@tosspayments/tosspayments-sdk';
import { useEffect, useState } from 'react';

const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY_DEPLOY || '';

interface PaymentInstance {
  requestBillingAuth: (options: any) => Promise<void>;
}

export const useTossPayment = () => {
  const [payment, setPayment] = useState<PaymentInstance | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const initializePayment = async () => {
      try {
        const tossPayments = await loadTossPayments(clientKey);
        const paymentInstance = tossPayments.payment({
          customerKey: `CUSTOMER_${Date.now()}`,
        });
        setPayment(paymentInstance);
        setIsLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Payment initialization failed'));
        setIsLoading(false);
      }
    };

    initializePayment();
  }, []);

  const requestPayment = async ({
    customerEmail,
    customerName,
  }: {
    customerEmail: string;
    customerName: string;
  }) => {
    if (!payment) {
      throw new Error('Payment not initialized');
    }

    try {
      await payment.requestBillingAuth({
        method: "CARD",
        customerEmail,
        customerName,
        successUrl: `${window.location.origin}/admin/payment/success`,
        failUrl: `${window.location.origin}/admin/payment/fail`,
      });
    } catch (err) {
      throw err instanceof Error ? err : new Error('Payment request failed');
    }
  };

  return {
    payment,
    isLoading,
    error,
    requestPayment,
  };
};