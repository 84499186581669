import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import { useIsMobile } from '@/shared/hooks/useIsMobile';
import ImageWrapper from '@/shared/components/ImageWrapper';
import { useEffect } from 'react';
 

const Portfolio01 = ({
  images,
  editablePath
 }: any) => {
  const isMobile = useIsMobile();

  // !!! caution !!!
  // add css .swiper-wrapper in index.css

  const firstRowConfig = {
    modules: [Autoplay],
    slidesPerView: isMobile ? 1 : 3,
    spaceBetween: isMobile ? 10 : 20,
    loop: true,
    loopAdditionalSlides: 1,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    speed: 8000,
  };

  const secondRowConfig = {
    modules: [Autoplay],
    slidesPerView: isMobile ? 3 : 6,
    spaceBetween: isMobile ? 10 : 20,
    loop: true,
    loopAdditionalSlides: 1,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    speed: 3000,
  };

  return (
    <div className="flex flex-col gap-3 md:gap-6 overflow-hidden py-3 md:py-6 bg-gradient-to-r from-[#454545]/25 to-[#ffbf00]/25">
      <div className="w-full z-0">
        <Swiper {...firstRowConfig} className="w-full swiper-wrapper">
        {images.items.map((item: any, index: any) => (
            <SwiperSlide key={`top-${index}`}>
              <div className="relative w-full aspect-video overflow-hidden rounded-md md:rounded-lg">
                <ImageWrapper
                  src={item.src}
                  styles={item.styles}
                  alt={`portfolio-${index}`}
                  className="w-full aspect-video"
                  loading="lazy"
                  editable={editablePath ? {
                    type: 'image',
                    path: `${editablePath}.images.items[${index}]`
                  } : undefined}
                  animation="none"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {isMobile &&
        <div className="w-full">
          <Swiper
            {...secondRowConfig}
            autoplay={{
              ...secondRowConfig.autoplay,
              reverseDirection: true
            }}
            className="h-full swiper-wrapper"
          >
            {images.items.map((item: any, index: any) => (
              <SwiperSlide key={`bottom-${index}`}>
                <div className="relative w-full aspect-video h-full overflow-hidden rounded-sm">
                  <ImageWrapper
                    src={item.src}
                    styles={item.styles}
                    alt={`portfolio-${index}`}
                    className="w-full h-full"
                    loading="lazy"
                    editable={editablePath ? {
                      type: 'image',
                      path: `${editablePath}.images.items[${index}]`
                    } : undefined}
                    animation="none"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      }
    </div>
  );
};

export default Portfolio01;