import ImageWrapper from "@/shared/components/ImageWrapper";
import TextWrapper from "@/shared/components/TextWrapper";
import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";
import ButtonWrapper from "@/shared/components/ButtonWrapper";


const Hero03 = ({
  content,
  images,
  buttons,
  editablePath,
}: any) => {
  const groupId = useGroupAnimation(50);

  return (
    <section className="h-[85vh] relative flex items-center justify-center">
      <ImageWrapper
        src={images.background.src}
        styles={images.background.styles}
        alt="hero"
        className="!absolute inset-0 w-full"
        loading="eager"
        editable={editablePath ? {
          type: 'image',
          path: `${editablePath}.images.background`
        } : undefined}
        animation="none"
      />

      <div className="relative flex flex-col items-center gap-8 md:gap-9 px-4">
        <ImageWrapper
          src={images.main.src}
          styles={images.main.styles}
          alt="logo"
          className="w-[150px] h-auto"
          loading="eager"
          editable={editablePath ? {
            type: 'image',
            path: `${editablePath}.images.main`
          } : undefined}
          />
        <TextWrapper
          content={content.title}
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.content.title`
          } : undefined}
          animation={{
            type: 'slideUp',
            groupId: groupId,
            duration: 1000,
          }}
        />
        <TextWrapper
          content={content.description}
          editable={editablePath ? {
            type: 'richText',
            path: `${editablePath}.content.description`
          } : undefined}
          animation={{
            type: 'slideUp',
            groupId: groupId,
          }}
        />
        

        <div className="mt-2 md:mt-4 flex flex-col md:flex-row gap-5 md:gap-6 w-full md:w-auto">
          <ButtonWrapper
            content={buttons[0]}
            className="px-6 py-3 min-w-52"
            editable={editablePath ? {
              type: 'button',
              path: `${editablePath}.buttons[0]`
            } : undefined}
            animation="none"
          />
          <ButtonWrapper
            content={buttons[1]}
            className="px-6 py-3 min-w-52"
            editable={editablePath ? {
              type: 'button',
              path: `${editablePath}.buttons[1]`
            } : undefined}
            animation="none"
          />
        </div>
      </div>
    </section>
  );
 };
 
 export default Hero03;