import Header from '@/builder/components/admin/Header';
import PostTable from '@/builder/components/posts/PostTable';
import { useTemplateStore } from '@/shared/stores/templateStore';
import { PlusCircle } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePosts } from '@/builder/queries/postQueries';
import NotFoundTemplate from '@/builder/components/admin/NotFoundTemplate';
import NotFoundUser from '@/builder/components/admin/NotFoundUser';
import ExpiredTemplate from '@/builder/components/admin/ExpiredTemplate';

export const POSTS_PER_PAGE = 5;

const PageContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="space-y-8 max-w-5xl mx-auto">
    <Header
      title="게시물 관리"
      subtitle="홈페이지에 들어가는 컨텐츠를 관리하세요"
    />
    {children}
  </div>
);

const PostTableContainer = ({
  data,
  isLoading,
  currentPage,
  setCurrentPage,
  handleCreatePost,
}: any) => (
  <div className="bg-white rounded-xl shadow-lg p-6">
    <div className="flex justify-between items-center mb-6">
      <h2 className="text-xl font-bold">글목록</h2>
      <button
        onClick={handleCreatePost}
        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center space-x-2"
      >
        <PlusCircle className="w-5 h-5" />
        <span>글쓰기</span>
      </button>
    </div>
    <PostTable
      posts={data?.posts || []}
      pagination={data?.pagination}
      isLoading={isLoading}
      currentPage={currentPage}
      onPageChange={setCurrentPage}
    />
  </div>
);

const PostList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = usePosts(currentPage, POSTS_PER_PAGE);
  const { templates, selectedTemplate } = useTemplateStore();

  const handleCreatePost = () => {
    navigate('/admin/posts/create');
  };

  if (!templates || templates.length === 0) {
    return (
      <PageContainer>
        <NotFoundUser />
      </PageContainer>
    );
  }

  if (!selectedTemplate?.templateId) {
    return (
      <PageContainer>
        <NotFoundTemplate />
      </PageContainer>
    );
  }

  if (selectedTemplate.status === 'inactive') {
    return (
      <div className="space-y-8 max-w-6xl mx-auto">
        <PageContainer>     
          <ExpiredTemplate />
        </PageContainer>
      </div>
    )
  }

  return (
    <PageContainer>
      <PostTableContainer
        data={data}
        isLoading={isLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleCreatePost={handleCreatePost}
      />
    </PageContainer>
  );
};

export default PostList;

// import Header from '@/builder/components/admin/Header';
// import PostTable from '@/builder/components/posts/PostTable';
// import { useTemplateStore } from '@/shared/stores/templateStore';
// import { PlusCircle } from 'lucide-react';
// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { usePosts } from '@/builder/queries/postQueries';
// import NotFoundTemplate from '@/builder/components/admin/NotFoundTemplate';

// export const POSTS_PER_PAGE = 5;

// const PostList = () => {
//   const navigate = useNavigate();
//   const [currentPage, setCurrentPage] = useState(1);
//   // const selectedTemplate = useTemplateStore((state) => state.selectedTemplate);
//   // const selectedTemplateId = useTemplateStore((state) => state.selectedTemplateId);
//   const { data, isLoading, error } = usePosts(currentPage, POSTS_PER_PAGE);
//   const { selectedTemplate } = useTemplateStore();


//   const handleCreatePost = () => {
//     navigate('/admin/posts/create');
//   };


//   if (!selectedTemplate?.templateId) {
//   // if (!selectedTemplateId) {
//     return(      
//       <div className="space-y-8 max-w-4xl mx-auto">
//         <Header
//           title= '게시물 관리'
//           subtitle= '홈페이지에 들어가는 컨텐츠를 관리하세요'
//         />
//         <NotFoundTemplate />
//       </div>
//     )
//   }

//   return (
//     <div className="space-y-8 max-w-5xl mx-auto">
//       <Header
//         title= '게시물 관리'
//         subtitle= '홈페이지에 들어가는 컨텐츠를 관리하세요'
//       />
//       <div className="bg-white rounded-xl shadow-lg p-6">
//         <div className="flex justify-between items-center mb-6">
//           <h2 className="text-xl font-bold">글목록</h2>
//           <button
//             onClick={handleCreatePost}
//             className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center space-x-2"
//           >
//             <PlusCircle className="w-5 h-5" />
//             <span>글쓰기</span>
//           </button>
//         </div>
//         <PostTable
//           posts={data?.posts || []}
//           pagination={data?.pagination}
//           isLoading={isLoading}
//           currentPage={currentPage}
//           onPageChange={setCurrentPage}
//         />
//       </div>
//     </div>
//   );
// };

// export default PostList;