import { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImagePicker from '../ImageEditor/ImagePicker';
import { registerQuillModules, sizeHandler, SIZE_MAP } from './quillModules';
import { quillStyles } from './styles';

// TextEditor 인터페이스
export interface TextEditorProps {
  content: string;
  type: 'plainText' | 'richText';
  onChange: (content: string) => void;
  enableImages?: boolean;
}

// 컴포넌트 초기화 시 모듈 등록
registerQuillModules();

const TextEditor = ({
  content,
  type,
  onChange,
  enableImages = false
}: TextEditorProps) => {

  const [value, setValue] = useState(content);
  const [showImagePicker, setShowImagePicker] = useState(false);
  const quillRef = useRef<ReactQuill>(null);

  // 값이 비어있는지 확인하는 함수
  const isEmpty = (str: string) => {
    return !str || str.replace(/<[^>]*>/g, '').trim() === '';
  };

  // useEffect(() => {
  //   if (!isEmpty(value)) {
  //     onChange(value);
  //   }

    
  //   return () => {
  //     if (isEmpty(value)) {
  //       onChange('텍스트를 입력해주세요');
  //     }
  //   };
  // }, [value]);

  // 1. 초기 content 값 확인
  useEffect(() => {
    console.log('Initial content:', content);
  }, []);

  // 2. value 상태가 변경될 때마다 확인
  useEffect(() => {
    console.log('Value changed:', {
      value,
      valueType: typeof value,
      hasEscapeChars: value.includes('\\')
    });

    if (!isEmpty(value)) {
      console.log('Before onChange call:', value);
      onChange(value);
      console.log('After onChange call');
    }
    
    return () => {
      if (isEmpty(value)) {
        console.log('Cleanup - empty value');
        onChange('텍스트를 입력해주세요');
      }
    };
  }, [value]);




  

  useEffect(() => {
    if (enableImages && quillRef.current) {
      const imageButton = document.querySelector('.ql-image');
      
      if (imageButton) {
        const handleClick = (e: Event) => {
          e.preventDefault();
          e.stopPropagation();
          setShowImagePicker(true);
        };

        imageButton.removeEventListener('click', handleClick, true);
        imageButton.addEventListener('click', handleClick, true);

        return () => {
          imageButton.removeEventListener('click', handleClick, true);
        };
      }
    }
  }, [enableImages]);

  const handleImageSelect = (url: string) => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const range = editor.getSelection(true);
      const length = editor.getLength();
      const index = range ? range.index : length;
      
      editor.insertEmbed(index, 'image', url);
      editor.setSelection(index + 1, 0);
    }
    setShowImagePicker(false);
  };

  const handleColor = useCallback(() => {
    const toolbar: any = document.querySelector('.ql-toolbar');
    const colorButton = toolbar?.querySelector('.ql-color');
    
    if (!colorButton) return;
    
    const input = document.createElement('input');
    input.type = 'color';
    input.style.position = 'absolute';
    input.style.opacity = '0';
    input.style.height = '0';
    
    const buttonRect = colorButton.getBoundingClientRect();
    const toolbarRect = toolbar.getBoundingClientRect();
    
    input.style.left = `${buttonRect.left - toolbarRect.left}px`;
    input.style.top = `${buttonRect.height}px`;
    input.style.zIndex = '1000';
    
    toolbar.appendChild(input);
    
    setTimeout(() => {
      input.style.visibility = 'visible';
      input.click();
    }, 0);
    
    const handleChange = () => {
      const quill = quillRef.current?.getEditor();
      const range = quill?.getSelection();
      
      if (range && quill) {
        quill.format('color', input.value);
      }
      input.remove();
    };

    input.addEventListener('change', handleChange);
  }, []);

  const formats = [
    'weight',
    'italic', 'underline',
    'size', 'lineHeight', 'letterSpacing', 'color', 'background', 'align', 'textAlign',
    'image'
  ];

  const getToolbarOptions = () => {
    const baseOptions = [
      ['italic', 'underline'],
      [{ 'weight': ['굵기', '300', '400', '500', '600', '700', '800'] }],
      [{ 'size': ['크기', ...Object.keys(SIZE_MAP)] }],
      [{ 'lineHeight': ['높이', '1', '1.2', '1.5', '1.8', '2.0'] }],
      [{ 'letterSpacing': ['자간', '-1em', '-0.05em', '-0.025em', '0', '0.025em', '0.05em', '0.1em'] }],
      ['color'],
      [{ 'textAlign': ['정렬', 'left', 'center', 'right'] }],
    ];

    if (enableImages) {
      baseOptions.push(['image']);
    }

    return baseOptions;
  };

  const modules = {
    toolbar: type === 'plainText' 
      ? false
      : {
          container: getToolbarOptions(),
          handlers: {
            color: handleColor,
            size: sizeHandler,
          },
        },
    customClipboard: true
  };

  return (
    <div className="w-full">
      <style>{quillStyles}</style>
      <div className="relative">
        <ReactQuill 
          ref={quillRef}
          theme='snow'
          value={value} 
          onChange={setValue}
          modules={modules}
          formats={type === 'plainText' ? [] : formats}
          style={{ height: type === 'plainText' ? '150px' : '375px' }}
          className="editor-container"
          preserveWhitespace
        />

        {showImagePicker && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <ImagePicker
              onSelect={handleImageSelect}
              onClose={() => setShowImagePicker(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TextEditor;