import { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const GuideSlide = ({ text, imagePath }: { text: string; imagePath: string }) => (
  <div className="space-y-4">
    <p className="text-gray-700">{text}</p>
    <div className="border rounded-lg overflow-hidden">
      <img 
        src={imagePath} 
        alt="도메인 구입 가이드" 
        className="mx-auto w-[800px] h-auto"
      />
    </div>
  </div>
);

const DomainPurchaseGuide = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      text: "1. 닷홈 사이트에 접속하여 회원가입을 해주세요.\nhttps://www.dothome.co.kr/",
      imagePath: "/images/domain/dothome01.webp"
    },
    {
      text: "2. 등록하실 도메인을 입력한 뒤에 검색을 눌러주세요.\n(아래는 예시 도메인입니다. 반드시 회원이 구매하실 도메인을 입력해주세요.)",
      imagePath: "/images/domain/dothome02.webp"
    },
    {
      text: "3. 원하는 도메인을 선택한 뒤에 신청하기를 눌러주세요.\n(이미 등록된 도메인의 경우 신청이 불가합니다.)",
      imagePath: "/images/domain/dothome03.webp"
    },
    {
      text: "4. 쿠폰을 적용한 뒤에 아래의 내용을 입력해주세요 > 다음단계 클릭",
      imagePath: "/images/domain/dothome04.webp"
    },
    {
      text: "5. 관리정보입력 - 담당자 정보\n(보통 회사의 대표자 정보를 입력하면 됩니다)",
      imagePath: "/images/domain/dothome05.webp"
    },
    {
      text: "6. 소유자 정보 입력\n(개인을 선택하여 대표자 정보를 적거나, 기관을 선택하여 회사의 정보를 입력하셔도 됩니다)",
      imagePath: "/images/domain/dothome06.webp"
    },
    {
      text: "7. 관리자 정보가 소유자 정보와 동일하다면 체크버튼을 누릅니다.",
      imagePath: "/images/domain/dothome07.webp"
    },
    {
      text: "8. 닷홈 네임서버 사용하도록 체크버튼을 누릅니다 > 다음 단계",
      imagePath: "/images/domain/dothome08.webp"
    },
    {
      text: "9. 도메인 정보를 최종확인한 뒤에 결제하기를 눌러주세요.",
      imagePath: "/images/domain/dothome09.webp"
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => 
      prev === slides.length - 1 ? prev : prev + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => 
      prev === 0 ? prev : prev - 1
    );
  };

  return (
    <div className="space-y-4">
      <GuideSlide 
        text={slides[currentSlide].text}
        imagePath={slides[currentSlide].imagePath}
      />
      
      <div className="flex items-center justify-between mt-4">
        <button
          onClick={prevSlide}
          disabled={currentSlide === 0}
          className={`flex items-center space-x-2 px-4 py-2 rounded-lg border ${
            currentSlide === 0 
              ? 'text-gray-400 border-gray-200 cursor-not-allowed'
              : 'text-gray-700 border-gray-300 hover:bg-gray-50'
          }`}
        >
          <ChevronLeft className="w-5 h-5" />
          <span>이전</span>
        </button>

        <div className="text-sm text-gray-500">
          {currentSlide + 1} / {slides.length}
        </div>

        <button
          onClick={nextSlide}
          disabled={currentSlide === slides.length - 1}
          className={`flex items-center space-x-2 px-4 py-2 rounded-lg border ${
            currentSlide === slides.length - 1
              ? 'text-gray-400 border-gray-200 cursor-not-allowed'
              : 'text-gray-700 border-gray-300 hover:bg-gray-50'
          }`}
        >
          <span>다음</span>
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default DomainPurchaseGuide;