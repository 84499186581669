import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Plus, X, ChevronDown } from 'lucide-react';
import throttle from 'lodash/throttle';

interface NavigationItem {
  name: string;
  path: string;
  children?: NavigationItem[]; 
  visible: boolean;
}

interface MenuItemsProps {
  navigation: NavigationItem[];
  isOpen: boolean;
  onClose: () => void;
}

const MenuItems = ({ navigation, isOpen, onClose }: MenuItemsProps) => {
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);

  const handleSubmenuClick = (path: string) => {
    setOpenSubmenu(openSubmenu === path ? null : path);
  };

  return (
    <div 
      className={`
        absolute inset-x-0 top-0 bg-black/90 z-40
        transition-all duration-700 ease-in-out
        ${isOpen ? 'h-screen opacity-100' : 'h-0 opacity-0'}
        overflow-hidden
      `}
    >
      <div className="max-w-screen-xl mx-auto px-5 pt-24">
        <ul className="space-y-0">
          {navigation.map((item, index) => (
            item.visible && (
              <li 
                key={item.path} 
                className={`
                  text-white 
                  border-t border-white/20
                  ${index === navigation.length - 1 ? 'border-b' : ''}
                `}
              >
                {item.children ? (
                  <div className="py-6 px-2 md:px-8">
                    <button
                      onClick={() => handleSubmenuClick(item.path)}
                      className="text-xl md:text-2xl font-light hover:text-gray-300 transition-colors w-full text-left flex items-center justify-between"
                    >
                      {item.name}
                      <ChevronDown 
                        className={`
                          transition-transform duration-300 ml-2
                          ${openSubmenu === item.path ? 'rotate-180' : ''}
                        `}
                      />
                    </button>
                    <div 
                      className={`
                        overflow-hidden transition-all duration-500
                        ${openSubmenu === item.path ? 'max-h-96 opacity-100 mt-4' : 'max-h-0 opacity-0'}
                      `}
                    >
                      <ul className="space-y-4 pl-4 md:pl-8">
                        {item.children.map((child) => (
                          child.visible && (
                            <li key={child.path}>
                              <Link
                                to={child.path}
                                onClick={onClose}
                                className="text-xl font-light text-gray-300 hover:text-white transition-colors block"
                              >
                                {child.name}
                              </Link>
                            </li>
                          )
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <Link
                    to={item.path}
                    onClick={onClose}
                    className="text-xl md:text-2xl px-2 md:px-8 font-light hover:text-gray-300 transition-colors block py-6"
                  >
                    {item.name}
                  </Link>
                )}
              </li>
            )
          ))}
        </ul>
      </div>
    </div>
  );
};

const Header02 = ({
  navigation,
  footer
}: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header
        className="absolute w-full z-50 top-0 left-0"
      >
        <div className="max-w-screen-xl mx-auto px-4 py-5 flex justify-between items-center">
          <div></div>
          <button
            onClick={toggleMenu}
            className="text-white/70 hover:text-gray-400 transition-colors p-1 rounded-full bg-white/20"
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          >
            {isMenuOpen ? (
              <X className="w-5 h-5" />
            ) : (
              <Plus className="w-5 h-5" />
            )}
          </button>
        </div>
      </header>

      <MenuItems 
        navigation={navigation.menu}
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      />
    </>
  );
};

export default Header02;