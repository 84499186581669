import TextWrapper from "@/shared/components/TextWrapper";
import DivWithAnimation from "../common/DivWithAnimation";
import { usePageStore } from "@/shared/stores/pageStore";
import { EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";
import { isBuilderMode } from "@/shared/constants/environment";

const DesktopPricingRow = ({ label, plan1Value, plan2Value }: any) => (
  <div className="grid grid-cols-3 py-8 border-b border-white/20 relative">
    <div className="px-4 text-white/60">{label}</div>
    <div className="text-center text-white px-4">{plan1Value}</div>
    <div className="text-center text-white px-4">{plan2Value}</div>
  </div>
);

const generateId = () => {
  return `pricing_${Math.random().toString(36).substr(2, 9)}`;
};

const Pricing01 = ({
  content,
  editablePath
}: any) => {
  const setSelectedComponent = usePageStore(state => state.setSelectedComponent);
  const handleHistoryClick = () => {
    if (editablePath) {
      const editorConfig: any = {
        type: 'pricing',
        path: `${editablePath}.content`,
        content: content
      };
  
      setSelectedComponent({
        id: generateId(),
        ...editorConfig
      });
    }
  };

  const plans = content.plans;
  if (!plans || plans.length < 2) return null;
 
  const featureNames = plans[0].features.map((feature: any) => feature.name);
 
  return (
    <>
    <style>{`
      @keyframes backgroundChange {
        0%, 100% { background-color: rgb(255 255 255 / 0.05); }
        50% { background-color: rgb(253 188 58 / 0.15); }
        // 50% { background-color: rgb(100 200 255 / 0.15); }
      }
      .animate-bg {
        animation: backgroundChange 1.5s infinite ease-in-out;
      }
    `}</style>

    <div className="w-full max-w-5xl mx-auto">
      <div
        onClick={handleHistoryClick}
        className={isBuilderMode() && editablePath ? EDITABLE_HOVER_CLASSES : ''}
      >
      <DivWithAnimation>
        {/* 모바일 뷰 */}
        <div className="md:hidden">
          {/* 모바일 헤더 */}
          <div className="flex relative">
            <div className="absolute inset-0 flex">
              <div className={`flex-1 ${plans[0].id === 'thisisweb' ? 'animate-bg' : ''}`} />
              <div className={`flex-1 ${plans[1].id === 'thisisweb' ? 'animate-bg' : ''}`} />
            </div>

            <div className="flex-1 text-center p-4 pb-6">
              <h3 className="text-white mb-2 text-xl font-semibold">{plans[0].name}</h3>
              <div className="text-red-500">
                <span className="text-lg font-semibold">{plans[0].monthlyPrice}</span>
              </div>
            </div>
            <div className="flex-1 text-center p-4 pb-6">
              <h3 className="text-white mb-2 text-xl font-semibold">{plans[1].name}</h3>
              <div className="text-red-500">
                <span className="text-lg font-semibold">{plans[1].monthlyPrice}</span>
              </div>
            </div>
          </div>
  
          {/* 모바일 feature 목록 */}
          <div className="border-t border-white/20 relative">
            {featureNames.map((name: any, idx: any) => (
              <div key={idx} className="py-4 border-b border-white/20 relative">
                <div className="absolute inset-0 flex">
                  <div className={`flex-1 ${plans[0].id === 'thisisweb' ? 'animate-bg' : ''}`} />
                  <div className={`flex-1 ${plans[1].id === 'thisisweb' ? 'animate-bg' : ''}`} />
                </div>
                <div className="text-white/60 font-light mb-2 px-4">{name}</div>
                <div className="flex">
                  <div className="flex-1 text-white px-4 text-center">
                    {typeof plans[0].features[idx].value === 'boolean'
                      ? plans[0].features[idx].value ? '✓' : '✗'
                      : plans[0].features[idx].value}
                  </div>
                  <div className="flex-1 text-white px-4 text-center">
                    {typeof plans[1].features[idx].value === 'boolean'
                      ? plans[1].features[idx].value ? '✓' : '✗'
                      : plans[1].features[idx].value}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
  
        {/* PC 뷰 */}
        <div className="hidden md:block">
          {/* 헤더 부분 */}
          <div className="grid grid-cols-3 relative">
            <div className="absolute inset-0 grid grid-cols-3">
              <div />
              <div className={plans[0].id === 'thisisweb' ? 'animate-bg' : ''} />
              <div className={plans[1].id === 'thisisweb' ? 'animate-bg' : ''} />
            </div>

            {/* 헤더 내용 */}
            <div /> {/* 빈 열 */}
            <div className="text-center p-6">
              <h3 className="text-white text-2xl font-semibold mb-2">{plans[0].name}</h3>
              <div className="text-red-500 mb-1">
                <span className="text-xl font-semibold">{plans[0].monthlyPrice}</span>
              </div>
            </div>
            <div className="text-center p-6">
              <h3 className="text-white text-2xl font-semibold mb-2">{plans[1].name}</h3>
              <div className="text-red-500 mb-1">
                <span className="text-xl font-semibold">{plans[1].monthlyPrice}</span>
              </div>
            </div>
          </div>

          {/* 테이블 */}
          <div className="border-t border-white/20 relative text-lg">
            <div className="absolute inset-0 grid grid-cols-3">
              <div />
              <div className={plans[0].id === 'thisisweb' ? 'animate-bg' : ''} />
              <div className={plans[1].id === 'thisisweb' ? 'animate-bg' : ''} />
            </div>

            {featureNames.map((name: any, idx: any) => (
              <DesktopPricingRow
                key={idx}
                label={name}
                plan1Value={
                  typeof plans[0].features[idx].value === 'boolean'
                    ? plans[0].features[idx].value ? '✓' : '✗'
                    : plans[0].features[idx].value
                }
                plan2Value={
                  typeof plans[1].features[idx].value === 'boolean'
                    ? plans[1].features[idx].value ? '✓' : '✗'
                    : plans[1].features[idx].value
                }
              />
            ))}
          </div>
        </div>
      </DivWithAnimation>
      </div>

      <TextWrapper
        content={content.caution}
        className="mt-8"
        editable={editablePath ? {
          type: 'richText',
          path: `${editablePath}.content.caution`
        } : undefined}
      />
    </div>
    </>
  );
};

export default Pricing01;