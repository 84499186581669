import React from 'react';
import { AlertCircle } from 'lucide-react';

const SubscriptionAlert = ({ status = 'subscription' }) => {
  const getAlertContent = (status: string) => {
    switch (status) {
      case 'onetime':
        return {
          title: '연장 구독 안내',
          message: '템플릿은 만료일이 지나면 사이트 사용이 불가합니다. 계속 사용을 원하시면 만료일 전에 미리 연장 구독을 신청해주세요.',
          color: 'red'
        };
      case 'subscription':
        return {
          title: '자동 갱신 안내',
          message: '각 템플릿은 독립적으로 관리되며, 다음 결제일에 자동으로 갱신됩니다. 구독을 취소하시려면 해당 템플릿의 다음 결제일 이전에 취소해 주세요.',
          color: 'blue'
        };
      case 'expired':
        return {
          title: '재구독 안내',
          message: '모든 템플릿의 만료일이 지나서 사용이 불가합니다. 계속 사용을 원하시면 재구독을 신청해주세요.',
          color: 'red'
        };
      default:
        return {
          title: '자동 갱신 안내',
          message: '각 템플릿은 독립적으로 관리되며, 다음 결제일에 자동으로 갱신됩니다.',
          color: 'blue'
        };
    }
  };

  const { title, message, color } = getAlertContent(status);
  
  const colorStyles: any = {
    blue: {
      container: 'bg-blue-50',
      icon: 'text-blue-500',
      title: 'text-blue-900',
      message: 'text-blue-700'
    },
    red: {
      container: 'bg-red-50',
      icon: 'text-red-500',
      title: 'text-red-900',
      message: 'text-red-700'
    }
  };

  const styles = colorStyles[color];

  return (
    <div className={`flex items-start space-x-4 p-4 ${styles.container} rounded-lg`}>
      <AlertCircle className={`w-5 h-5 ${styles.icon} mt-0.5`} />
      <div>
        <p className={`font-medium ${styles.title}`}>{title}</p>
        <p className={`mt-1 ${styles.message}`}>{message}</p>
      </div>
    </div>
  );
};

export default SubscriptionAlert;