import { PlusCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

const TemplateCreationCTA = () => {
  const navigate = useNavigate();
  
  const handleCreateTemplate = () => {
    navigate('/admin/payment/selection');
  };
  
  return (
    <div className="flex flex-col items-center justify-center bg-white rounded-xl shadow-lg px-8 py-28 text-center">
      <PlusCircle className="w-16 h-16 text-blue-500 mb-4" />
      <h2 className="text-2xl font-bold mb-2">30초 만에 웹사이트를 만들어보세요!</h2>
      <p className="text-gray-600 mb-8">
        지금 바로 템플릿을 선택하고 멋진 웹사이트를 만들어보세요.
      </p>
      <button
        onClick={handleCreateTemplate}
        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-8 rounded-lg transition-colors duration-200 flex items-center space-x-2"
      >
        <span>템플릿 선택하기</span>
      </button>

      <a
        href='https://husky-coffee-14c.notion.site/185c3ee6db8d8015a9a4eab0340e1ca1?pvs=4'
        target='_blank'
        className="mt-8 font-light text-sm text-gray-500 underline"
      >
        결제 후에도 이 페이지가 뜬다면
      </a>
    </div>
  );
};

export default TemplateCreationCTA;