import React, { useState } from 'react';
import { Plus, Trash2 } from 'lucide-react';

interface PricingFeature {
  name: string;
  value: string;
}

interface PricingPlan {
  id: string;
  name: string;
  monthlyPrice: string;
  features: PricingFeature[];
  recommended: boolean;
}

interface PricingContent {
  plans: PricingPlan[];
  caution: string;
}

interface PricingEditorProps {
  content: PricingContent;
  onChange: (content: PricingContent) => void;
}

const PricingEditor: React.FC<PricingEditorProps> = ({ content, onChange }) => {
  const [pricingData, setPricingData] = useState<PricingContent>(content);

  // 플랜 정보 업데이트
  const updatePlan = (planIndex: number, field: string, value: string) => {
    const updatedPlans = pricingData.plans.map((plan, index) => {
      if (index === planIndex) {
        return {
          ...plan,
          [field]: value
        };
      }
      return plan;
    });

    const updatedContent = { ...pricingData, plans: updatedPlans };
    setPricingData(updatedContent);
    onChange(updatedContent);
  };

  // features 업데이트
  const updateFeature = (featureIndex: number, field: string, value: string) => {
    const updatedPlans = pricingData.plans.map(plan => ({
      ...plan,
      features: plan.features.map((feature, idx) => {
        if (idx === featureIndex) {
          if (field === 'name') {
            return { ...feature, name: value };
          }
          // planIndex가 0이면 첫 번째 플랜의 value를, 1이면 두 번째 플랜의 value를 업데이트
          const planIndex = plan.id === pricingData.plans[0].id ? 0 : 1;
          if (field === `value${planIndex}`) {
            return { ...feature, value };
          }
        }
        return feature;
      })
    }));

    const updatedContent = { ...pricingData, plans: updatedPlans };
    setPricingData(updatedContent);
    onChange(updatedContent);
  };

  // 새로운 feature 추가
  const addFeature = () => {
    const newFeature = {
      name: '새로운 항목',
      value: '-'
    };

    const updatedPlans = pricingData.plans.map(plan => ({
      ...plan,
      features: [...plan.features, { ...newFeature }]
    }));

    const updatedContent = { ...pricingData, plans: updatedPlans };
    setPricingData(updatedContent);
    onChange(updatedContent);
  };

  // feature 삭제
  const removeFeature = (featureIndex: number) => {
    const updatedPlans = pricingData.plans.map(plan => ({
      ...plan,
      features: plan.features.filter((_, index) => index !== featureIndex)
    }));

    const updatedContent = { ...pricingData, plans: updatedPlans };
    setPricingData(updatedContent);
    onChange(updatedContent);
  };

  return (
    <div className="w-full space-y-6">
      {/* 플랜 기본 정보 */}
      <div className="grid grid-cols-2 gap-4">
        {pricingData.plans.map((plan, planIndex) => (
          <div key={plan.id} className="border rounded-lg p-4 bg-gray-50 shadow-sm">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  플랜 이름
                </label>
                <input
                  type="text"
                  value={plan.name}
                  onChange={(e) => updatePlan(planIndex, 'name', e.target.value)}
                  className="w-full py-2 px-3 border rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  이용 금액
                </label>
                <input
                  type="text"
                  value={plan.monthlyPrice}
                  onChange={(e) => updatePlan(planIndex, 'monthlyPrice', e.target.value)}
                  className="w-full py-2 px-3 border rounded"
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Features 목록 */}
      <div className="border rounded-lg p-4 bg-gray-50 shadow-sm">
        <h3 className="text-lg font-medium mb-4">비교 항목</h3>
        <div className="space-y-6">
          {pricingData.plans[0].features.map((feature, featureIndex) => (
            <div key={featureIndex} className="relative bg-white rounded-lg border p-4 shadow-sm">
              {/* 삭제 버튼 */}
              <div className="absolute right-3 top-1">
                <button
                  onClick={() => removeFeature(featureIndex)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded"
                  title="항목 삭제"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>

              {/* 항목명 */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  항목명 {featureIndex + 1}
                </label>
                <textarea
                  value={feature.name}
                  onChange={(e) => updateFeature(featureIndex, 'name', e.target.value)}
                  className="w-full py-2 px-3 border rounded h-[44px]"
                />
              </div>

              {/* 플랜별 설명 */}
              <div className="grid grid-cols-2 gap-4">
                {pricingData.plans.map((plan, planIndex) => (
                  <div key={plan.id} className="space-y-1">
                    <label className="block text-sm font-medium text-gray-700">
                      {plan.name}
                    </label>
                    <textarea
                      value={plan.features[featureIndex].value}
                      onChange={(e) => updateFeature(featureIndex, `value${planIndex}`, e.target.value)}
                      className="w-full py-2 px-3 border rounded resize-y min-h-[50px] "
                      placeholder={`${plan.name}의 세부 내용을 입력하세요`}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 새 항목 추가 버튼 */}
      <div className="flex justify-end">
        <button
          onClick={addFeature}
          className="flex items-center gap-2 text-blue-600 hover:text-blue-700"
        >
          <Plus className="w-5 h-5" />
          <span>새로운 항목 추가</span>
        </button>
      </div>
    </div>
  );
};

export default PricingEditor;