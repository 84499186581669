import { useCallback, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { registerQuillModules, SIZE_MAP, sizeHandler } from '../TextEditor/quillModules';
import { quillStyles } from '../TextEditor/styles';

interface ButtonContent {
  id?: number;
  text: string;
  link?: string;
  styles?: {
    borderRadius?: number;
    backgroundColor?: string;
    opacity?: number;
    borderColor?: string;
    borderWidth?: number;
  };
}

interface ButtonEditorProps {
  content: ButtonContent;
  onChange: (content: ButtonContent) => void;
}

registerQuillModules();

const ButtonEditor = ({
  content,
  onChange
}: ButtonEditorProps) => {
  const [textValue, setTextValue] = useState(content.text);
  // const [linkValue, setLinkValue] = useState(content.link || '');
  const [tempStyles, setTempStyles] = useState({
    borderRadius: content?.styles?.borderRadius || 0,
    backgroundColor: content?.styles?.backgroundColor || "#FFF",
    opacity: content?.styles?.opacity || 100,
    borderColor: content?.styles?.borderColor || "#000000",
    borderWidth: content?.styles?.borderWidth || 0
  });

  const quillRef = useRef<ReactQuill>(null);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const timeoutId = setTimeout(() => {
      // 기본 업데이트 객체 생성
      const updateContent: ButtonContent = {
        text: textValue,
        styles: tempStyles
      };

      // id가 있는 경우에만 id 속성 추가
      if (content.id !== undefined) {
        updateContent.id = content.id;
      }

      // link가 있는 경우에만 link 속성 추가
      if (content.link !== undefined) {
        updateContent.link = content.link;
      }

      onChange(updateContent);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [textValue, tempStyles]);

  // useEffect(() => {
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //     return;
  //   }

  //   const timeoutId = setTimeout(() => {
  //     onChange({
  //       id: content.id,
  //       text: textValue,
  //       // link: linkValue,
  //       styles: tempStyles
  //     });
  //   }, 100);

  //   return () => clearTimeout(timeoutId);
  // }, [textValue, linkValue, tempStyles]);

  const handleColor = useCallback(() => {
    const toolbar: any = document.querySelector('.ql-toolbar');
    const colorButton = toolbar?.querySelector('.ql-color');
    
    if (!colorButton) return;
    
    const input = document.createElement('input');
    input.type = 'color';
    input.style.position = 'absolute';
    input.style.opacity = '0';
    input.style.height = '0';
    
    const buttonRect = colorButton.getBoundingClientRect();
    const toolbarRect = toolbar.getBoundingClientRect();
    
    input.style.left = `${buttonRect.left - toolbarRect.left}px`;
    input.style.top = `${buttonRect.height}px`;
    input.style.zIndex = '1000';
    
    toolbar.appendChild(input);
    
    setTimeout(() => {
      input.style.visibility = 'visible';
      input.click();
    }, 0);
    
    const handleChange = () => {
      const quill = quillRef.current?.getEditor();
      const range = quill?.getSelection();
      
      if (range && quill) {
        quill.format('color', input.value);
      }
      input.remove();
    };

    input.addEventListener('change', handleChange);
  }, []);

  const formats = [
    'weight',
    'italic', 'underline',
    'size', 'lineHeight', 'letterSpacing', 'color', 'align', 'textAlign'
  ];

  const modules = {
    toolbar: {
      container: [
        ['italic', 'underline'],
        [{ 'weight': ['굵기', '300', '400', '500', '600', '700', '800'] }],
        [{ 'size': ['크기', ...Object.keys(SIZE_MAP)] }], // Just like in TextEditor
        [{ 'lineHeight': ['높이', '1', '1.2', '1.5', '1.8', '2.0'] }],
        [{ 'letterSpacing': ['자간', '-1em', '-0.05em', '-0.025em', '0', '0.025em', '0.05em', '0.1em'] }],
        ['color'],
        [{ 'textAlign': ['정렬', 'left', 'center', 'right'] }],
      ],
      handlers: {
        color: handleColor,
        size: sizeHandler,
      },
    },
    customClipboard: true
  };

  return (
    <div className="space-y-4">
      <div className="w-full">
        <style>{quillStyles}</style>
        <div className="relative">
          <ReactQuill 
            ref={quillRef}
            theme="snow"
            value={textValue}
            onChange={setTextValue}
            modules={modules}
            formats={formats}
            style={{ height: '100px' }}
            className="editor-container"
            preserveWhitespace
          />
        </div>
      </div>

      <div className="space-y-4 pt-10">
        <div className="flex items-center">
          <label className="w-24 text-sm font-medium">배경색</label>
          <input
            type="color"
            value={tempStyles.backgroundColor}
            onChange={(e) => setTempStyles({
              ...tempStyles,
              backgroundColor: e.target.value
            })}
            className="w-28"
          />
        </div>


        <div className="flex items-center">
          <label className="w-24 text-sm font-medium">투명도</label>
          <div className="flex-1">
            <input
              type="range"
              min="0"
              max="100"
              value={tempStyles.opacity}
              onChange={(e) => setTempStyles({
                ...tempStyles,
                opacity: Number(e.target.value)
              })}
              className="w-full"
            />
          </div>
        </div>

        <div className="flex items-center">
          <label className="w-24 text-sm font-medium">테두리색</label>
          <input
            type="color"
            value={tempStyles.borderColor}
            onChange={(e) => setTempStyles({
              ...tempStyles,
              borderColor: e.target.value
            })}
            className="w-28"
          />
        </div>

        <div className="flex items-center">
          <label className="w-24 text-sm font-medium">테두리 굵기</label>
          <input
            type="range"
            min="0"
            max="10"
            value={tempStyles.borderWidth}
            onChange={(e) => setTempStyles({
              ...tempStyles,
              borderWidth: Number(e.target.value)
            })}
            className="flex-1"
          />
        </div>

        <div className="flex items-center">
          <label className="w-24 text-sm font-medium">테두리 각도</label>
          <input
            type="range"
            min="0"
            max="50"
            value={tempStyles.borderRadius}
            onChange={(e) => setTempStyles({
              ...tempStyles,
              borderRadius: Number(e.target.value)
            })}
            className="flex-1"
          />
        </div>

      </div>
    </div>
  );
};

export default ButtonEditor;

// import { useCallback, useEffect, useRef, useState } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { registerQuillModules, sizeHandler } from '../TextEditor/quillModules';
// import { quillStyles } from '../TextEditor/styles';

// interface ButtonContent {
//   id?: number;
//   text: string;
//   link?: string;
//   styles?: {
//     borderRadius?: number;
//     backgroundColor?: string;
//     opacity?: number;
//   };
// }

// interface ButtonEditorProps {
//   content: ButtonContent;
//   onChange: (content: ButtonContent) => void;
// }

// registerQuillModules();

// const ButtonEditor = ({
//   content,
//   onChange
// }: ButtonEditorProps) => {
//   const [textValue, setTextValue] = useState(content.text);
//   const [linkValue, setLinkValue] = useState(content.link || '');
//   const [tempStyles, setTempStyles] = useState({
//     borderRadius: content?.styles?.borderRadius || 0,
//     backgroundColor: content?.styles?.backgroundColor || "#FFF",
//     opacity: content?.styles?.opacity || 100
//   });

//   const quillRef = useRef<ReactQuill>(null);
//   const isInitialMount = useRef(true);

//   useEffect(() => {
//     if (isInitialMount.current) {
//       isInitialMount.current = false;
//       return;
//     }

//     const timeoutId = setTimeout(() => {
//       onChange({
//         id: content.id,
//         text: textValue,
//         link: linkValue,
//         styles: tempStyles
//       });
//     }, 100);

//     return () => clearTimeout(timeoutId);
//   }, [textValue, linkValue, tempStyles]);

//   const handleColor = useCallback(() => {
//     const toolbar: any = document.querySelector('.ql-toolbar');
//     const colorButton = toolbar?.querySelector('.ql-color');
    
//     if (!colorButton) return;
    
//     const input = document.createElement('input');
//     input.type = 'color';
//     input.style.position = 'absolute';
//     input.style.opacity = '0';
//     input.style.height = '0';
    
//     const buttonRect = colorButton.getBoundingClientRect();
//     const toolbarRect = toolbar.getBoundingClientRect();
    
//     input.style.left = `${buttonRect.left - toolbarRect.left}px`;
//     input.style.top = `${buttonRect.height}px`;
//     input.style.zIndex = '1000';
    
//     toolbar.appendChild(input);
    
//     setTimeout(() => {
//       input.style.visibility = 'visible';
//       input.click();
//     }, 0);
    
//     const handleChange = () => {
//       const quill = quillRef.current?.getEditor();
//       const range = quill?.getSelection();
      
//       if (range && quill) {
//         quill.format('color', input.value);
//       }
//       input.remove();
//     };

//     input.addEventListener('change', handleChange);
//   }, []);

//   const formats = [
//     'weight',
//     'italic', 'underline',
//     'size', 'lineHeight', 'letterSpacing', 'color', 'align', 'textAlign'
//   ];

//   const modules = {
//     toolbar: {
//       container: [
//         ['italic', 'underline'],
//         [{ 'weight': ['굵기', '300', '400', '500', '600', '700', '800'] }],
//         [{ 'size': ['크기', '0.875rem', '1rem', '1.125rem', '1.25rem', '1.5rem'] }],
//         [{ 'lineHeight': ['높이', '1', '1.2', '1.5', '1.8', '2.0'] }],
//         [{ 'letterSpacing': ['자간', '-1em', '-0.05em', '-0.025em', '0', '0.025em', '0.05em', '0.1em'] }],
//         ['color'],
//         [{ 'textAlign': ['정렬', 'left', 'center', 'right'] }],
//       ],
//       handlers: {
//         color: handleColor,
//         size: sizeHandler,
//       },
//     },
//     customClipboard: true
//   };

//   return (
//     <div className="space-y-4">
//       <div className="w-full">
//         <style>{quillStyles}</style>
//         <div className="relative">
//           <ReactQuill 
//             ref={quillRef}
//             theme="snow"
//             value={textValue}
//             onChange={setTextValue}
//             modules={modules}
//             formats={formats}
//             style={{ height: '100px' }}
//             className="editor-container"
//             preserveWhitespace
//           />
//         </div>
//       </div>

//       <div className="space-y-4 pt-10">
//         {/* <div className="flex items-center">
//           <label className="w-24 text-sm font-medium">링크</label>
//           <input
//             type="text"
//             value={linkValue}
//             onChange={(e) => setLinkValue(e.target.value)}
//             placeholder="예: /about"
//             className="flex-1 p-1.5 border rounded"
//           />
//         </div> */}

//         <div className="flex items-center">
//           <label className="w-24 text-sm font-medium">배경색</label>
//           <input
//             type="color"
//             value={tempStyles.backgroundColor}
//             onChange={(e) => setTempStyles({
//               ...tempStyles,
//               backgroundColor: e.target.value
//             })}
//             className="w-28"
//           />
//         </div>

//         <div className="flex items-center">
//           <label className="w-24 text-sm font-medium">투명도</label>
//           <div className="flex-1">
//             <input
//               type="range"
//               min="0"
//               max="100"
//               value={tempStyles.opacity}
//               onChange={(e) => setTempStyles({
//                 ...tempStyles,
//                 opacity: Number(e.target.value)
//               })}
//               className="w-full"
//             />
//           </div>
//         </div>

//         <div className="flex items-center">
//           <label className="w-24 text-sm font-medium">테두리</label>
//           <input
//             type="range"
//             min="0"
//             max="50"
//             value={tempStyles.borderRadius}
//             onChange={(e) => setTempStyles({
//               ...tempStyles,
//               borderRadius: Number(e.target.value)
//             })}
//             className="flex-1"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ButtonEditor;