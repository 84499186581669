import { useEffect, useRef, useState } from 'react';

// 커스텀 훅 - 브라우저 툴바만 고려
const useViewportHeight = (offset = 0) => {
  const [height, setHeight] = useState('100vh');
  const ref = useRef(null);

  useEffect(() => {
    const updateHeight = () => {
      // 브라우저의 실제 보이는 영역 높이
      const viewportHeight = window.innerHeight;
      if (ref.current) {
        const newHeight = `${viewportHeight - offset}px`;
        setHeight(newHeight);
      }
    };

    // 초기 높이 설정
    updateHeight();

    // resize 이벤트 리스너
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [offset]);

  return { height, ref };
};

// 컨테이너 컴포넌트
const ViewportContainer = ({ children, offset = 0, className = '' }: any) => {
  const { height, ref } = useViewportHeight(offset);

  return (
    <div 
      ref={ref}
      className={`w-full ${className}`}
      style={{ height }}
    >
      {children}
    </div>
  );
};

export default ViewportContainer;