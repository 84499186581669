import React from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import SectionContainer from '@/templates/components/common/SectionContainer';
import ProgressCircle from '@/templates/components/indicators/progress/ProgressCircle';
import sampleList from '@/builder/config/sampleList.json';
import DivWithAnimation from '@/templates/components/common/DivWithAnimation';
import { useGroupAnimation } from '@/shared/hooks/animation/useGroupAnimation';
import stepsData from '@/builder/config/steps.json';

interface Template {
  id: number;
  type: string;
  name: string;
  description: string;
  image: string;
  url: string;
}

interface TemplateCardProps {
  template: Template;
}

const TemplateCard: React.FC<TemplateCardProps> = ({ template }) => {
  const navigate = useNavigate();
  
  const handlePreview = () => {
    if (!template.url) {
      toast.error('해당 템플릿은 준비중입니다');
      return;
    }
    window.open(template.url, '_blank', 'noopener,noreferrer');
  };

  const handleSelect = () => {
    // URL이 없는 경우 에러 메시지 표시하고 페이지 전환하지 않음
    if (!template.url) {
      toast.error('해당 템플릿은 준비중입니다');
      return;
    }
    
    // URL이 있는 경우에만 페이지 전환
    navigate('/register', { 
      state: { 
        selectedTemplate: {
          id: template.id,
          type: template.type,
          name: template.name,
          url: template.url
        }
      }
    });
  };

  return (
    <div className="group relative w-full mb-6 md:mb-0">
      <div className="rounded-sm md:rounded-lg overflow-hidden shadow-xl bg-gray-800">
        <div className="relative w-full pb-[133%] overflow-hidden">
          <img
            src={template.image}
            alt={template.name}
            className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col items-center justify-center gap-4">
            <button
              onClick={handlePreview}
              className="bg-white text-black px-6 py-2 rounded-lg hover:bg-gray-200 transition-colors shadow-lg"
            >
              미리보기
            </button>
            <button 
              onClick={handleSelect}
              className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-colors shadow-lg"
            >
              선택하기
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4 text-white text-center text-lg font-medium">{template.name}</div>
      <div className="mt-1 text-gray-200 text-center text-sm md:text-base font-light">{template.description}</div>
    </div>
  );
};


const Selection: React.FC = () => {
  const groupId = useGroupAnimation(100);
  const templates = sampleList.data;
  const steps = stepsData.data;

  return (
    <SectionContainer 
      backgroundImage={{
        src: '',
        styles: {
          overlay: {
            color: '#000000',
          }
        }
      }}
      containerClassName="text-white"
    >
      <div className="mb-28">
        <ProgressCircle
          steps={steps}
          currentStep={1}
        />
      </div>

      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-12">템플릿 선택하기</h1>
        
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-12">
          {templates.map(template => (
            <DivWithAnimation
              animation={{
                groupId: groupId,
              }}
            >
              <TemplateCard key={template.id} template={template} />
            </DivWithAnimation>
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};

export default Selection;