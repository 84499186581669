import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import DOMPurify from "dompurify";
import { useGroupAnimation } from '@/shared/hooks/animation/useGroupAnimation';
import DivWithAnimation from '../common/DivWithAnimation';
import { usePageStore } from '@/shared/stores/pageStore';
import { EDITABLE_HOVER_CLASSES } from '@/shared/constants/styles';
import { isBuilderMode } from '@/shared/constants/environment';

const generateId = () => {
  return `faq_${Math.random().toString(36).substr(2, 9)}`;
};


const Faq01 = ({
  content,
  editablePath
}: any) => {
  const setSelectedComponent = usePageStore(state => state.setSelectedComponent);
  const handleHistoryClick = () => {
    if (editablePath) {
      const editorConfig: any = {
        type: 'faq',
        path: `${editablePath}.content`,
        content: content
      };
  
      setSelectedComponent({
        id: generateId(),
        ...editorConfig
      });
    }
  };

  const groupId = useGroupAnimation(100);

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFaq = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div
      onClick={handleHistoryClick}
      className={isBuilderMode() && editablePath ? EDITABLE_HOVER_CLASSES : ''}
    >
      <div className="w-full mx-auto">
        <div className="flex flex-col gap-2 md:gap-2.5">
          {content.items.map((item: any, index: number) => (
            <DivWithAnimation
              animation={{
                groupId: groupId,
              }}
            >
            <div key={item.id} className="bg-white/20 rounded-md">
              {/* 질문 */}
              <button
                onClick={() => toggleFaq(index)}
                className="w-full flex items-center justify-between p-4 md:p-5 text-left "
              >
                <span className="text-white text-base md:text-xl">{item.question}</span>
                {openIndex === index ? (
                  <ChevronUp className="w-6 h-6 text-white flex-shrink-0" />
                ) : (
                  <ChevronDown className="w-6 h-6 text-white flex-shrink-0" />
                )}
              </button>
              
              {/* 답변 */}
              <div
                className={`grid transition-all duration-300 ease-in-out bg-white/15 ${
                  openIndex === index ? 'grid-rows-[1fr] opacity-100 p-4' : 'grid-rows-[0fr] opacity-0'
                }`}
              >
                <div
                  className="overflow-hidden text-white/80 text-base md:text-lg"
                  dangerouslySetInnerHTML={{ 
                    __html: DOMPurify.sanitize(item.answer) 
                  }}>
                </div>
                
              </div>
            </div>
            </DivWithAnimation>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq01;