import { Outlet } from 'react-router-dom';
import Sidebar from '@/builder/components/admin/Sidebar';
import { useIsMobile } from '@/shared/hooks/useIsMobile';
import { useTemplates } from '@/builder/queries/templateQueries';
import { useTemplateStore } from '@/shared/stores/templateStore';
import { useEffect } from 'react';

const AdminLayout = () => {
  const isMobile = useIsMobile();
  const { data: templates } = useTemplates();
  const setTemplates = useTemplateStore((state) => state.setTemplates);
  const {selectedTemplate, setSelectedTemplate} = useTemplateStore();


  useEffect(() => {
    if (!templates) return;
    setTemplates(templates);
    
    if (selectedTemplate) {
      // 현재 선택된 템플릿의 최신 상태를 찾아서 업데이트
      const updatedTemplate = templates.find((t: any) => t.id === selectedTemplate.id);
      if (updatedTemplate) {
        setSelectedTemplate(updatedTemplate);
      } else {
        // 해당 템플릿이 없어졌다면 첫번째 템플릿 선택
        setSelectedTemplate(templates[0]);
      }
    } else {
      setSelectedTemplate(templates[0]);
    }
  }, [templates]);


  return (
    <div className="min-h-screen">
      <Sidebar />
      <main className={`
        ${isMobile ? 'ml-0' : 'ml-64'}
        min-h-screen bg-gray-50 p-4 md:p-8
      `}>
        <Outlet />
      </main>
  </div>
  );
};

export default AdminLayout;