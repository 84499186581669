import { usePageStore } from "@/shared/stores/pageStore";
import { EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";

interface FooterProps {
  company: {
    name?: {
      title?: string;
      korean?: string;
      english?: string;
    };
    ceo?: string;
    registration?: string;
    order_number?: string;
    privacy_officer?: string;
    address?: string;
    contact?: {
      phone?: string;
      email?: string;
    };
    logo?: {
      favicon?: string;
      header?: string;
      footer?: string;
    };
  };
  links?: {
    name: string;
    path: string;
  }[];
  editablePath?: string;
  onEdit?: () => void;
}

const generateId = () => {
  return `company_${Math.random().toString(36).substr(2, 9)}`;
};

export default function Footer02({ company, links, editablePath }: FooterProps) {
  const setSelectedComponent = usePageStore(state => state.setSelectedComponent);

  const handleClick = () => {
    if (editablePath) {
      const editorConfig: any = {
        type: 'footer',
        path: `${editablePath}`,
        content: {
          company
        }
      };
  
      setSelectedComponent({
        id: generateId(),
        ...editorConfig
      });
    }
  };

  return (
    <footer
      className={`bg-black/90 text-white/80 relative ${editablePath ? EDITABLE_HOVER_CLASSES : ''}`}
      onClick={handleClick}
    >
      <div className="max-w-screen-xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 py-12 px-4">
          <div className="hidden md:block space-y-4">
            {company?.logo?.footer && (
              <img
                src={company.logo.footer}
                alt="logo"
                className="w-32 object-contain"
              />
            )}
          </div>

          {/* Company Info Section */}
          <div className="space-y-2">
            <h3 className="text-white font-medium mb-4">Company Info</h3>
            {company?.name && (
              <p className="text-sm">{company.name.korean}</p>
            )}
            {company?.ceo && (
              <p className="text-sm">CEO: {company.ceo}</p>
            )}
            {company?.registration && (
              <p className="text-sm">사업자등록번호: {company.registration}</p>
            )}
            {company?.order_number && (
              <p className="text-sm">통신판매업번호신고번호: {company.order_number}</p>
            )}
            {company?.privacy_officer && (
              <p className="text-sm">Privacy Officer: {company.privacy_officer}</p>
            )}
          </div>

          {/* Contact Section */}
          <div className="space-y-2">
            <h3 className="text-white font-medium mb-4">Contact</h3>
            {company?.contact?.phone && (
              <p className="text-sm">Tel: {company.contact.phone}</p>
            )}
            {company?.contact?.email && (
              <p className="text-sm">Email: {company.contact.email}</p>
            )}
            {company?.address && (
              <p className="text-sm">Address: {company.address}</p>
            )}
          </div>

          {/* Links Section */}
          {(links?.[0].path || links?.[1].path) && (
            <div className="space-y-2">
              <h3 className="text-white font-medium mb-4">Links</h3>
              <div className="flex flex-col space-y-2">
                {links.map((link, index) => (
                  link.path && (
                    <a
                      key={index}
                      href={link.path}
                      target="_blank"
                      className="text-sm hover:text-white transition-colors"
                    >
                      {link.name}
                    </a>
                  )
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Bottom Section */}
        <div className="border-t border-white/10 px-4 py-6">
          <div className="flex flex-col md:flex-row justify-center items-center gap-4">
            {company?.name?.english && (
              <p className="text-sm">
                © {new Date().getFullYear()} {company.name.english}. All Rights Reserved.
              </p>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}