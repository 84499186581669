import { useState, useEffect } from 'react';
import { useMobilePreview } from '../stores/pageStore';

export const useIsMobile = (breakpoint: number = 1023) => {
  const [isMobile, setIsMobile] = useState(false);
  const isMobilePreview = useMobilePreview(); 

  useEffect(() => {
    if (isMobilePreview) {
      setIsMobile(true);
      return;
    }

    const checkMobile = () => {
      setIsMobile(window.innerWidth < breakpoint);
    };

    // 초기 체크
    checkMobile();

    // 리사이즈 이벤트에 대한 체크
    window.addEventListener('resize', checkMobile);

    // 클린업
    return () => window.removeEventListener('resize', checkMobile);
  }, [breakpoint]);

  return isMobile;
};