import { useTemplateStore } from "@/shared/stores/templateStore";
import DayNightSimple from "@/templates/components/background/DayNightSimple";
import CardGrid03 from "@/templates/components/cardgrid/CardGrid03";
import SectionContainer from "@/templates/components/common/SectionContainer";
import Hero05 from "@/templates/components/hero/Hero05";

// // for test
// import data from "@/templates/template4/config/sample.json"


function Home() {
  const templateData = useTemplateStore(state => state.templateData);

  // // for test
  // const templateData = data


  return (
    <DayNightSimple>
    {/* <ScrollAnimatedBackground> */}
      <Hero05
        {...(templateData.pages.home.hero)}
        editablePath="pages.home.hero"
      />

      <SectionContainer
        containerClassName="!py-16 md:!py-20"
        className="bg-transparent"
        editablePath="pages.home.portfolio"
      >
        <CardGrid03
          {...(templateData.pages.home.grid)}
          essential={templateData.essential}
          // posts={templateData.posts}
          editablePath="pages.home.grid"
        />
      </SectionContainer>
    {/* </ScrollAnimatedBackground> */}
    </DayNightSimple>
  );
}

export default Home;