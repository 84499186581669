import toast from "react-hot-toast";

interface TemplateInfo {
  type?: string;
  name?: string;
  status?: string;
  url?: string;
}

interface SubscriptionState {
  expiryText: string;
  buttonText: string;
  buttonStyle?: any;
  action: (
    navigate: any,
    templateInfo: TemplateInfo, 
    mutations: {
      refundMutation: any;
      subscriptionRenewalMutation: any;
    }
  ) => void;
}

export const getSubscriptionState = ({ subscription, templateInfo }: any): SubscriptionState => {
  // 일회성 구독인 경우
  if (subscription.paymentType !== 'subscription') {
    return {
      expiryText: '만료일',
      buttonText: '연장 구독 신청',
      buttonStyle: {
        backgroundColor: "rgb(220 38 38)",
        textColor: "#fff"
      },
      action: (navigate, templateInfo) => {
        navigate('/admin/user/plans', { 
          state: { 
            selectedTemplate: {
              id: subscription.templateId,
              type: templateInfo?.type,
              name: templateInfo?.name,
              url: templateInfo?.url,
              subscriptions: {
                expiryDate: subscription.expiryDate,
                status: templateInfo?.status
              }
            }
          }
        });
      }
    };
  }

  const today = new Date();
  const expiryDate = new Date(subscription.expiryDate);
  const firstPayment = subscription.billingHistory[0];
  
  // 구독 전환 후 환불 케이스
  const subscriptionExpiryDate = new Date(expiryDate);
  subscriptionExpiryDate.setMonth(subscriptionExpiryDate.getMonth() - subscription.billingCycle);
  if (templateInfo?.status === 'active' && today < subscriptionExpiryDate) {
    console.log("case1")
    return {
      expiryText: '다음 결제일',
      buttonText: '환불신청',
      buttonStyle: {
        backgroundColor: "#fff",
        textColor: "rgb(55 65 81)",
        border: "1px solid rgb(209 213 219)"
      },
      action: (_, __, mutations) => {
        mutations.refundMutation.mutate({
          templateId: subscription.templateId,
          cancelType: 'conversion'
        });
      }
    };
  }

  const oneMonthAfterPayment = new Date(subscription.startDate);
  oneMonthAfterPayment.setMonth(oneMonthAfterPayment.getMonth() + 1);

  // 체험판 환불 케이스
  if (templateInfo?.status === 'active'
      && subscription.billingHistory.length === 1
      && today <= oneMonthAfterPayment) {
    console.log("case2")
    return {
      expiryText: '다음 결제일',
      buttonText: '환불신청',
      buttonStyle: {
        backgroundColor: "#fff",
        textColor: "rgb(55 65 81)",
        border: "1px solid rgb(209 213 219)"
      },
      action: (_, __, mutations) => {
        mutations.refundMutation.mutate({
          templateId: subscription.templateId,
          cancelType: 'trial'
        });
      }
    };
  }

  // 활성 구독 케이스
  if (templateInfo?.status === 'active') {
    return {
      expiryText: subscription.autoRenewal ? '다음 결제일' : '만료일',
      buttonText: subscription.autoRenewal ? '구독갱신 취소' : '재구독',
      buttonStyle: subscription.autoRenewal 
      ? {
        backgroundColor: "#fff",
        textColor: "rgb(55 65 81)",
        border: "1px solid rgb(209 213 219)"
      }
      : {
        backgroundColor: "rgb(220 38 38)",
        textColor: "#fff"
      },
      action: (_, __, mutations) => {
        mutations.subscriptionRenewalMutation.mutate({
          templateId: subscription.templateId,
          action: subscription.autoRenewal ? 'disable' : 'enable'
        });
      }
    };
  }


  // 비활성 구독 케이스
  return {
    expiryText: '만료일',
    buttonText: '재구독',
    buttonStyle: {
      backgroundColor: "rgb(220 38 38)",
      textColor: "#fff"
    },
    action: (navigate, templateInfo) => {
      navigate('/admin/user/reactive', {
        state: { 
          selectedTemplate: {
            id: subscription.templateId,
            type: templateInfo?.type,
            name: templateInfo?.name,
            url: templateInfo?.url,
          }
        }
      });
    }
  };
};