// src/stores/pageStore.ts
import { EditableComponentType } from '@/shared/types/common';
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type ComponentType = EditableComponentType ;

interface SelectedComponent {
  id: string
  type: ComponentType
  path: string
  content?: string
  styles?: any
}

interface PageState {
  currentPage: string
  selectedComponent: SelectedComponent | null
  isEditMode: boolean
  hasUnsavedChanges: boolean
  isMobilePreview: boolean  // 추가
}

interface PageActions {
  setCurrentPage: (page: string) => void
  setSelectedComponent: (component: SelectedComponent | null) => void
  setEditMode: (isEdit: boolean) => void
  setHasUnsavedChanges: (hasChanges: boolean) => void
  setMobilePreview: (isMobile: boolean) => void  // 추가
  resetSelectedComponent: () => void
  reset: () => void
}

const initialState: PageState = {
  currentPage: '',
  selectedComponent: null,
  isEditMode: true,
  hasUnsavedChanges: false,
  isMobilePreview: false  // 추가
}

export const usePageStore = create<PageState & PageActions>()(
  devtools(
    (set) => ({
      // State
      ...initialState,
      
      // Actions
      setCurrentPage: (page) => 
        set({ currentPage: page }),
      
      setSelectedComponent: (component) => 
        set({ selectedComponent: component }),
      
      setEditMode: (isEdit) => 
        set({ isEditMode: isEdit }),
      
      setHasUnsavedChanges: (hasChanges) => 
        set({ hasUnsavedChanges: hasChanges }),

      setMobilePreview: (isMobile) =>  // 추가
        set({ isMobilePreview: isMobile }),

      resetSelectedComponent: () =>
        set({ selectedComponent: null }),
      
      reset: () => set(initialState)
    }),
    { name: 'page-store' }
  )
)

// 선택적으로 필요한 상태만 구독하기 위한 selector 함수들
export const useSelectedComponent = () => 
  usePageStore((state) => state.selectedComponent)

export const useCurrentPage = () => 
  usePageStore((state) => state.currentPage)

export const useEditMode = () => 
  usePageStore((state) => state.isEditMode)

export const useHasUnsavedChanges = () => 
  usePageStore((state) => state.hasUnsavedChanges)

export const useMobilePreview = () => 
  usePageStore((state) => state.isMobilePreview)