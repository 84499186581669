import React from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useTossPayment } from '@/builder/hooks/useTossPayment';
import { useUser } from '@/builder/stores/authStore';
import { PER_MONTH_WITHOUT_VAT, SUBSCRIPTION_AMOUNTS } from '@/builder/constants/billing';

export const PlanCard = ({ isAnnual = false, selectedTemplate, isConversion = false, isReactive = false }: any) => {
  const { requestPayment, isLoading: isPaymentLoading } = useTossPayment();
  const { email, ceoName } = useUser() || {};
  const navigate = useNavigate();


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const paymentData = {
        plan: 'standard' as const,
        billingCycle: parseInt(isAnnual ? '12' : '1'),
        selectedTemplate,
        isConversion,
        isReactive
      };
      sessionStorage.setItem('paymentData', JSON.stringify(paymentData));
  
      if (!email || !ceoName) {
        toast.error('로그인이 만료되었습니다. 다시 로그인해주세요.')
        navigate('/auth/login')
        return;
      }

      if (isReactive) {
        navigate('/admin/payment/success')
        return
      }

      await requestPayment({
        customerEmail: email,
        customerName: ceoName,
      });
    } catch (error: any) {
      if (error.code === 'USER_CANCEL') {
        return;
      }
      
      console.error('Payment error:', error);
      toast.error('결제 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className={`h-full flex flex-col p-6 rounded-xl ${
      isAnnual 
        ? 'bg-blue-50 border-2 border-blue-200 relative overflow-hidden' 
        : 'bg-white border-2 border-gray-200'
    }`}>
      {isAnnual && (
        <div className="absolute -right-8 top-4 bg-blue-500 text-white px-12 py-1 rotate-45">
          추천
        </div>
      )}
      <div className="mb-6">
        <h3 className="text-xl font-bold">
          {isAnnual ? '연간 결제' : '월간 결제'}
        </h3>
        <p className="text-gray-600 mt-1">
          {isAnnual ? `1년 약정 (${((SUBSCRIPTION_AMOUNTS.MONTHLY * 12) - SUBSCRIPTION_AMOUNTS.YEARLY) / (SUBSCRIPTION_AMOUNTS.MONTHLY * 12) * 100 }% 할인)` : '할인 없음'}
        </p>
      </div>

      <div className="mb-8">
        <div className="flex items-end gap-2">
          <span className="text-3xl font-bold">
            {isAnnual ? PER_MONTH_WITHOUT_VAT.YEARLY.toLocaleString() : PER_MONTH_WITHOUT_VAT.MONTHLY.toLocaleString()}
          </span>
          <span className="text-gray-600 mb-1">원/월</span>
        </div>
        
          <div className="text-blue-500 text-sm mt-1">
            {isAnnual ? `연 ${SUBSCRIPTION_AMOUNTS.YEARLY.toLocaleString()}원` : `월 ${SUBSCRIPTION_AMOUNTS.MONTHLY.toLocaleString()}`}  (VAT 포함)
            {isAnnual && (
              <span className="pl-2 line-through text-gray-500">
                &#8592; {`${(SUBSCRIPTION_AMOUNTS.MONTHLY * 12).toLocaleString()}원`}
              </span>
            )}
          </div>
        
      </div>

      <ul className="space-y-3 mb-12 flex-grow">
        <li className="flex items-start gap-2 font-semibold">
          <span className="text-green-500">✓</span>
          <span>{selectedTemplate?.name} {!isConversion ? '제공' : '연장'}</span>
          {!isConversion && (
            <a
              href={selectedTemplate?.url || '#'}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:underline text-base"
            >
              [미리보기]
            </a>
          )}
        </li>
        <li className="flex items-start gap-2">
          <span className="text-green-500">✓</span>
          <span>무제한 수정 및 업데이트</span>
        </li>
        <li className="flex items-start gap-2">
          <span className="text-green-500">✓</span>
          <span>이미지 50장 업로드</span>
        </li>
        <li className="flex items-start gap-2">
          <span className="text-green-500">✓</span>
          <span>커스텀 도메인 연결</span>
        </li>
        {isAnnual && (
          <li className="flex items-start gap-2">
            <span className="text-green-500">✓</span>
            <span className="text-blue-500 font-medium">연간 {((SUBSCRIPTION_AMOUNTS.MONTHLY * 12) - SUBSCRIPTION_AMOUNTS.YEARLY).toLocaleString()}원 절약</span>
          </li>
        )}
      </ul>

      <button
        onClick={handleSubmit}
        disabled={isPaymentLoading}
        className={`w-full py-3 px-6 rounded-lg font-medium 
        ${isAnnual 
          ? 'bg-blue-500 text-white hover:bg-blue-600' 
          : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
        } transition-colors disabled:opacity-50 disabled:cursor-not-allowed`}
      >
        {isAnnual
          ? (isConversion ? '연간 구독으로 전환하기' : '연간 구독 시작하기')
          : (isConversion ? '월간 구독으로 전환하기' : '월간 구독 시작하기')}
      </button>
    </div>
  );
};