import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const PaymentFail = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 실패 메시지를 토스트로 표시
    toast.error('카드등록에 실패했습니다. 이 현상이 반복되면 고객센터에 문의해주세요.', {
      duration: 5000, // 3초 동안 표시
    });

    // 2초 후에 /payment 페이지로 리다이렉트
    const timer = setTimeout(() => {
      navigate('/selection');
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen text-center flex justify-center items-center">
      카드등록에 실패했습니다.<br/>
      이 현상이 반복되면 고객센터에 문의해주세요.
    </div>
  );
};

export default PaymentFail;