// src/utils/queryErrorHandler.ts
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export const handleQueryError = (error: unknown, navigate: ReturnType<typeof useNavigate>) => {
  if (error instanceof AxiosError) {
    const status = error.response?.status;
    const errorMessage = error.response?.data?.message; // 서버에서 보내는 에러 메시지가 있다면 사용

    switch (status) {
      case 401:
        toast.error('로그인이 만료되었습니다. 작성중인 내용은 임시저장되었습니다.');
        localStorage.removeItem('template-storage');
        localStorage.removeItem('auth-storage');
        localStorage.removeItem('token');
        navigate('/auth/login');
        break;

      case 403:
        toast.error('접근 권한이 없습니다.');
        navigate('/');
        break;

      case 404:
        toast.error('요청하신 정보를 찾을 수 없습니다.');
        break;

      case 500:
      case 502:
      case 503:
        toast.error('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        break;

      default:
        toast.error('요청 처리 중 오류가 발생했습니다.');
        break;
    }

    // 개발 환경에서만 콘솔에 출력
    if (process.env.NODE_ENV === 'development') {
      console.error('API Error:', {
        status,
        message: errorMessage,
        url: error.config?.url,
      });
    }
  }
};