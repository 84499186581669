import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Home, Edit, FileText, HelpCircle, LogOut, Menu, User, ChevronDown, Lock, CreditCard, Link2 } from 'lucide-react';
import TemplateSelector from '@/builder/components/admin/TemplateSelector';
import { useTemplateStore } from '@/shared/stores/templateStore';

const navigationItems = [
  { 
    name: '홈', 
    path: '/admin', 
    icon: Home,
    type: 'internal'
  },
  { 
    name: '사이트 편집', 
    path: '/admin/templates', 
    icon: Edit,
    type: 'internal'
  },
  { 
    name: '게시물 관리', 
    path: '/admin/posts', 
    icon: FileText,
    type: 'internal'
  },
  { 
    name: '도메인 연결',
    path: '/admin/domain', 
    icon: Link2,
    type: 'internal'
  },
  { 
    name: '회원정보',
    icon: User,
    type: 'dropdown',
    items: [
      {
        name: '보안',
        path: '/admin/user/security',
        icon: Lock
      },
      {
        name: '구독관리',
        path: '/admin/user/subscription',
        icon: CreditCard
      }
    ]
  },
  { 
    name: '자주묻는질문', 
    path: 'https://husky-coffee-14c.notion.site/FAQ-14bc3ee6db8d802c8728fc6b13199a2f?pvs=4', 
    icon: HelpCircle,
    type: 'external'
  },
  { 
    name: '로그아웃',
    path: '/auth/login',
    icon: LogOut,
    type: 'logout'
  },
];

const Sidebar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isOpen, setIsOpen] = useState(!isMobile);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      setIsOpen(!mobile);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('template-storage')
    localStorage.removeItem('auth-storage');
    localStorage.removeItem('token')
    navigate('/auth/login');
  };

  const getLinkClassName = (path: string) => `w-full flex items-center px-4 py-3 text-gray-700 hover:bg-gray-100 ${
    location.pathname === path ? 'bg-gray-100 text-blue-600' : ''
  }`;

  const handleDropdownClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setDropdownOpen(!dropdownOpen);
  };

  const renderNavigationItem = (item: any) => {
    if (item.type === 'external') {
      return (
        <a
          key={item.path}
          href={item.path}
          target="_blank"
          rel="noopener noreferrer"
          className={getLinkClassName(item.path)}
          onClick={() => isMobile && setIsOpen(false)}
        >
          <item.icon className="w-5 h-5 mr-3" />
          <span>{item.name}</span>
        </a>
      );
    }
    
    if (item.type === 'logout') {
      return (
        <button
          key={item.path}
          onClick={() => {
            handleLogout();
            isMobile && setIsOpen(false);
          }}
          className={getLinkClassName(item.path)}
        >
          <item.icon className="w-5 h-5 mr-3" />
          <span>{item.name}</span>
        </button>
      );
    }

    if (item.type === 'dropdown') {
      return (
        <div key={item.name} className="relative">
          <button
            onClick={handleDropdownClick}
            className={`w-full flex items-center justify-between px-4 py-3 text-gray-700 hover:bg-gray-100 ${
              dropdownOpen ? 'bg-gray-100' : ''
            }`}
          >
            <div className="flex items-center">
              <item.icon className="w-5 h-5 mr-3" />
              <span>{item.name}</span>
            </div>
            <ChevronDown className={`w-4 h-4 transition-transform ${dropdownOpen ? 'rotate-180' : ''}`} />
          </button>
          {dropdownOpen && (
            <div className="">
              {item.items.map((subItem: any) => (
                <Link
                  key={subItem.path}
                  to={subItem.path}
                  className={`${getLinkClassName(subItem.path)} pl-12`}
                  onClick={() => isMobile && setIsOpen(false)}
                >
                  {/* <subItem.icon className="w-5 h-5 mr-3" /> */}
                  <span>{subItem.name}</span>
                </Link>
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <Link
        key={item.path}
        to={item.path}
        className={getLinkClassName(item.path)}
        onClick={() => isMobile && setIsOpen(false)}
      >
        <item.icon className="w-5 h-5 mr-3" />
        <span>{item.name}</span>
      </Link>
    );
  };

  return (
    <>
      {isMobile && (
        <header className="h-16 bg-white border-b flex items-center justify-between px-4 relative z-50">
          <h1 className="text-xl font-bold">THIS IS WEB</h1>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="p-2 hover:bg-gray-100 rounded-lg"
          >
            <Menu className="w-6 h-6" />
          </button>
        </header>
      )}

      <aside className={`
        ${isMobile ? 'fixed left-0 top-16' : 'fixed left-0 top-0'} 
        ${isMobile && !isOpen ? '-translate-x-full' : 'translate-x-0'}
        w-64 h-screen bg-white border-r transition-transform duration-300 ease-in-out z-50
      `}>
        <div className='p-3 pt-6 border-b'>
          { !isMobile && <h1 className="text-xl font-bold">THIS IS WEB</h1> }
          <TemplateSelector />
        </div>
        
        <nav className="mt-4">
          {navigationItems.map(renderNavigationItem)}
        </nav>
      </aside>

      {isMobile && isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default Sidebar;