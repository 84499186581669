import { useTemplateStore } from '@/shared/stores/templateStore';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export interface LoginFormProps {
  onSubmit: (email: string, password: string) => void;  // Promise<void> 제거
  isSubmitting?: boolean;  // 선택적으로 로딩 상태 추가
}

export interface LoginFormState {
  email: string;
  password: string;
  error: string;
}

const LoginForm = ({ onSubmit }: LoginFormProps) => {
  const [formState, setFormState] = useState<LoginFormState>({
    email: '',
    password: '',
    error: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormState(prev => ({ ...prev, error: '' }));

    if (!formState.email || !formState.password) {
      setFormState(prev => ({ ...prev, error: '모든 내용을 입력해 주세요.' }));
      return;
    }

    try {
      await onSubmit(formState.email, formState.password);
    } catch (error) {
      setFormState(prev => ({ 
        ...prev, 
        error: '이메일 또는 비밀번호가 잘못되었습니다.' 
      }));
    }
  };

  return (
    <div className="p-8 !pb-4 max-w-sm w-full mx-auto bg-white rounded-xl shadow-lg">
      <h2 className="text-2xl font-bold mb-6">THIS IS WEB</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">이메일</label>
          <input
            id="email"
            type="email"
            value={formState.email}
            onChange={(e) => setFormState(prev => ({ ...prev, email: e.target.value }))}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">비밀번호</label>
          <input
            id="password"
            type="password"
            value={formState.password}
            onChange={(e) => setFormState(prev => ({ ...prev, password: e.target.value }))}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>

        {formState.error && <p className="text-red-500">{formState.error}</p>}

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
        >
          로그인
        </button>
      </form>
      <div className='mt-2 text-right'>
        <a
          href='https://husky-coffee-14c.notion.site/FAQ-14bc3ee6db8d802c8728fc6b13199a2f'
          target='_blank'
          className="font-light text-sm text-blue-500 hover:underline"
        >오류문의</a>
      </div>
    </div>
  );
};

export default LoginForm;