import { useImageUpload } from '@/builder/queries/imageQueries';
import { useTemplateStore } from '@/shared/stores/templateStore';
import React, { useState } from 'react';


const InfosEditor: React.FC<any> = ({ content = { company: {} }, onChange }) => {
  const { templateData, selectedTemplate } = useTemplateStore();
  const [footerData, setFooterData] = useState<any>({
    company: templateData.essential.footer.company || {}
  });
  const templateId = selectedTemplate?.templateId;
  // const { selectedTemplateId: templateId } = useTemplateStore();
  const imageUpload = useImageUpload(templateId || '');
  // const imageUpload = useImageUpload();


  const handleChange = (field: string, value: string) => {
    const paths = field.split('.');
    const updatedData = { ...footerData };

    // Ensure company exists
    if (!updatedData.company) {
      updatedData.company = {};
    }
    
    let current: any = updatedData;

    for (let i = 0; i < paths.length - 1; i++) {
      if (!current[paths[i]]) {
        current[paths[i]] = {};
      }
      current = current[paths[i]];
    }

    const lastPath = paths[paths.length - 1];
    if (value.trim() === '') {
      delete current[lastPath];
    } else {
      current[lastPath] = value;
    }

    // Clean up empty objects
    if (Object.keys(current).length === 0) {
      let temp: any = updatedData;
      for (let i = 0; i < paths.length - 1; i++) {
        if (Object.keys(temp[paths[i]]).length === 0) {
          delete temp[paths[i]];
        }
        temp = temp[paths[i]];
      }
    }

    if (onChange) {
      onChange(updatedData);
      setFooterData(updatedData);
    }
  };

  const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>, type: 'favicon' | 'header' | 'footer') => {
    const file = e.target.files?.[0];
    if (!file) return;
  
    try {
      const result = await imageUpload.mutateAsync(file);
      if (result?.url) {
        // R2 업로드 URL 사용
        const r2Path = result.url;  // KV에서 반환된 R2 URL 사용
        
        // 데이터 업데이트
        handleChange(`company.logo.${type}`, r2Path);
      }
    } catch (error) {
      console.error('Logo upload error:', error);
    }
  };


  return (
    <div className="w-full space-y-4 p-4 border rounded-lg bg-gray-50">
      <div className="space-y-4">

      <div className="bg-gray-200 p-4 rounded shadow-sm border">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-4">
            타이틀 관리
          </label>

          <label className="block text-sm  text-gray-600 mb-2">
            <p>사이트 이름 (브라우저 탭에 보여지는 사이트 이름입니다)</p>
          </label>
          <input
            type="text"
            value={footerData.company?.name?.title || ''}
            onChange={(e) => handleChange('company.name.title', e.target.value)}
            className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="회사명을 입력하세요"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm text-gray-600 mb-2">
            <p>파비콘 (브라우저 탭에 보여지는 로고입니다)</p>
          </label>
          <div className="space-y-2">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleLogoUpload(e, 'favicon')}
              className="hidden"
              id="favicon"
            />
            <label
              htmlFor="favicon"
              className="inline-block px-4 py-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600 disabled:opacity-50"
            >
              {imageUpload.isPending ? '업로드 중...' : '업로드'}
            </label>
            {footerData.company.logo?.favicon && (
              <div className="mt-2">
                <img
                  src={footerData.company.logo.favicon}
                  alt="파비콘 미리보기"
                  className="max-h-20 object-contain p-2 rounded"
                />
                <button
                  type="button"
                  onClick={() => handleChange('company.logo.favicon', '')}
                  className="mt-2 px-3 py-1 text-sm text-red-500 hover:text-red-600"
                >
                  이미지 제거
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bg-gray-200 p-4 rounded shadow-sm border border-white">
        <label className="block text-sm font-medium text-gray-700 mb-4">
          로고 이미지
        </label>
          
        <div className="mb-4">
          <label className="block text-sm text-gray-600 mb-2">
            <p>헤더 로고 (홈페이지 최상단 헤더에 들어가는 로고입니다)</p>
            <p>(* 템플릿 종류에 따라 존재하지 않을 수 있습니다)</p>
          </label>
          <div className="space-y-2">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleLogoUpload(e, 'header')}
              className="hidden"
              id="headerLogo"
            />
            <label
              htmlFor="headerLogo"
              className="inline-block px-4 py-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600 disabled:opacity-50"
            >
              {imageUpload.isPending ? '업로드 중...' : '업로드'}
            </label>
            {footerData.company.logo?.header && (
              <div className="mt-2">
                <img
                  src={footerData.company.logo.header}
                  alt="헤더 로고 미리보기"
                  className="max-h-20 object-contain p-2 rounded"
                />
                <button
                  type="button"
                  onClick={() => handleChange('company.logo.header', '')}
                  className="mt-2 px-3 py-1 text-sm text-red-500 hover:text-red-600"
                >
                  이미지 제거
                </button>
              </div>
            )}
          </div>
        </div>

        <div>
          <label className="block text-sm text-gray-600 mb-2">
            <p>푸터 로고(홈페이지 최하단 푸터에 들어가는 로고입니다)</p>
          </label>
          <div className="space-y-2">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleLogoUpload(e, 'footer')}
              className="hidden"
              id="footerLogo"
            />
            <label
              htmlFor="footerLogo"
              className="inline-block px-4 py-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600 disabled:opacity-50"
            >
              {imageUpload.isPending ? '업로드 중...' : '업로드'}
            </label>
            {footerData.company.logo?.footer && (
              <div className="mt-2">
                <img
                  src={footerData.company.logo.footer}
                  alt="푸터 로고 미리보기"
                  className="max-h-20 object-contain p-2 rounded"
                />
                <button
                  type="button"
                  onClick={() => handleChange('company.logo.footer', '')}
                  className="mt-2 px-3 py-1 text-sm text-red-500 hover:text-red-600"
                >
                  이미지 제거
                </button>
              </div>
            )}
          </div>
        </div>
        </div>
      </div>

    </div>
  );
};

export default InfosEditor;