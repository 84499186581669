import { PlanCard } from '@/builder/components/payment/PlanCard';
import { useTossPayment } from '@/builder/hooks/useTossPayment';
import { useUser } from '@/builder/stores/authStore';
import { useGroupAnimation } from '@/shared/hooks/animation/useGroupAnimation';
import DivWithAnimation from '@/templates/components/common/DivWithAnimation';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

interface Template {
  id: number;
  type: string;
  name: string;
  description: string;
  image: string;
  url: string;
}

interface TemplateCardProps {
  template: Template;
}

const PageContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="space-y-8 max-w-4xl mx-auto">
    <div className="bg-white p-6 rounded-lg shadow">
      <h1 className="text-2xl font-bold mb-2">2. 플랜 선택하기</h1>
      <p className="text-gray-600">
        한 달 내로 언제든 취소할 수 있습니다.
      </p>
    </div>
    {children}
  </div>
  
);

const SelectPlan: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedTemplate = location.state?.selectedTemplate;
  const groupId = useGroupAnimation(100);

  useEffect(()=> {
    if (selectedTemplate?.name) return
    toast.error('템플릿을 선택해주세요.')
    navigate('/admin/payment/selection')
  }, [])

  return (
    <PageContainer>
      <div className="grid md:grid-cols-2 gap-6">
        <DivWithAnimation
          animation={{
            groupId: groupId,
          }}
          className="min-h-20"
        >
          <PlanCard
            isAnnual={true}
            selectedTemplate={selectedTemplate}
            isConversion={false}
          />
        </DivWithAnimation>
        <DivWithAnimation
          animation={{
            groupId: groupId,
          }}
          className="min-h-20"
        >
          <PlanCard
            isAnnual={false}
            selectedTemplate={selectedTemplate}
            isConversion={false}
          />
        </DivWithAnimation>
      </div>
    </PageContainer>
  );
};

export default SelectPlan;


