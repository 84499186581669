import { useTemplates } from '@/builder/queries/templateQueries';
import { useTemplateStore } from '@/shared/stores/templateStore';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';

const TemplateSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: templates, isLoading } = useTemplates();
  const {
    selectedTemplate, setSelectedTemplate,
  } = useTemplateStore();

  // const selectedTemplate = templates?.find(
  //   (template: any) => template.templateId === selectedTemplateId
  // );

  if (isLoading) return null;

  return (
    <div className="relative py-3">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className=
          {`w-full flex items-center justify-between px-3 py-2 text-sm  rounded-md 
          ${selectedTemplate ? 'bg-gray-50 hover:bg-gray-100' : 'bg-blue-200 hover:bg-blue-300' }`}
      >
        <span className="text-gray-700">
          {selectedTemplate ? selectedTemplate.name : '템플릿 선택'}
        </span>
        <ChevronDown className="w-4 h-4 text-gray-500" />
      </button>

      {isOpen && (
        <div className="absolute w-full left-0 right-4 bg-white border rounded-md shadow-lg z-50">
          {templates?.map((template: any) => (
            <button
              key={template.templateId}
              onClick={() => {
                setSelectedTemplate(template);
                setIsOpen(false);
              }}
              className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-50 ${
                selectedTemplate?.templateId === template?.templateId
                  ? 'bg-blue-50 text-blue-600'
                  : 'text-gray-700'
              }`}
            >
              {template.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TemplateSelector;