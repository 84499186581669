import React, { useState } from 'react';
import { Plus, Trash2 } from 'lucide-react';

interface LocationItem {
  id: number;
  src: string;
  name: string;
  address: string;
  phone: string;
}

interface MapEditorProps {
  content: LocationItem[];
  onChange: (content: LocationItem[]) => void;
}

const MapEditor: React.FC<MapEditorProps> = ({ content, onChange }) => {
  const [locationItems, setLocationItems] = useState<LocationItem[]>(content);

  // 새 지점 추가
  const addLocationItem = () => {
    const newItem: LocationItem = {
      id: Date.now(),
      src: '',
      name: '지점',
      address: '주소를 입력하세요.',
      phone: '연락처를 입력하세요.'
    };

    const updatedItems = [...locationItems, newItem];
    setLocationItems(updatedItems);
    onChange(updatedItems);
  };

  // 지점 삭제
  const removeLocationItem = (index: number) => {
    const updatedItems = locationItems.filter((_, idx) => idx !== index);
    setLocationItems(updatedItems);
    onChange(updatedItems);
  };

  // 지점 정보 수정
  const updateLocationItem = (index: number, field: keyof LocationItem, value: string) => {
    const updatedItems = locationItems.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [field]: value
        };
      }
      return item;
    });

    setLocationItems(updatedItems);
    onChange(updatedItems);
  };

  // URL 업데이트 핸들러
  const handleUrlChange = (index: number, newValue: string) => {
    // iframe src URL을 추출하는 정규식
    const srcRegex = /src="(https:\/\/www\.google\.com\/maps\/embed\?[^"]+)"/;
    const match = newValue.match(srcRegex);
    
    // 매칭된 URL이 있으면 해당 URL만 사용, 없으면 입력값 그대로 사용
    const extractedUrl = match ? match[1] : newValue;
    
    // LocationItem의 src 필드 업데이트
    updateLocationItem(index, 'src', extractedUrl);
  };

  return (
    <div className="w-full space-y-6">
      <div className="space-y-4">
        {locationItems.map((item, index) => (
          <div key={item.id} className="border rounded-lg p-4 bg-gray-50 shadow-sm">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium">지도 {index + 1}</h3>
              <button
                onClick={() => removeLocationItem(index)}
                className="p-2 text-red-600 hover:bg-red-50 rounded"
                title="지점 삭제"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  지점명
                </label>
                <input
                  type="text"
                  value={item.name}
                  onChange={(e) => updateLocationItem(index, 'name', e.target.value)}
                  className="w-full py-2 px-3 border rounded"
                  placeholder="지점명을 입력하세요"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  구글맵 링크
                </label>
                <input
                  type="text"
                  value={item.src}
                  onChange={(e) => handleUrlChange(index, e.target.value)}
                  className="w-full py-2 px-3 border rounded"
                  placeholder="구글맵 링크를 입력하세요 (하단 가이드 참고)"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  주소
                </label>
                <input
                  type="text"
                  value={item.address}
                  onChange={(e) => updateLocationItem(index, 'address', e.target.value)}
                  className="w-full py-2 px-3 border rounded"
                  placeholder="주소를 입력하세요"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  연락처
                </label>
                <input
                  type="text"
                  value={item.phone}
                  onChange={(e) => updateLocationItem(index, 'phone', e.target.value)}
                  className="w-full py-2 px-3 border rounded"
                  placeholder="연락처를 입력하세요"
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-end">
        <button
          onClick={addLocationItem}
          className="flex items-center gap-2 text-blue-600 hover:text-blue-700"
        >
          <Plus className="w-5 h-5" />
          <span>새로운 지점 추가</span>
        </button>
      </div>

      <div className="mt-4">
        <a
          href="https://husky-coffee-14c.notion.site/14bc3ee6db8d808da31ffa1b6a581093"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800 text-sm hover:underline"
        >
          구글맵 링크 검색 방법 →
        </a>
      </div>
    </div>
  );
};

export default MapEditor;

// import React, { useState } from 'react';

// interface MapEditorProps {
//   content?: string;
//   onChange?: (updatedContent: string) => void;
// }

// const MapEditor: React.FC<MapEditorProps> = ({ content = '', onChange }) => {
//   const [mapUrl, setMapUrl] = useState<string>(content);

//   // URL 업데이트 핸들러
//   const handleUrlChange = (newValue: string) => {
//     // iframe src URL을 추출하는 정규식
//     const srcRegex = /src="(https:\/\/www\.google\.com\/maps\/embed\?[^"]+)"/;
//     const match = newValue.match(srcRegex);
    
//     // 매칭된 URL이 있으면 해당 URL만 사용, 없으면 입력값 그대로 사용
//     const extractedUrl = match ? match[1] : newValue;

//     if (onChange) {
//       onChange(extractedUrl);
//       setMapUrl(extractedUrl);
//     }
//   };

//   return (
//     <div className="w-full space-y-4 p-4 border rounded-lg bg-gray-50">
//       <div className="space-y-2">
//         <div className="bg-white p-4 rounded shadow-sm border">
//           <label className="block text-sm font-medium text-gray-700 mb-2">
//             구글맵 링크
//           </label>
//           <input
//             type="text"
//             value={mapUrl}
//             onChange={(e) => handleUrlChange(e.target.value)}
//             className="w-full py-2 px-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
//             placeholder="구글맵 iframe 소스를 입력하세요"
//           />
//         </div>
//       </div>

//       <div className="mt-4">
//         <a
//           href="https://husky-coffee-14c.notion.site/14bc3ee6db8d808da31ffa1b6a581093"
//           target="_blank"
//           rel="noopener noreferrer"
//           className="text-blue-600 hover:text-blue-800 text-sm hover:underline"
//         >
//           구글맵 링크 검색 방법 →
//         </a>
//       </div>
//     </div>
//   );
// };

// export default MapEditor;