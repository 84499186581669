import { useEffect, useState, useRef } from 'react';
import { useImageUpload } from '@/builder/queries/imageQueries';
import TextEditor from '../editor/TextEditor/TextEditor';
import { PostFormData } from '@/builder/types/post';
import { useTemplateStore } from '@/shared/stores/templateStore';
import ImagePicker from '../editor/ImageEditor/ImagePicker';

interface PostFormProps {
  initialData?: PostFormData;
  isSubmitting: boolean;
  onSubmit: (data: PostFormData) => void;
  onCancel: () => void;
  onChange?: (data: PostFormData) => void;
}

const PostForm = ({ 
  initialData = { title: '', subtitle: '', content: '', thumbnailUrl: '' },
  isSubmitting,
  onSubmit,
  onCancel,
  onChange
}: PostFormProps) => {
  const [formData, setFormData] = useState<PostFormData>(initialData);
  const [showImagePicker, setShowImagePicker] = useState(false);
  const { selectedTemplate } = useTemplateStore();
  const templateId = selectedTemplate?.templateId;
  // const { selectedTemplateId: templateId } = useTemplateStore();
  const imageUpload = useImageUpload(templateId || '');
  
  const lastSaveRef = useRef<number>(Date.now());
  const pendingChangesRef = useRef<PostFormData | null>(null);
  
  // 주기적 저장 처리
  useEffect(() => {
    if (!onChange) return; // onChange가 없으면 저장하지 않음

    const saveInterval = 3000; // 3초
    
    const intervalId = setInterval(() => {
      if (pendingChangesRef.current && 
          Date.now() - lastSaveRef.current >= saveInterval) {
        onChange(pendingChangesRef.current);
        lastSaveRef.current = Date.now();
        pendingChangesRef.current = null;
      }
    }, saveInterval);

    return () => clearInterval(intervalId);
  }, [onChange]);

  // 페이지 벗어날 때 저장
  useEffect(() => {
    if (!onChange) return;

    const handleBeforeUnload = () => {
      if (pendingChangesRef.current) {
        onChange(pendingChangesRef.current);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [onChange]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newData = {
      ...formData,
      [name]: value
    };
    setFormData(newData);
    pendingChangesRef.current = newData;
  };

  const handleContentChange = (content: string) => {
    const newData = {
      ...formData,
      content
    };
    setFormData(newData);
    pendingChangesRef.current = newData;
  };

  const handleImageSelect = (url: string) => {
    const newData = {
      ...formData,
      thumbnailUrl: url
    };
    setFormData(newData);
    pendingChangesRef.current = newData;
    setShowImagePicker(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        {/* 제목 입력 */}
        <div>
          <label className="block text-sm font-medium mb-1">제목</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            placeholder="게시물 제목을 입력하세요"
            className="w-full p-2 border rounded"
            required
          />
        </div>

        {/* 부제목 입력 */}
        <div>
          <label className="block text-sm font-medium mb-1">부제목 (선택사항)</label>
          <input
            type="text"
            name="subtitle"
            value={formData.subtitle}
            onChange={handleInputChange}
            placeholder="부제목을 입력하세요"
            className="w-full p-2 border rounded"
          />
        </div>

        {/* 썸네일 이미지 */}
        <div>
          <label className="block text-sm font-medium mb-1">썸네일 이미지</label>
          <div className="space-y-2">
            <button
              type="button"
              onClick={() => setShowImagePicker(true)}
              className="px-4 py-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600 disabled:opacity-50"
              disabled={imageUpload.isPending}
            >
              {imageUpload.isPending ? '업로드 중...' : '이미지 선택'}
            </button>
            
            {formData.thumbnailUrl && (
              <div className="mt-2">
                <img
                  src={formData.thumbnailUrl}
                  alt="썸네일 미리보기"
                  className="max-h-40 object-cover rounded"
                />
                <button
                  type="button"
                  onClick={() => {
                    const newData = { ...formData, thumbnailUrl: '' };
                    setFormData(newData);
                    pendingChangesRef.current = newData;
                  }}
                  className="mt-2 px-3 py-1 text-sm text-red-500 hover:text-red-600"
                >
                  이미지 제거
                </button>
              </div>
            )}
          </div>
        </div>

        {/* 본문 에디터 */}
        <div>
          <label className="block text-sm font-medium mb-1">본문</label>
          <TextEditor
            content={formData.content}
            type="richText"
            onChange={handleContentChange}
            enableImages={true}
          />
        </div>
      </div>

      {/* 이미지 선택 모달 */}
      {showImagePicker && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <ImagePicker
            onSelect={handleImageSelect}
            onClose={() => setShowImagePicker(false)}
          />
        </div>
      )}

      {/* 버튼 그룹 */}
      <div className="pt-8 flex justify-end space-x-4">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border rounded hover:bg-gray-50"
          disabled={isSubmitting}
        >
          취소
        </button>
        <button
          type="button"
          onClick={() => onSubmit(formData)}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
          disabled={isSubmitting}
        >
          {isSubmitting ? '작성 중...' : '작성 완료'}
        </button>
      </div>
    </form>
  );
};

export default PostForm;
