import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import AdminLayout from '@/builder/layouts/AdminLayout';
import EditorLayout from '@/builder/layouts/EditorLayout';

import Login from '@/builder/pages/auth/Login';
import ProtectedRoute from '@/builder/routes/ProtectedRoute';
import TemplateList from '@/builder/pages/admin/TemplateList';
import PostList from '@/builder/pages/posts/PostList';
import CreatePost from '@/builder/pages/posts/CreatePost';
import EditPost from '@/builder/pages/posts/EditPost';
import Dashboard from '@/builder/pages/admin/Dashboard';
import Landing from '@/builder/pages/landing/Landing';
import Selection from '../pages/landing/Selection';
import Home from '@/templates/template4/pages/home/Home';
import UserInfo from '../pages/user/UserInfo';
import LandingLayout from '../layouts/LandingLayout';
import SelectTemplate from '../pages/payment/SelectTemplate';
import SelectPlan from '../pages/payment/SelectPlan';
import PaymentSuccess from '../pages/payment/PaymentSuccess';
import PaymentFail from '../pages/payment/PaymentFail';
import Register from '../pages/landing/Register';
import UserSubscription from '../pages/user/UserSubscription';
import UserConvertSubscription from '../pages/user/UserConvertSubscription';
import UserReactiveSubscription from '../pages/user/UserReactiveSubscription';
import ConnectionDomain from '../pages/admin/ConnectionDomain';


export const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingLayout />,
    children: [
      { path: '', element: <Navigate to="/auth/login" replace /> },  // 변경된 부분
      // { path: '', element: <Landing /> },
      { path: '/landing', element: <Landing /> },
      { path: '/selection', element: <Selection /> },
      { path: '/register', element: <Register /> },
      {
        path: 'auth',
        children: [
          { path: 'login', element: <Login /> },
          // { path: 'signup', element: <Signup /> },
        ]
      },
      // { 
      //   path: 'payment',  // admin 밖으로 뺌
      //   children: [
      //     { path: 'success', element: <PaymentSuccess /> },
      //     { path: 'fail', element: <PaymentFail /> },
      //   ]
      // },
      { path: 'admin/lab', element: <Home /> }, // 템플릿 연구실
      {
        path: 'admin',
        element: <ProtectedRoute><Outlet /></ProtectedRoute>,
        children: [
          {
            element: <AdminLayout />,
            children: [
              { path: '', element: <Dashboard /> },
              { path: 'templates', element: <TemplateList /> },
              { path: 'domain', element: <ConnectionDomain /> },
              { 
                path: 'user',
                children: [
                  { path: 'security', element: <UserInfo /> },
                  { path: 'subscription', element: <UserSubscription /> },
                  { path: 'plans', element: <UserConvertSubscription /> },
                  { path: 'reactive', element: <UserReactiveSubscription /> },
                ]
              },
              { 
                path: 'posts',
                children: [
                  { index: true, element: <PostList /> },
                  { path: 'create', element: <CreatePost /> },
                  { path: ':postId/edit', element: <EditPost /> }
                ]
              },
              { 
                path: 'payment',
                children: [
                  { path: 'selection', element: <SelectTemplate /> },
                  { path: 'plans', element: <SelectPlan /> },
                  { path: 'success', element: <PaymentSuccess /> },
                  { path: 'fail', element: <PaymentFail /> },
                ]
              }
              // { path: 'faq', element: <FAQ /> }
            ]
          },
          // 사이트 편집 페이지 (AdminLayout 제외)
          {
            path: 'templates/:templateId',
            element: <EditorLayout />
          }
        ]
      }
    ]
  }
]);
