import { usePageStore } from "@/shared/stores/pageStore";
import { EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";
import { isBuilderMode } from '@/shared/constants/environment';
import DivWithAnimation from "../common/DivWithAnimation";
import { useGroupAnimation } from "@/shared/hooks/animation/useGroupAnimation";
import ViewportContainer from "../common/ViewportContainer";


export interface TextProps {
  data: string[];
  className?: string;
}

const Text = ({ data, className = '' }: TextProps) => {
  return (
    <div className={className}>
      {data.map((text, idx) => (
        <div
          key={idx}
          className={`
            ${idx === 0
              ? 'text-4xl md:text-6xl font-semibold'
              : 'text-3xl md:text-5xl font-extralight'}
            tracking-tight !leading-none
          `}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

const Hero05 = ({
  content,
  images,
  editablePath
}: any) => {
  const groupId = useGroupAnimation(50);
  const setSelectedComponent = usePageStore(state => state.setSelectedComponent);
  const handleHistoryClick = () => {
    if (editablePath) {
      const editorConfig: any = {
        type: 'list',
        path: `${editablePath}.content`,
        content: content
      };
  
      setSelectedComponent({
        ...editorConfig
      });
    }
  };

  return (
    <ViewportContainer>
      <div className="h-full relative">
        <div className="absolute bottom-[10%] left-[5%] mx-auto">
          <div
            onClick={handleHistoryClick}
            className={isBuilderMode() && editablePath ? EDITABLE_HOVER_CLASSES : ''}
          >
            <DivWithAnimation
              animation={{
                type: 'fadeIn',
                delay: 2000,
                groupId: groupId,
              }}
            >
              <Text
                data={content}
                className="text-white tracking-tight border-l-4 pl-6 flex flex-col gap-4"
              />
            </DivWithAnimation>
          </div>
        </div>
      </div>
    </ViewportContainer>
  );
 };

 export default Hero05;