// src/pages/admin/TemplateList.tsx
import Header from '@/builder/components/admin/Header';
import { useTemplateStore } from '@/shared/stores/templateStore';
import TemplateCard from '@/builder/components/templates/TemplateCard';
import NotFoundTemplate from '../../components/admin/NotFoundTemplate';
import NotFoundUser from '@/builder/components/admin/NotFoundUser';
import ExpiredTemplate from '@/builder/components/admin/ExpiredTemplate';

const PageContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="space-y-8 max-w-5xl mx-auto">
    <Header
      title="사이트 편집"
      subtitle="디자인이 최적화된 홈페이지를 편집하세요"
    />
    {children}
  </div>
);

const TemplateList = () => {
  const { templates, selectedTemplate } = useTemplateStore();
  const selectedTemplateId = selectedTemplate?.templateId;

  if (!templates || templates.length === 0) {
    return (
      <PageContainer>
        <NotFoundUser />
      </PageContainer>
    );
  }

  if (selectedTemplate.status === 'inactive') {
    return (
      <div className="space-y-8 max-w-6xl mx-auto">
        <PageContainer>
          <ExpiredTemplate />
        </PageContainer>
      </div>
    )
  }

  if (!selectedTemplateId) {
    return (
      <PageContainer>
        <NotFoundTemplate />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <div className="w-full">
        <TemplateCard templateId={selectedTemplateId} />
      </div>
    </PageContainer>
  );
};

export default TemplateList;