import { QueryClient } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'


export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      // staleTime: 0,
      // gcTime: 0,
      staleTime: 5 * 60 * 1000,
      gcTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
})

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

persistQueryClient({
  queryClient,
  persister,
  maxAge: 5 * 60 * 1000,
  
  // 특정 쿼리만 persist하고 싶다면
  /*
  filter: (query) => {
    return query.queryKey[0] === 'templates'
  },
  */
})