import React, { useState } from 'react';
import Header from '@/builder/components/admin/Header';
import { CreditCard, CalendarDays, Clock, AlertCircle, FileText, ChevronDown } from 'lucide-react';
import { useTemplates } from '@/builder/queries/templateQueries';
import { usePaymentInfo } from '@/builder/queries/paymentQueries';
import SubscriptionAlert from '@/builder/components/payment/SubscriptionAlert';
import SubscriptionCard from '@/builder/components/payment/SubscriptionCard';
import { PaymentCard } from '@/builder/components/payment/PaymentCard';
import { UpdateCardModal } from '@/builder/components/payment/UpdateCardModal';

const UserSubscription = () => {
  const { data: templates } = useTemplates();
  const { data: paymentInfo, isLoading } = usePaymentInfo();
  const [expandedHistory, setExpandedHistory] = useState<string | null>(null);
  const [isUpdateCardModalOpen, setIsUpdateCardModalOpen] = useState(false);


  if (isLoading) {
    return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  }

  return (
    <div className="space-y-8 max-w-4xl mx-auto">
      <Header
        title="구독관리"
        subtitle="구독상태를 관리할 수 있습니다"
      />

      <div className="grid grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-lg border">
          <p className="text-sm text-gray-500">생성 템플릿</p>
          <p className="text-2xl font-bold mt-1">
            {paymentInfo?.subscriptions.length || 0}개
          </p>
        </div>
        <div className="bg-white p-4 rounded-lg border">
          <p className="text-sm text-gray-500">활성 구독</p>
          <p className="text-2xl font-bold mt-1 text-green-600">
            {templates?.filter(template => template.status === 'active').length || 0}개
          </p>
        </div>
      </div>

      {paymentInfo?.card.cardNumber && (
        <PaymentCard
          cardInfo={paymentInfo.card}
          onChangeCard={() => setIsUpdateCardModalOpen(true)}
        />
      )}

      <div className="space-y-6">
        {paymentInfo?.subscriptions.map((subscription) => {
          const templateInfo = templates?.find(t => t.templateId === subscription.templateId);
          return (
            <SubscriptionCard
              key={subscription.templateId}
              subscription={subscription}
              templateInfo={templateInfo}
              expandedHistory={expandedHistory}
              setExpandedHistory={setExpandedHistory}
            />
          );
        })}
      </div>

      <SubscriptionAlert status={paymentInfo?.subscriptions[0]?.paymentType} />

      <UpdateCardModal
        isOpen={isUpdateCardModalOpen}
        onClose={() => setIsUpdateCardModalOpen(false)}
      />
    </div>
  );
};

export default UserSubscription;