import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useIntersectionObserver } from './useIntersectionObserver';
import { useAnimationStore } from '@/shared/stores/animationStore';
import { ExtendedAnimationConfig, AnimationHookReturn } from '@/shared/types/animation';
import { create } from 'zustand';

interface AnimationState {
  startTimes: Map<string, Map<number, number>>;
  markStarted: (groupId: string, order: number) => void;
  getStartTime: (groupId: string, order: number) => number;
  canStart: (groupId: string, order: number, baseDelay: number) => boolean;
  reset: (groupId: string) => void;
}

const useAnimationState = create<AnimationState>((set, get) => ({
  startTimes: new Map(),

  // markStarted: (groupId: string, order: number) => {
  //   console.log('🎯 Marking animation start:', { groupId, order, time: Date.now() });  // 여기에 추가
  //   set(state => {
  //     const newMap = new Map(state.startTimes);
  //     if (!newMap.has(groupId)) {
  //       newMap.set(groupId, new Map());
  //     }
  //     const groupMap = newMap.get(groupId)!;
  //     groupMap.set(order, Date.now());
  //     return { startTimes: newMap };
  //   });
  // },
  
  
  markStarted: (groupId: string, order: number) => {
    set(state => {
      const newMap = new Map(state.startTimes);
      if (!newMap.has(groupId)) {
        newMap.set(groupId, new Map());
      }
      const groupMap = newMap.get(groupId)!;
      groupMap.set(order, Date.now());
      return { startTimes: newMap };
    });
  },
  
  getStartTime: (groupId: string, order: number): number => {
    return get().startTimes.get(groupId)?.get(order) || 0;
  },

  // canStart: (groupId: string, order: number, baseDelay: number): boolean => {
  //   if (order === 0) return true;
    
  //   const prevStartTime = get().getStartTime(groupId, order - 1);
  //   const currentTime = Date.now();
  //   const canStartNow = currentTime >= prevStartTime + baseDelay;

  //   // 여기에 더 자세한 로그 추가
  //   console.log('🔍 Can Start Check:', {
  //       groupId,
  //       order,
  //       prevOrder: order - 1,
  //       prevStartTime,
  //       currentTime,
  //       timeDifference: currentTime - prevStartTime,
  //       baseDelay,
  //       canStartNow,
  //       allStartTimes: get().startTimes
  //   });
    
  //   return canStartNow;
  // },
  
  canStart: (groupId: string, order: number, baseDelay: number): boolean => {
    if (order === 0) return true;
    
    const prevStartTime = get().getStartTime(groupId, order - 1);
    if (prevStartTime === 0) return false;
    
    return Date.now() >= prevStartTime + baseDelay;
  },
  
  reset: (groupId: string) => {
    set(state => {
      const newMap = new Map(state.startTimes);
      newMap.delete(groupId);
      return { startTimes: newMap };
    });
  }
}));

const groupOrders = new Map<string, number>();

export const useAnimation = (
  animation?: ExtendedAnimationConfig
): AnimationHookReturn => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible] = useIntersectionObserver(ref, animation?.threshold);
  const { groups } = useAnimationStore();
  const [canAnimate, setCanAnimate] = useState(false);
  const [order, setOrder] = useState(0);
  const { markStarted, canStart, reset } = useAnimationState();
  const animationRef = useRef(animation);

  // 그룹 관련 초기화
  useEffect(() => {
    if (!animation?.groupId) return;

    const groupId = animation.groupId;
    const currentOrder = groupOrders.get(groupId) ?? 0;
    setOrder(currentOrder);
    groupOrders.set(groupId, currentOrder + 1);

    return () => {
      if (groupOrders.get(groupId) === currentOrder + 1) {
        groupOrders.delete(groupId);
        reset(groupId);
      }
    };
  }, [animation?.groupId]);


  // useEffect(() => {
  //   if (canAnimate) return;
    
  //   const currentAnimation = animationRef.current;
    
  //   // 개별 애니메이션 또는 그룹의 첫 번째 컴포넌트는 isVisible 체크
  //   if (!currentAnimation?.groupId || (currentAnimation?.groupId && order === 0)) {
  //     // 첫 번째 컴포넌트 시작할 때만 로그
  //     if (!isVisible) return;
      
  //     console.log('🚀 First animation starting:', {
  //       groupId: currentAnimation?.groupId,
  //       order,
  //       delay: currentAnimation?.delay
  //     });
      
  //     const timer = setTimeout(() => {
  //       setCanAnimate(true);
  //       if (currentAnimation?.groupId) {
  //         markStarted(currentAnimation.groupId, order);
  //       }
  //     }, currentAnimation?.delay ?? 0);
      
  //     return () => clearTimeout(timer);
  //   }
  
  //   // 그룹의 두 번째 이후 컴포넌트들은 이전 컴포넌트 시작 시간 체크
  //   const group = groups[currentAnimation.groupId];
  //   const baseDelay = group?.baseDelay ?? currentAnimation.delay ?? 200;
  //   let frameId: number;
  
  //   // 두 번째 컴포넌트 시작 조건 체크할 때만 로그
  //   console.log('👀 Second animation checking:', {
  //     order,
  //     baseDelay,
  //     canStart: canStart(currentAnimation.groupId!, order, baseDelay)
  //   });
  
  //   const checkStart = () => {
  //     if (!canStart(currentAnimation.groupId!, order, baseDelay)) {
  //       frameId = requestAnimationFrame(checkStart);
  //       return;
  //     }
  
  //     // 두 번째 컴포넌트 실제 시작할 때만 로그
  //     console.log('✨ Second animation starting:', {
  //       order,
  //       baseDelay
  //     });
      
  //     setCanAnimate(true);
  //     markStarted(currentAnimation.groupId!, order);
  //   };
  
  //   checkStart();
  
  //   return () => {
  //     if (frameId) {
  //       cancelAnimationFrame(frameId);
  //     }
  //   };
  // }, [isVisible, canAnimate, order, groups]);
  
  // 애니메이션 시작 로직
  useEffect(() => {
    if (canAnimate) return;
    
    const currentAnimation = animationRef.current;
    
    // 개별 애니메이션 또는 그룹의 첫 번째 컴포넌트는 isVisible 체크
    if (!currentAnimation?.groupId || (currentAnimation?.groupId && order === 0)) {
      if (!isVisible) return;
      
      const timer = setTimeout(() => {
        setCanAnimate(true);
        if (currentAnimation?.groupId) {
          markStarted(currentAnimation.groupId, order);
        }
      }, currentAnimation?.delay ?? 0);
      
      return () => clearTimeout(timer);
    }

    // 그룹의 두 번째 이후 컴포넌트들은 이전 컴포넌트 시작 시간 체크
    const group = groups[currentAnimation.groupId];
    const baseDelay = group?.baseDelay ?? currentAnimation.delay ?? 200;
    let frameId: number;

    const checkStart = () => {
      if (!canStart(currentAnimation.groupId!, order, baseDelay)) {
        frameId = requestAnimationFrame(checkStart);
        return;
      }

      setCanAnimate(true);
      markStarted(currentAnimation.groupId!, order);
    };

    checkStart();

    return () => {
      if (frameId) {
        cancelAnimationFrame(frameId);
      }
    };
  }, [isVisible, canAnimate, order, groups]);

  const delay = useMemo(() => {
    if (!animation?.groupId) return animation?.delay || 0;
    
    const group = groups[animation.groupId];
    const baseDelay = group?.baseDelay ?? animation.delay ?? 200;
    return order * baseDelay;
  }, [animation?.groupId, animation?.delay, groups, order]);

  const styles = useMemo(() => {
    if (!animation) return {};

    const duration = animation.duration ?? 1000;

    switch (animation.type) {
      case 'ripple':
        return {
          transform: canAnimate ? 'scale(1)' : 'scale(0)',
          opacity: canAnimate ? 1 : 0,
          transition: `all ${duration}ms cubic-bezier(0.4, 0, 0.2, 1) ${delay}ms`
        };
      case 'fadeIn':
        return {
          opacity: canAnimate ? 1 : 0,
          transition: `opacity ${duration}ms ease-in-out ${delay}ms`
        };
      case 'slideUp':
        return {
          transform: canAnimate ? 'translateY(0)' : 'translateY(50px)',
          opacity: canAnimate ? 1 : 0,
          transition: `all ${duration}ms ease-out ${delay}ms`
        };
      case 'parallax':
        return {
          transform: `translateY(${delay}px)`,
          transition: `transform ${duration}ms ease-out ${delay}ms`
        };
      default:
        return {};
    }
  }, [animation, canAnimate, delay]);

  return [ref, styles];
};

// import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
// import { useIntersectionObserver } from './useIntersectionObserver';
// import { useAnimationStore } from '@/shared/stores/animationStore';
// import { ExtendedAnimationConfig, AnimationHookReturn } from '@/shared/types/animation';
// import { create } from 'zustand';

// interface AnimationState {
//   startTimes: Map<string, Map<number, number>>;
//   markStarted: (groupId: string, order: number) => void;
//   getStartTime: (groupId: string, order: number) => number;
//   canStart: (groupId: string, order: number, baseDelay: number) => boolean;
//   reset: (groupId: string) => void;
// }

// const useAnimationState = create<AnimationState>((set, get) => ({
//   startTimes: new Map(),
  
//   markStarted: (groupId: string, order: number) => {
//     set(state => {
//       const newMap = new Map(state.startTimes);
//       if (!newMap.has(groupId)) {
//         newMap.set(groupId, new Map());
//       }
//       const groupMap = newMap.get(groupId)!;
//       groupMap.set(order, Date.now());
//       return { startTimes: newMap };
//     });
//   },
  
//   getStartTime: (groupId: string, order: number): number => {
//     return get().startTimes.get(groupId)?.get(order) || 0;
//   },
  
//   canStart: (groupId: string, order: number, baseDelay: number): boolean => {
//     if (order === 0) return true;
    
//     const prevStartTime = get().getStartTime(groupId, order - 1);
//     if (prevStartTime === 0) return false;
    
//     return Date.now() >= prevStartTime + baseDelay;
//   },
  
//   reset: (groupId: string) => {
//     set(state => {
//       const newMap = new Map(state.startTimes);
//       newMap.delete(groupId);
//       return { startTimes: newMap };
//     });
//   }
// }));

// const groupOrders = new Map<string, number>();

// export const useAnimation = (
//   animation?: ExtendedAnimationConfig
// ): AnimationHookReturn => {
//   const ref = useRef<HTMLDivElement>(null);
//   const [isVisible] = useIntersectionObserver(ref, animation?.threshold);
//   const { groups } = useAnimationStore();
//   const [canAnimate, setCanAnimate] = useState(false);
//   const [order, setOrder] = useState(0);
//   const { markStarted, canStart, reset } = useAnimationState();
//   const animationRef = useRef(animation);

//   // 그룹 관련 초기화
//   useEffect(() => {
//     if (!animation?.groupId) return;

//     const groupId = animation.groupId;
//     const currentOrder = groupOrders.get(groupId) ?? 0;
//     setOrder(currentOrder);
//     groupOrders.set(groupId, currentOrder + 1);

//     return () => {
//       if (groupOrders.get(groupId) === currentOrder + 1) {
//         groupOrders.delete(groupId);
//         reset(groupId);
//       }
//     };
//   }, [animation?.groupId]);

//   // 애니메이션 시작 로직
//   useEffect(() => {
//     if (!isVisible || canAnimate) return;
    
//     const currentAnimation = animationRef.current;
    
//     // 개별 애니메이션 처리
//     if (!currentAnimation?.groupId) {
//       const timer = setTimeout(() => {
//         setCanAnimate(true);
//       }, currentAnimation?.delay ?? 0);
      
//       return () => clearTimeout(timer);
//     }

//     // 그룹 애니메이션 처리
//     const group = groups[currentAnimation.groupId];
//     const baseDelay = group?.baseDelay ?? currentAnimation.delay ?? 200;
//     let frameId: number;
//     let isStarted = false;

//     const checkStart = () => {
//       if (isStarted || !canStart(currentAnimation.groupId!, order, baseDelay)) {
//         frameId = requestAnimationFrame(checkStart);
//         return;
//       }

//       isStarted = true;
//       setCanAnimate(true);
//       markStarted(currentAnimation.groupId!, order);
//     };

//     checkStart();

//     return () => {
//       if (frameId) {
//         cancelAnimationFrame(frameId);
//       }
//     };
//   }, [isVisible, order, groups]);

//   const delay = useMemo(() => {
//     if (!animation?.groupId) return animation?.delay || 0;
    
//     const group = groups[animation.groupId];
//     const baseDelay = group?.baseDelay ?? animation.delay ?? 200;
//     return order * baseDelay;
//   }, [animation?.groupId, animation?.delay, groups, order]);

//   const styles = useMemo(() => {
//     if (!animation) return {};

//     const duration = animation.duration ?? 1000;

//     switch (animation.type) {
//       case 'ripple':
//         return {
//           transform: canAnimate ? 'scale(1)' : 'scale(0)',
//           opacity: canAnimate ? 1 : 0,
//           transition: `all ${duration}ms cubic-bezier(0.4, 0, 0.2, 1) ${delay}ms`
//         };
//       case 'fadeIn':
//         return {
//           opacity: canAnimate ? 1 : 0,
//           transition: `opacity ${duration}ms ease-in-out ${delay}ms`
//         };
//       case 'slideUp':
//         return {
//           transform: canAnimate ? 'translateY(0)' : 'translateY(50px)',
//           opacity: canAnimate ? 1 : 0,
//           transition: `all ${duration}ms ease-out ${delay}ms`
//         };
//       case 'parallax':
//         return {
//           transform: `translateY(${delay}px)`,
//           transition: `transform ${duration}ms ease-out ${delay}ms`
//         };
//       default:
//         return {};
//     }
//   }, [animation, canAnimate, delay]);

//   return [ref, styles];
// };
