// src/queries/imageQueries.ts
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { api, withAuthInterceptor } from '../utils/api';
import { templateKeys } from './templateQueries';
import { useUser } from '../stores/authStore';
import { handleQueryError } from '../utils/queryErrorHandler';
import { useNavigate } from 'react-router-dom';


interface ApiResponse<T = any> {
  success: boolean;
  data?: T;
  error?: string;
}


export const imageKeys = {
  all: (userId: string) => ['images', userId] as const,
  list: (userId: string, templateId: string) => [...imageKeys.all(userId), templateId] as const,
};

export const useGetImages = (templateId: string) => {
  // const api = withAuthInterceptor();
  const user = useUser();
  const navigate = useNavigate();

  return useQuery({
    queryKey: imageKeys.list(user?.userId || '', templateId),
    queryFn: async () => {
      try {
        const { data } = await api.get<ApiResponse<ImageData[]>>('/api/media/images', {
          headers: {
            'X-Template-Id': templateId
          }
        });

        if (!data.success) {
          throw new Error(data.error || 'Failed to fetch images');
        }

        return data.data;
      } catch (error) {
        handleQueryError(error, navigate);
        throw error;
      }
    },
    enabled: !!user?.userId
  });
};


export const useImageUpload = (templateId: string) => {
  // const api = withAuthInterceptor();
  const queryClient = useQueryClient();
  const user = useUser();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);

      const { data } = await api.post<any>(
        '/api/media/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Template-Id': templateId
          }
        }
      );

      if (!data.success) {
        throw new Error(data.error || 'Upload failed');
      }

      return data.data;
    },
    onSuccess: () => {
      toast.success('이미지가 성공적으로 업로드되었습니다.');
      queryClient.invalidateQueries({ queryKey: imageKeys.list(user?.userId || '', templateId) });
    },
    onError: (error) => handleQueryError(error, navigate)
    // onError: (error: Error) => {
    //   toast.error(error.message || '이미지 업로드에 실패했습니다.');
    // }
  });
};


export const useDeleteImage = (templateId: string) => {
  // const api = withAuthInterceptor();
  const queryClient = useQueryClient();
  const user = useUser();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (imageId: string) => {
      const { data } = await api.delete<ApiResponse>(
        `/api/media/delete/${imageId}`,
        {
          headers: {
            'X-Template-Id': templateId
          }
        }
      );

      if (!data.success) {
        throw new Error(data.error || 'Delete failed');
      }

      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: imageKeys.list(user?.userId || '', templateId) });
    },
    onError: (error) => handleQueryError(error, navigate)
    // onError: (error: Error) => {
    //   toast.error(error.message || '이미지 삭제에 실패했습니다.');
    // }
  });
};