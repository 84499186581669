import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { router } from '@/builder/routes';
import { queryClient } from './builder/lib/queryClient';
// import { initializeBuilderMode } from '@/shared/constants/environment';
import { useEffect } from 'react';

const App = () => {
  

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster
        position="top-right"
        toastOptions={{
          // 성공 토스트 스타일
          success: {
            duration: 3000,
            style: {
              background: '#22c55e', // 초록색
              color: '#fff',
            },
          },
          // 에러 토스트 스타일
          error: {
            duration: 4000,
            style: {
              background: '#ef4444', // 빨간색
              color: '#fff',
            },
          },
        }}
      />
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App  // default export 추가