import React, { useState } from 'react';

interface MenuItem {
  id: number;
  name: string;
  linkId: string;
}

interface MenuGridEditorProps {
  content: {
    items: MenuItem[];
    essential: any;
  };
  onChange: (content: { items: MenuItem[] }) => void;
}

const MenuGridEditor: React.FC<MenuGridEditorProps> = ({ content, onChange }) => {
  const [items, setItems] = useState<MenuItem[]>(() => {
    // 항상 4개의 메뉴 아이템을 유지
    const initialItems = [...(content.items || [])];
    while (initialItems.length < 4) {
      initialItems.push({
        id: initialItems.length + 1,
        name: '',
        linkId: ''
      });
    }
    return initialItems;
  });

  const getAllMenuItems = () => {
    const menuItems: { id: string; name: string }[] = [];
    content.essential.navigation.menu.forEach((item: any) => {
      if (item.visible) {
         !item.children && menuItems.push({
          id: item.id,
          name: item.name
        });
        
        if (item.children) {
          item.children.forEach((child: any) => {
            if (child.visible) {
              menuItems.push({
                id: child.id,
                name: child.name
              });
            }
          });
        }
      }
    });
    return menuItems;
  };

  const updateItem = (index: number, field: 'name' | 'linkId', value: string) => {
    const newItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setItems(newItems);
    onChange({ items: newItems });
  };

  const menuOptions = getAllMenuItems();

  return (
    <div className="space-y-6">
      {items.map((item, index) => (
        <div 
          key={item.id} 
          className="p-4 bg-gray-50 rounded-lg border space-y-4"
        >
          <div className="text-lg font-medium text-gray-700 mb-3">
            메뉴 {index + 1}
          </div>
          
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-600">
              이름
            </label>
            <input
              type="text"
              value={item.name}
              onChange={(e) => updateItem(index, 'name', e.target.value)}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="메뉴 이름을 입력하세요"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-600">
              연결 링크
            </label>
            <select
              value={item.linkId}
              onChange={(e) => updateItem(index, 'linkId', e.target.value)}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">선택해주세요</option>
              {menuOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuGridEditor;