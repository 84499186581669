import { Check } from "lucide-react";
import React from "react";

interface Step {
  step: number;
  label: string;
}

interface ProgressCircleProps {
  steps: Step[];
  currentStep: number;
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({ steps, currentStep }) => {
  const isCompleted = (stepNumber: number) => stepNumber <= currentStep;
  const isActive = (stepNumber: number) => stepNumber === currentStep;

  return (
    <div className="flex items-center justify-center relative">
      {steps.map((step, index) => (
        <React.Fragment key={step.step}>
          <div className="relative">
            <div className={`w-10 md:w-12 h-10 md:h-12 rounded-full flex items-center justify-center border-[5px] md:border-[6px] ${
              isCompleted(step.step) ? 'border-red-500' : 'border-gray-600'
            } `}>
              {isCompleted(step.step) && (
                <Check className="w-5 h-5 md:w-6 md:h-6 text-red-500" />
              )}
            </div>
            <span className={`absolute -bottom-7 left-1/2 -translate-x-1/2 text-sm whitespace-nowrap ${
              isCompleted(step.step) ? 'text-red-500' : 'text-gray-400'
            }`}>
              {step.label}
            </span>
          </div>
          {index < steps.length - 1 && (
            <div className={`w-16 md:w-24 h-1 md:h-1.5 ${
              isCompleted(step.step) && isCompleted(steps[index + 1].step)
                ? 'bg-red-500'
                : 'bg-gray-600'
            }`} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgressCircle;