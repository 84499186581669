import ButtonWrapper from "@/shared/components/ButtonWrapper";
import TextWrapper from "@/shared/components/TextWrapper";

const Contact03 = ({
  content,
  editablePath
}: any) => {

  return (
    <div className="flex flex-col items-center">
      <TextWrapper
        content={content.title}
        className= 'mt-8'
        editable={editablePath ? {
          type: 'richText',
          path: `${editablePath}.content.title`
        } : undefined}
      />

      <ButtonWrapper
        content={content.button}
        className="mt-8 px-10 py-2"
        editable={editablePath ? {
          type: 'button',
          path: `${editablePath}.content.button`
        } : undefined}
        animation="none"
      />
    </div>
  );
 };

export default Contact03;