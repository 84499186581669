import { useNavigate } from 'react-router-dom';
import LoginForm from '../../components/auth/LoginForm';
import { useLogin } from '@/builder/queries/authQueries';
import { useAuthStore } from '@/builder/stores/authStore';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useTemplateStore } from '@/shared/stores/templateStore';

const LoginPage = () => {
  const navigate = useNavigate();
  const { mutate: login, isPending } = useLogin();

  useEffect(() => {
    useTemplateStore.getState().reset();
  }, []);


  const handleLogin = (email: string, password: string) => {
    login(
      { email, password },
      {
        onSuccess: (data) => {
          useAuthStore.setState({ 
            token: data.data.token,
            user: {
              userId: data.data.user.userId,
              email: data.data.user.email,
              role: data.data.user.role,
              ceoName: data.data.user.ceoName
            },
            isAuthenticated: true
          });
          toast.success('로그인이 완료되었습니다');
          navigate('/admin');
        },
        onError: (error: any) => {
          toast.error(
            error.response?.data?.error || '이메일 또는 비밀번호가 잘못되었습니다'
          );
        }
      }
    );
  };
  // const handleLogin = (email: string, password: string) => {
  //   login(
  //     { email, password },
  //     {
  //       onSuccess: (data) => {
  //         setToken(data.data.token);  // zustand store에도 토큰 저장
  //         toast.success('로그인이 완료되었습니다');
  //         navigate('/admin');
  //       },
  //       onError: (error: any) => {
  //         toast.error(
  //           error.response?.data?.error || '이메일 또는 비밀번호가 잘못되었습니다'
  //         );
  //       }
  //     }
  //   );
  // };


  return (
    <section className="px-4 min-h-screen relative flex items-center justify-center">
      <div className="absolute inset-0 overflow-hidden -z-10">
        <img
          src="/images/auth/login.webp"
          alt="login"
          className="object-cover w-full h-full"
        />
      </div>
      <LoginForm 
        onSubmit={handleLogin}
        isSubmitting={isPending}
      />
    </section>
  );
};

export default LoginPage;