import React, { useEffect, useState } from 'react';

const DayNightSimple = ({ children }: { children: React.ReactNode }) => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [initialProgress, setInitialProgress] = useState(0);
  const [isInitialAnimationComplete, setIsInitialAnimationComplete] = useState(false);

  // Initial animation
  useEffect(() => {
    const animationDuration = 1500;
    const startTime = Date.now();

    const animateInitial = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / animationDuration, 1);

      setInitialProgress(progress);

      if (progress < 1) {
        requestAnimationFrame(animateInitial);
      } else {
        setIsInitialAnimationComplete(true);
      }
    };

    requestAnimationFrame(animateInitial);
  }, []);

  // Combined scroll handler for both iframe and window
  useEffect(() => {
    const handleWindowScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight - windowHeight;
      const scrolled = window.scrollY;
      const progress = Math.min(Math.max(scrolled / documentHeight, 0), 1);
      setScrollProgress(progress);
    };

    // 기존 iframe-scroll 이벤트 처리
    const handleIframeScroll = (e: Event) => {
      if (e instanceof CustomEvent) {
        const { scrollTop } = e.detail;
        const progress = Math.min(Math.max(scrollTop / 1000, 0), 1);
        setScrollProgress(progress);
      }
    };

    // 두 이벤트 모두 리스닝
    window.addEventListener('scroll', handleWindowScroll);
    window.addEventListener('iframe-scroll', handleIframeScroll);

    // 초기 스크롤 위치 계산
    setScrollProgress(0);  // 명시적 초기값 설정

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
      window.removeEventListener('iframe-scroll', handleIframeScroll);
    };
  }, []);

  // Rest of the component remains the same...

  const interpolateColor = (color1: string, color2: string, factor: number) => {
    const r1 = parseInt(color1.slice(1, 3), 16);
    const g1 = parseInt(color1.slice(3, 5), 16);
    const b1 = parseInt(color1.slice(5, 7), 16);
    const r2 = parseInt(color2.slice(1, 3), 16);
    const g2 = parseInt(color2.slice(3, 5), 16);
    const b2 = parseInt(color2.slice(5, 7), 16);
    
    const r = Math.round(r1 + (r2 - r1) * factor);
    const g = Math.round(g1 + (g2 - g1) * factor);
    const b = Math.round(b1 + (b2 - b1) * factor);
    
    return `#${r.toString(16).padStart(2,'0')}${g.toString(16).padStart(2,'0')}${b.toString(16).padStart(2,'0')}`;
  };

  const getSkyColors = (progress: number) => {
    const nightSkyTop = '#010313';     // 밤하늘 top (기존과 동일)
    const nightSkyBottom = '#1e293b';  // 밤하늘 bottom (기존과 동일)
    const daySkyTop = '#163789';       // 낮하늘 top (중간 톤의 파란색)
    const daySkyBottom = '#2563eb';    // 낮하늘 bottom (중간 톤의 파란색)
    const dawnSkyTop = '#0a1733';      // 새벽하늘 top (더 어두운 색으로 변경)
    const dawnSkyBottom = '#142c66';   // 새벽하늘 bottom (더 어두운 색으로 변경)

    if (!isInitialAnimationComplete) {
      // 밤 -> 새벽 -> 낮으로의 전환
      if (progress < 0.5) {
        // 밤 -> 새벽
        const dawnProgress = progress * 2;
        return {
          top: interpolateColor(nightSkyTop, dawnSkyTop, dawnProgress),
          bottom: interpolateColor(nightSkyBottom, dawnSkyBottom, dawnProgress)
        };
      } else {
        // 새벽 -> 낮
        const dayProgress = (progress - 0.5) * 2;
        return {
          top: interpolateColor(dawnSkyTop, daySkyTop, dayProgress),
          bottom: interpolateColor(dawnSkyBottom, daySkyBottom, dayProgress)
        };
      }
    } else {
      return {
        top: interpolateColor(daySkyTop, '#0f172a', scrollProgress),
        bottom: interpolateColor(daySkyBottom, '#1e3a8a', scrollProgress)
      };
    }
  };

  // const getSkyColors = (progress: number) => {
  //   const nightSkyTop = '#010313';
  //   const nightSkyBottom = '#1e293b';
  //   const daySkyTop = '#1e40af';
  //   const daySkyBottom = '#3b82f6';

  //   if (!isInitialAnimationComplete) {
  //     return {
  //       top: interpolateColor(nightSkyTop, daySkyTop, progress),
  //       bottom: interpolateColor(nightSkyBottom, daySkyBottom, progress)
  //     };
  //   } else {
  //     return {
  //       top: interpolateColor(daySkyTop, '#0f172a', scrollProgress),
  //       bottom: interpolateColor(daySkyBottom, '#1e3a8a', scrollProgress)
  //     };
  //   }
  // };

  const skyColors = getSkyColors(initialProgress);
  const effectiveProgress = isInitialAnimationComplete ? scrollProgress : initialProgress;


  return (
    <div className="relative min-h-screen">
      <div className="fixed top-0 left-0 w-full h-full -z-10">
        {/* Sky background */}
        <div 
          className="absolute inset-0 transition-colors duration-1000"
          style={{
            background: `linear-gradient(to bottom, ${skyColors.top}, ${skyColors.bottom})`,
          }}
        />

        {/* Sun/Moon */}
        <div
          className="absolute w-24 h-24 transition-all duration-1000 z-10"
          style={{
            left: '75%',
            top: isInitialAnimationComplete 
              ? `${22 + scrollProgress * 60}%`
              : `${40 - initialProgress * 18}%`,
            transform: isInitialAnimationComplete
              ? `scale(${1 - scrollProgress * 0.3}) rotate(${scrollProgress * 360}deg)`
              : `scale(${0.7 + initialProgress * 0.3})`,
            opacity: isInitialAnimationComplete
              ? (scrollProgress > 0.5 ? (1 - scrollProgress) * 2 : 1)
              : initialProgress,
          }}
        >
          {/* Sun rays */}
          <div
            className="absolute inset-0"
            style={{
              opacity: isInitialAnimationComplete 
                ? 1 - scrollProgress * 2
                : initialProgress,
              animation: 'pulse 3s ease-in-out infinite',
            }}
          >
            {[...Array(12)].map((_, i) => (
              <div
                key={i}
                className="absolute top-1/2 left-1/2 h-1 bg-yellow-400/60"
                style={{
                  width: '150%',
                  transform: `rotate(${i * 30}deg)`,
                  transformOrigin: '0 50%',
                }}
              />
            ))}
          </div>
          
          {/* Sun/Moon circle */}
          <div 
            className="absolute inset-0 rounded-full transition-all duration-1000"
            style={{
              background: effectiveProgress < 0.5
                ? 'radial-gradient(circle at center, #fde047, #f59e0b)'
                : 'radial-gradient(circle at center, #f1f5f9, #e2e8f0)',
              boxShadow: effectiveProgress < 0.5
                ? '0 0 60px #f59e0b'
                : '0 0 40px rgba(255, 255, 255, 0.5)',
            }}
          />
        </div>

        {/* Stars */}
        <div className="absolute inset-0">
          {[...Array(50)].map((_, i) => (
            <div
              key={i}
              className="absolute rounded-full bg-white transition-opacity duration-1000"
              style={{
                width: `${Math.random() * 2 + 1}px`,
                height: `${Math.random() * 2 + 1}px`,
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                opacity: isInitialAnimationComplete 
                ? Math.max(0, scrollProgress - 0.3) * Math.random() * 0.7  // 최대 투명도 70%로 제한
                : Math.max(0, (1 - initialProgress) - 0.3) * Math.random() * 0.5,  // 초기 투명도 50%로 제한
                animation: `twinkle ${Math.random() * 2 + 2}s ease-in-out infinite`,
              }}
            />
          ))}
        </div>

        {/* Clouds */}
        {[...Array(6)].map((_, i) => (
          <div
            key={i}
            className="absolute rounded-full filter blur-xl transition-all duration-1000"
            style={{
              width: `${Math.random() * 300 + 100}px`,
              height: `${Math.random() * 100 + 50}px`,
              left: `${(i * 20 + Math.random() * 10)}%`,
              top: `${i > 3 ? (60 + Math.random() * 20) : (Math.random() * 40)}%`,
              background: isInitialAnimationComplete
                ? `rgba(255, 255, 255, ${0.8 - scrollProgress * 0.7})`
                : `rgba(255, 255, 255, ${Math.min(0.8, initialProgress * 1.2)})`,
              transform: `translateX(${effectiveProgress * (Math.random() * 100 - 50)}px)`,
            }}
          />
        ))}
      </div>

      {/* Content */}
      <div className="relative">
        {children}
      </div>

      {/* Animations */}
      <style>
        {`
          @keyframes twinkle {
            0%, 100% { opacity: 0.1; }
            50% { opacity: 0.6; }
          }
          @keyframes pulse {
            0%, 100% { transform: scale(1); }
            50% { transform: scale(1.1); }
          }
        `}
      </style>
    </div>
  );
};

export default DayNightSimple;