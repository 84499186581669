import { useState } from 'react';
import Header from '@/builder/components/admin/Header';
import { useTemplateStore } from '@/shared/stores/templateStore';
import NotFoundTemplate from '../../components/admin/NotFoundTemplate';
import NotFoundUser from '@/builder/components/admin/NotFoundUser';
import ExpiredTemplate from '@/builder/components/admin/ExpiredTemplate';
import { Alert, AlertDescription } from '@/shared/components/ui/alert';
import { AlertCircle, ChevronDown } from 'lucide-react';
import DomainConnectionContent from '@/builder/components/domain/DomainConnectionContent';

const PageContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="space-y-8 max-w-5xl mx-auto">
    <Header
      title="도메인 연결"
      subtitle="내 웹사이트에 도메인을 연결하고 배포하세요"
    />
    {children}
  </div>
);

const ConnectionDomain = () => {
  const { templates, selectedTemplate } = useTemplateStore();
  const selectedTemplateId = selectedTemplate?.templateId;

  if (!templates || templates.length === 0) {
    return (
      <PageContainer>
        <NotFoundUser />
      </PageContainer>
    );
  }

  if (selectedTemplate.status === 'inactive') {
    return (
      <div className="space-y-8 max-w-6xl mx-auto">
        <PageContainer>
          <ExpiredTemplate />
        </PageContainer>
      </div>
    );
  }

  if (!selectedTemplateId) {
    return (
      <PageContainer>
        <NotFoundTemplate />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Alert>
        <AlertCircle className="h-4 w-4 text-blue-500 mt-1" />
        <AlertDescription>
          도메인은 웹사이트의 주소를 의미합니다(예: 네이버 - naver.com).<br/>
          편집한 사이트를 배포하기 위해서는 개인도메인 연결이 필요하며, 도메인은 회원님께서 별도로 구매하셔야 합니다. 
        </AlertDescription>
      </Alert>
      <DomainConnectionContent />
    </PageContainer>
  );
};

export default ConnectionDomain;