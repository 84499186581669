import React from 'react';
import { CalendarDays, Clock, ChevronDown, FileText } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useRefund, useSubscriptionRenewal } from '@/builder/queries/paymentQueries';
import { getSubscriptionState } from '@/builder/utils/subscriptionUtils';

const SubscriptionCard = ({ 
  subscription, 
  templateInfo, 
  expandedHistory, 
  setExpandedHistory 
}: any) => {
  const navigate = useNavigate();
  const isSubscription = subscription.paymentType === 'subscription';
  const refundMutation = useRefund();
  const subscriptionRenewalMutation = useSubscriptionRenewal();

  if (!subscription) return null;

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'inactive':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-blue-100 text-blue-800';
    }
  };

  const getStatusText = (status: string) => {
    if (!isSubscription) {
      return status === 'active' ? '사용중' : '만료됨';
    }
    return status === 'active' ? '구독중' : '만료됨';
  };

  const state = getSubscriptionState({ subscription, templateInfo });


  const handleButtonClick = () => {
    state.action(
      navigate, 
      templateInfo, 
      {
        refundMutation,
        subscriptionRenewalMutation
      }
    );
  };

  return (
    <div className="bg-white rounded-lg border shadow-sm">
      <div className="p-6">
        <div className="flex justify-between items-start">
          <div>
            <h2 className="text-lg font-semibold">
              {templateInfo?.name || subscription.templateId}
            </h2>
            <p className="text-sm text-gray-500 mt-1">
              {isSubscription ? '템플릿 구독 정보' : '템플릿 사용 정보'}
            </p>
          </div>
          <div className={`px-4 py-2 rounded-full font-medium ${getStatusColor(templateInfo?.status)}`}>
            {getStatusText(templateInfo?.status)}
          </div>
        </div>

        <div className="mt-6 space-y-4">
          <div className="flex items-start space-x-4">
            <CalendarDays className="w-5 h-5 text-gray-500 mt-1" />
            <div>
              <p className="font-medium">
                {state.expiryText}
              </p>
              <p className="text-gray-600">
                {new Date(subscription.expiryDate).toLocaleDateString()}
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <Clock className="w-5 h-5 text-gray-500 mt-1" />
            <div>
              <p className="font-medium">구독 종류</p>
              <p className="text-gray-600">
                {isSubscription 
                  ? ( templateInfo?.status === 'active' ? `${subscription.billingCycle}개월 ${subscription.plan}` : '구독이 만료되었습니다. 재사용을 원하면 아래의 버튼을 눌러주세요.')
                  : '일회성 지불'}
              </p>
            </div>
          </div>
        </div>

        <button
          onClick={() => setExpandedHistory(
            expandedHistory === subscription.templateId ? null : subscription.templateId
          )}
          className="mt-6 flex items-center text-sm text-gray-500 hover:text-gray-700"
        >
          <ChevronDown className={`w-4 h-4 mr-1 transition-transform ${
            expandedHistory === subscription.templateId ? 'rotate-180' : ''
          }`} />
          결제 내역 {expandedHistory === subscription.templateId ? '접기' : '보기'}
        </button>

        {expandedHistory === subscription.templateId && (
          <div className="mt-4 space-y-4">
            {[...subscription.billingHistory].sort((a, b) => 
              new Date(b.paymentDate).getTime() - new Date(a.paymentDate).getTime()
            ).map((bill: any, index: number) => (
              <div key={index} className="flex items-center justify-between p-4 border rounded-lg">
                <div className="flex items-center space-x-4">
                  <FileText className="w-5 h-5 text-gray-500" />
                  <div>
                    <p className="font-medium">
                      {new Date(bill.paymentDate).toLocaleDateString()}
                    </p>
                    <p className="text-gray-600">
                      {bill.amount.toLocaleString('ko-KR')}원
                    </p>
                    {bill.orderId == 'onetime' &&
                      <p className="mt-0.5 text-gray-500 text-xs">
                        * 일회성 결제는 결제한 플랫폼에서 확인이 가능합니다.
                      </p>
                    }
                  </div>
                </div>
                <span className={`${bill.status === 'paid' ? 'text-green-600' : 'text-red-600'}`}>
                  {bill.status === 'paid' ? '결제됨' : '환불됨'}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="border-t p-4">
        <button 
          onClick={handleButtonClick}
          disabled={refundMutation.isPending}
          // className='w-full px-4 py-2 rounded-lg transition-colors bg-red-600 text-white hover:bg-red-700'
          className={`w-full px-4 py-2 rounded-lg transition-colors ${
            refundMutation.isPending
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-red-600 hover:bg-red-700'
          } text-white`}
          style={{
            backgroundColor: refundMutation.isPending 
              ? '#9CA3AF'
              : state.buttonStyle?.backgroundColor,
            color: refundMutation.isPending
              ? '#fff'
              : state.buttonStyle?.textColor,
            border: refundMutation.isPending
              ? 'none'
              : state.buttonStyle?.border
          }}
        >
          {refundMutation.isPending ? '처리중...' : state.buttonText}
        </button>
      </div>
    </div>
  );
};

export default SubscriptionCard;