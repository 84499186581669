import ScrollToTop from '@/shared/components/ScrollToTop';
import { Outlet, useLocation } from 'react-router-dom';
import data from "@/builder/config/landing.json"
import Header from '@/templates/components/header/Header02';
import Footer from '@/templates/components/footer/Footer02';

const LandingLayout = () => {
  const location = useLocation();
  const isAdminPath = location.pathname.startsWith('/admin');

  return (
    <div className="min-h-screen">
      <ScrollToTop />
      {!isAdminPath &&
        <Header
          {...(data.essential)}
        />
      }
      <main>
        <Outlet />
      </main>
      
      {!isAdminPath &&
        <Footer
          {...(data.essential.footer)}
        />
      }
    </div>
  );
};

export default LandingLayout;