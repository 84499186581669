import { useMutation } from '@tanstack/react-query';
import { api } from '../utils/api';

interface RegisterRequest {
 email: string;
 password: string;
 ceoName?: string;
 companyName: string;
 taxId?: string;
 phoneNumber?: string;
 selectedTemplate?: any;
 couponCode: string;
 terms: {
   serviceAgreement: boolean;
   privacyAgreement: boolean;
   marketingAgreement: boolean;
 };
}

interface AuthResponse {
  token: string;
  data: {
    token: string;
    user: any;
  }
}

export const useRegister = () => {
 return useMutation({
   mutationFn: async ({ 
     email, 
     password,
     ceoName,
     companyName,
     taxId,
     phoneNumber,
     selectedTemplate,
     couponCode,
     agreedToService,
     agreedToPrivacy,
     agreedToMarketing
   }: { 
     email: string;
     password: string;
     ceoName?: string;
     companyName: string;
     taxId?: string;
     phoneNumber?: string;
     selectedTemplate?: any;
     couponCode: string;
     agreedToService: boolean;
     agreedToPrivacy: boolean;
     agreedToMarketing: boolean;
   }) => {
     const requestData: RegisterRequest = {
       email,
       password,
       ceoName,
       companyName,
       taxId,
       phoneNumber,
       selectedTemplate,
       couponCode,
       terms: {
         serviceAgreement: agreedToService,
         privacyAgreement: agreedToPrivacy,
         marketingAgreement: agreedToMarketing
       }
     };

     const { data } = await api.post<AuthResponse>('/api/auth/register', requestData);
     return data;
   }
 });
};

export const useLogin = () => {
  return useMutation({
    mutationFn: async ({ email, password }: { 
      email: string; 
      password: string; 
    }) => {
      const { data } = await api.post<AuthResponse>('/api/auth/login', {
        email,
        password
      });
      // 로그인 성공 시 토큰 저장
      localStorage.setItem('token', data.data.token);
      return data;
    }
  });
};

export const useChangePassword = () => {
  return useMutation({
    mutationFn: async ({
      currentPassword,
      newPassword
    }: {
      currentPassword: string;
      newPassword: string;
    }) => {
      const { data } = await api.post('/api/auth/change-password', {
        currentPassword,
        newPassword
      });
      return data;
    }
  });
};