import { useTemplateStore } from '@/shared/stores/templateStore';
import { Layout, Clock, Shield, Code2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useCreateTemplate } from '@/builder/queries/templateQueries';
import NotFoundUser from '@/builder/components/admin/NotFoundUser';
import ExpiredTemplate from '@/builder/components/admin/ExpiredTemplate';


const IntroHeader = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h1 className="text-2xl font-bold mb-2">THIS IS WEB</h1>
      <p className="text-gray-600">
        웹빌더의 관리자 페이지입니다. 여기서 모든 서비스를 한눈에 관리하실 수 있습니다.
      </p>
    </div>
  )
}

const Dashboard = () => {
  const { templates, selectedTemplate } = useTemplateStore();

  if (!templates || templates.length === 0) {
    return (
      <div className="space-y-8 max-w-6xl mx-auto">
        <IntroHeader />        
        <NotFoundUser />
      </div>
    );
  }

  if (selectedTemplate.status === 'inactive') {
    return (
      <div className="space-y-8 max-w-6xl mx-auto">
        <IntroHeader />        
        <ExpiredTemplate />
      </div>
    )
  }

  
  return (
    <div className="space-y-8 max-w-6xl mx-auto">
      {/* 환영 메시지 */}
      <IntroHeader />      

      {/* 통계 카드 */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {stats.map((stat) => (
          <div key={stat.title} className="bg-white p-6 rounded-lg shadow">
            <div className="flex items-center justify-between">
              <div className={`${stat.color} p-3 rounded-lg`}>
                <stat.icon className="w-6 h-6 text-white" />
              </div>
              <span className="text-sm text-gray-500">{stat.change}</span>
            </div>
            <h3 className="text-2xl font-bold mt-4">{stat.value}</h3>
            <p className="text-gray-600 text-sm">{stat.title}</p>
          </div>
        ))}
      </div>

      {/* 예정된 기능 */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-bold mb-4">
          <span className="text-blue-500">Coming Soon</span> - 예정된 기능
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {comingSoon.map((feature) => (
            <div
              key={feature.title}
              className="border border-gray-200 p-4 rounded-lg hover:border-blue-500 transition-colors"
            >
              <h3 className="font-semibold text-lg mb-2">{feature.title}</h3>
              <p className="text-gray-600 text-sm mb-3">{feature.description}</p>
              <span className="text-blue-500 text-sm font-medium">{feature.date}</span>
            </div>
          ))}
        </div>
      </div>

      {/* 퀵 링크 */}
      <div className="flex flex-col gap-8 md:flex-row justify-between items-center bg-gradient-to-r from-blue-500 to-blue-600 p-6 rounded-lg shadow text-white">
        <div>
          <h2 className="text-xl font-bold mb-4">사이트 주소</h2>
          <p>
            {
              selectedTemplate &&
              (selectedTemplate?.url &&
              selectedTemplate?.url
              ||
              `도메인을 연결해주세요. 그 전에도 사이트 편집은 가능합니다.
              홈페이지 배포는 도메인 연결 후 영업일 3일 내로 완료됩니다.`)
              ||
              `관리할 홈페이지를 선택하지 않으셨습니다.
              좌측 상단의 템플릿 선택 메뉴에서 템플릿을 선택해주세요.`
            }
            
          </p>
          
        </div>
          {selectedTemplate &&
            <a
              href= {selectedTemplate?.url ? '/admin/templates' : 'https://husky-coffee-14c.notion.site/153c3ee6db8d80caa64dce7fcd67735b'}
              target='_blank'
              className="bg-white text-blue-600 px-8 md:px-4 py-2 rounded hover:bg-blue-50 transition-colors"
            >
              {selectedTemplate?.url ? "사이트 편집" : "도메인 연결"}
            </a>
          }
      </div>
    </div>
  );
};



// 임시 통계 데이터
const stats = [
  {
    title: '템플릿 종류',
    value: '4',
    change: '기본 템플릿',
    icon: Layout,
    color: 'bg-blue-500'
  },
  {
    title: '평균 제작 시간',
    value: '10분',
    change: '템플릿 기반 빠른 제작',
    icon: Clock, // 시간을 나타내는 아이콘으로 변경
    color: 'bg-green-500'
  },
  {
    title: '서비스 안정성',
    value: '99.9%',
    change: '클라우드플레어 기반',
    icon: Shield, // 보안/안정성을 나타내는 아이콘으로 변경
    color: 'bg-orange-500' // 안정성을 나타내는 색상으로 변경
  },
  {
    title: '기술 스택',
    value: '최신',
    change: 'React 기반',
    icon: Code2, // 코드/기술을 나타내는 아이콘으로 변경
    color: 'bg-purple-500' // 기술을 나타내는 색상으로 변경
  }
];

const comingSoon = [
  {
    title: 'New 템플릿',
    description: '새로운 디자인의 템플릿이 공개될 예정입니다.',
    date: 'Q1'
  },
  {
    title: 'AI 추천 템플릿',
    description: 'AI를 기반으로 산업군에 따른 템플릿을 추천합니다.',
    date: 'Q2'
  },
  {
    title: '고급 분석 기능',
    description: '방문자 행동 분석과 전환율 추적 기능을 제공합니다.',
    date: 'Q2'
  }
];

export default Dashboard;