import { DEFAULT_ANIMATION, EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";
import { useEditableComponent } from "@/shared/hooks/useEditableComponent";
import { useAnimation } from "../hooks/animation/useAnimation";
import { isBuilderMode } from '@/shared/constants/environment';
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

export interface ButtonWrapperProps {
  content: any;
  className?: string;
  editable?: {
    type: string;
    path: string;
  };
  animation?: typeof DEFAULT_ANIMATION | 'none';
  onClick?: () => void;
}

const hexToRGBA = (hex: string, opacity: number) => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const ButtonWrapper = ({
  content,
  className = '',
  editable,
  animation = DEFAULT_ANIMATION,
  onClick
}: ButtonWrapperProps) => {
  const finalAnimation = animation === 'none'
    ? undefined
    : typeof animation === 'object'
      ? { ...DEFAULT_ANIMATION, ...animation }
      : DEFAULT_ANIMATION;

  const { handleComponentSelect } = useEditableComponent();
  const [ref, animationStyles] = useAnimation(finalAnimation);

  const handleClick = (e: React.MouseEvent) => {
    if (isBuilderMode()) {
      e.preventDefault();
      if (editable && handleComponentSelect) {
        handleComponentSelect({
          type: editable.type,
          path: editable.path,
          content: content
        });
      }
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  // 배경색에만 투명도 적용
  const backgroundColor = content?.styles?.backgroundColor && content?.styles?.opacity != null
    ? hexToRGBA(content.styles.backgroundColor, content.styles.opacity / 100)
    : content?.styles?.backgroundColor;

  const commonProps = {
    ref: ref as any,
    onClick: handleClick,
    className: `
      relative inline-block text-center hover:opacity-80
      ${className}
      ${isBuilderMode() && editable ? EDITABLE_HOVER_CLASSES : ''}
    `,
    style: {
      ...animationStyles,
      borderRadius: `${content?.styles?.borderRadius || 0}px`,
      backgroundColor,
      borderColor: content?.styles?.borderColor || 'transparent',
      borderWidth: `${content?.styles?.borderWidth || 0}px`,
      borderStyle: content?.styles?.borderWidth ? 'solid' : 'none'
    }
  };

  if (!isBuilderMode() && (content.link)) {
    return (
      <Link
        to={content.link}
        {...commonProps}
        dangerouslySetInnerHTML={{ 
          __html: DOMPurify.sanitize(content.text) 
        }}
      />
    );
  }

  return (
    <button
      {...commonProps}
      dangerouslySetInnerHTML={{ 
        __html: DOMPurify.sanitize(content.text) 
      }}
    />
  );
};

export default ButtonWrapper;

// import { DEFAULT_ANIMATION, EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";
// import { useEditableComponent } from "@/shared/hooks/useEditableComponent";
// import { useAnimation } from "../hooks/animation/useAnimation";
// import { isBuilderMode } from '@/shared/constants/environment';
// import { Link } from "react-router-dom";
// import DOMPurify from "dompurify";

// export interface ButtonWrapperProps {
//   content: any;
//   className?: string;
//   editable?: {
//     type: string;
//     path: string;
//   };
//   animation?: typeof DEFAULT_ANIMATION | 'none';
//   onClick?: () => void;
// }


// const ButtonWrapper = ({
//   content,
//   className = '',
//   editable,
//   animation = DEFAULT_ANIMATION,
//   onClick
// }: ButtonWrapperProps) => {
//   const finalAnimation = animation === 'none'
//     ? undefined
//     : typeof animation === 'object'
//       ? { ...DEFAULT_ANIMATION, ...animation }
//       : DEFAULT_ANIMATION;

//   const { handleComponentSelect } = useEditableComponent();
//   const [ref, animationStyles] = useAnimation(finalAnimation);

//   const handleClick = (e: React.MouseEvent) => {
//     if (isBuilderMode()) {
//       e.preventDefault();
//       if (editable && handleComponentSelect) {
//         handleComponentSelect({
//           type: editable.type,
//           path: editable.path,
//           content: content
//         });
//       }
//       return;
//     }

//     if (onClick) {
//       onClick();
//     }
//   };

//   const commonProps = {
//     ref: ref as any,
//     onClick: handleClick,
//     className: `
//       relative inline-block text-center
//       ${className}
//       ${isBuilderMode() && editable ? EDITABLE_HOVER_CLASSES : ''}
//     `,
//     style: {
//       ...animationStyles,
//       borderRadius: `${content?.styles?.borderRadius || 0}px`,
//       backgroundColor: content?.styles?.backgroundColor,
//       opacity: content?.styles?.opacity != null
//         ? content.styles.opacity / 100
//         : 1
//     }
//   };

//   if (isBuilderMode() && content.link) {  // link가 있을 때만 Link 컴포넌트 사용
//     return (
//       <Link
//         to={content.link}
//         {...commonProps}
//         dangerouslySetInnerHTML={{ 
//           __html: DOMPurify.sanitize(content.text) 
//         }}
//       />
//     );
//   }

//   return (
//     <button
//       {...commonProps}
//       dangerouslySetInnerHTML={{ 
//         __html: DOMPurify.sanitize(content.text) 
//       }}
//     />
//   );
// };

// export default ButtonWrapper;