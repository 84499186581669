import { PlusCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ExpiredTemplate = () => {
  const navigate = useNavigate();
  
  const handleCreateTemplate = () => {
    navigate('/admin/user/subscription');
  };
  
  return (
    <div className="flex flex-col items-center justify-center bg-white rounded-xl shadow-lg px-8 py-28 text-center">
      <PlusCircle className="w-16 h-16 text-red-500 mb-4" />
      <h2 className="text-2xl font-bold mb-2">구독이 만료되었습니다.</h2>
      <p className="text-gray-600 mb-8">
        지금 바로 재구독하고, 사이트를 활성화시켜보세요!
      </p>
      <button
        onClick={handleCreateTemplate}
        className="bg-red-500 hover:bg-red-600 text-white font-semibold py-3 px-8 rounded-lg transition-colors duration-200 flex items-center space-x-2"
      >
        <span>재구독 하러가기</span>
      </button>
    </div>
  );
};

export default ExpiredTemplate;