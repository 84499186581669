import { isBuilderMode } from '@/shared/constants/environment';
import { EDITABLE_HOVER_CLASSES } from "@/shared/constants/styles";
import { usePageStore } from '@/shared/stores/pageStore';
import DivWithAnimation from '../common/DivWithAnimation';
import { useGroupAnimation } from '@/shared/hooks/animation/useGroupAnimation';

interface GridMenuItem {
  id: number;
  name: string;
  linkId: string;
}

interface CardGridProps {
  essential: any;
  content?: {
    items: GridMenuItem[];
  };
  editablePath?: string;
}

const MenuGrid = ({ essential, items = [] }: { 
  essential: any;
  items: GridMenuItem[];
}) => {
  const groupId = useGroupAnimation(100);
  const findMenuPath = (linkId: string) => {
    for (const item of essential.navigation.menu) {
      if (item.id === linkId) return item.path;
      if (item.children) {
        const child = item.children.find((c: any) => c.id === linkId);
        if (child) return child.path;
      }
    }
    return '';
  };

  return (
    <div className="flex flex-col gap-6 md:gap-8">
      {items.map((item) => (
        <DivWithAnimation
          animation={{
            groupId: groupId,
          }}
        >
          <a
            key={item.id}
            href={!isBuilderMode() ? findMenuPath(item.linkId) : ''} 
            onClick={(e) => {
              if (isBuilderMode()) {
                e.preventDefault();
              }
            }}
            className="flex flex-col items-center justify-center py-8 hover:opacity-80 md:h-auto 
              text-white text-2xl md:text-4xl font-semibold bg-white/20"
          >
            {item.name}
          </a>
        </DivWithAnimation>
      ))}
    </div>
  );
};

const generateId = () => {
  return `grid_${Math.random().toString(36).substr(2, 9)}`;
};

const CardGrid03 = ({
  essential,
  content = { items: [] },
  editablePath
}: CardGridProps) => {
  const setSelectedComponent = usePageStore(state => state.setSelectedComponent);

  const handleGridClick = () => {
    if (editablePath) {
      const editorConfig: any = {  // any 타입으로 지정
        type: 'menus',
        path: `${editablePath}.content`,
        content: {
          items: content.items || [],
          essential
        }
      };

      setSelectedComponent({
        id: generateId(),
        ...editorConfig
      });
    }
  };

  return (
    <div 
      onClick={handleGridClick}
      className={isBuilderMode() && editablePath ? EDITABLE_HOVER_CLASSES : ''}
    >
      <MenuGrid 
        essential={essential}
        items={content.items}
      />
    </div>
  );
};

export default CardGrid03;