import { useTemplateData } from '@/builder/queries/templateQueries';
import NavigationMenu from './NavigationMenu';
import { Blend, Monitor, Phone, Smartphone } from 'lucide-react';
import { usePageStore } from '@/shared/stores/pageStore';
import { useTemplateStore } from '@/shared/stores/templateStore';

export type EditableComponent = {
  id: string;
  type: 'text';
  content: string;
 } | {
  id: string;
  type: 'image';
  content: string;
 };
 
 export interface EditorHeaderProps {
  onSave: () => void;
  onExit: () => void;
  isMobilePreview: boolean;
  onChangeView: () => void;
  navigationMenu: {name: string, path: string}[];
}


const generateId = () => {
  return `essential_${Math.random().toString(36).substr(2, 9)}`;
};

const EditorHeader = ({ 
  onSave,
  onExit,
  isMobilePreview,
  onChangeView,
  navigationMenu,  // 변경: templateData 대신 필요한 데이터만 받기
}: EditorHeaderProps) => {

  const templateData = useTemplateStore(state => state.templateData);
  const companyData = templateData.essential.footer.company;
  const setSelectedComponent = usePageStore(state => state.setSelectedComponent);
  
    const handleClick = () => {
      const editorConfig: any = {
        type: 'infos',
        path: `essential.footer`,
        content: {
          companyData
        }
      };
      setSelectedComponent({
        id: generateId(),
        ...editorConfig
      });
    };
  



 return (
   <header className="h-16 p-2 border-b flex items-center justify-between px-4 bg-white">
     <div className="flex items-center">
      <NavigationMenu
        menu={navigationMenu.map((item, index) => ({
          id: `menu-${index}`, // 고유 ID 추가
          name: item.name,
          path: item.path,
          visible: true, // 기본값으로 true 설정
        }))}
      />
      {/* <NavigationMenu menu={navigationMenu || []} /> */}
      <button
        onClick={handleClick}
        className="flex items-center gap-2 px-5 py-2 bg-blue-500 text-white rounded"><Blend className='w-5 h-5'
      />
        로고관리
      </button>
     </div>



     <div className="flex items-center space-x-6">
      <div>
        <button
          onClick={onChangeView}
          className="flex items-center gap-2 px-3 py-2 rounded hover:bg-gray-100"
        >
          {isMobilePreview ? (
            <>
              <Monitor className="w-5 h-5" />
            </>
          ) : (
            <>
              <Smartphone className="w-5 h-5" />
            </>
          )}
        </button>
      </div>
      <div className='flex space-x-3'>
        <button onClick={onExit} className="px-5 py-2 border rounded">나가기</button>
        <button onClick={onSave} className="px-5 py-2 bg-blue-500 text-white rounded">변경하기</button>
      </div>
     </div>
   </header>
 );
};

export default EditorHeader;