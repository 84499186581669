import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CalendarDays, AlertCircle } from 'lucide-react';
import { useGroupAnimation } from '@/shared/hooks/animation/useGroupAnimation';
import DivWithAnimation from '@/templates/components/common/DivWithAnimation';
import { PlanCard } from '@/builder/components/payment/PlanCard';
import { Alert, AlertDescription } from '@/shared/components/ui/alert';

const PageContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="space-y-8 max-w-4xl mx-auto">
    {children}
  </div>
);


const UserConvertSubscription = () => {
  const groupId = useGroupAnimation(100);
  const location = useLocation();
  const selectedTemplate = location.state?.selectedTemplate;

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <PageContainer>
      {/* 현재 사용중인 템플릿 정보 */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h1 className="text-2xl font-bold mb-2">구독 전환하기</h1>
        <p className="text-gray-600">
          디스이즈웹을 만료없이 계속해서 이용하세요.
        </p>
      </div>

      <Alert>
        <AlertCircle className="h-4 w-4 text-blue-500 mt-1" />
        <AlertDescription>
          구독 전환 시 현재일 기준으로 결제가 이루어집니다.<br/>
          현재 만료일({formatDate(selectedTemplate?.subscriptions.expiryDate)})까지는 환불이 가능하며, 그 이후에 구독을 취소하는 경우에는 다음 번의 구독 갱신 취소가 이루어집니다.
        </AlertDescription>
      </Alert>

      {/* 플랜 선택 */}
      <div className="grid md:grid-cols-2 gap-6">
        <DivWithAnimation
          animation={{
            groupId: groupId,
          }}
          className="min-h-20"
        >
          <PlanCard
            isAnnual={true}
            selectedTemplate={selectedTemplate}
            isConversion={true}
          />
        </DivWithAnimation>
        <DivWithAnimation
          animation={{
            groupId: groupId,
          }}
          className="min-h-20"
        >
          <PlanCard
            isAnnual={false}
            selectedTemplate={selectedTemplate}
            isConversion={true}
          />
        </DivWithAnimation>
      </div>
    </PageContainer>
  );
};

export default UserConvertSubscription;