import { create } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { fetchConfigData } from '../hooks/fetchConfigData';
import { DEV_CONFIG } from '@/templates/config/templateEnv';
import { getCurrentTemplateConfig } from '@/templates/config/templateConfig';

// !!! Caution !!!
// : Don't delete comments for Deloyment


interface TemplateState {
  templates: any | null;
  selectedTemplate: any | null;
  templateData: any | null;
  setTemplates: (templates: any | null) => void;
  setSelectedTemplate: (type: any) => void;
  setTemplateData: (data: any) => void;
  reset: any
}

type TemplatePersist = Pick<TemplateState, "selectedTemplate">;

const persistConfig: PersistOptions<TemplateState, TemplatePersist> = {
 name: 'template-storage',
 partialize: (state) => ({ 
  templates: state.templates,
  selectedTemplate: state.selectedTemplate,
 }),
};

export const useTemplateStore = create<TemplateState>()(
 persist(
  (set) => ({
    templateData: null,
    // // for Deployment
    // templateData: DEV_CONFIG.useSampleData 
    // ? getCurrentTemplateConfig().sampleData 
    // : getCurrentTemplateConfig().defaultData,  
    selectedTemplate: null,
    templates: null,
    setTemplateData: (data) => set({ templateData: data }),
    setSelectedTemplate: (template) => set({ selectedTemplate: template }),
    setTemplates: (templates) => set({ templates }),
    reset: () => set({ templateData: null, selectedTemplate: null, templates: null })
  }),
   persistConfig
 )
);

// // for Deployment
// const getInitialData = async () => {
//   try {
//     return await fetchConfigData();
//   } catch (error) {
//     console.error('Error fetching initial data:', error);
//     return DEV_CONFIG.useSampleData 
//     ? getCurrentTemplateConfig().sampleData 
//     : getCurrentTemplateConfig().defaultData;
//   }
// };
// const initialState = getInitialData();
// initialState.then(data => {
//   useTemplateStore.setState({ templateData: data });
// });