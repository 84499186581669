import React from 'react';

interface AlertProps {
  children: React.ReactNode;
}

export const Alert = ({ children }: AlertProps) => (
  <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-start gap-3">
    {children}
  </div>
);

export const AlertDescription = ({ children }: AlertProps) => (
  <p className="text-blue-700 text-sm leading-6">{children}</p>
);