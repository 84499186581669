import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useConvertToSubscription, useIssueBilling, useReactivateSubscription, useUpdateCard } from '@/builder/queries/paymentQueries';
import toast from 'react-hot-toast';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate: issueBilling, isPending: isIssuePending } = useIssueBilling();
  const { mutate: convertToSubscription, isPending: isConvertPending } = useConvertToSubscription();
  const { mutate: updateCard, isPending: isUpdatePending } = useUpdateCard();
  const { mutate: reactivateSubscription, isPending: isReactivatePending } = useReactivateSubscription();

  const isPending = isIssuePending || isConvertPending || isUpdatePending;

  useEffect(() => {
    const paymentDataString = sessionStorage.getItem('paymentData');
    if (!paymentDataString) {
      toast.error('오류가 발생했습니다. 다시 시도해주세요.');
      navigate('/admin/payment/plans');
      return;
    }
    const paymentData = JSON.parse(paymentDataString);

    // 비활성 구독 재결제 처리
    if (paymentData.isReactive) {
      reactivateSubscription(
        { paymentData },
        {
          onSuccess: (data) => {
            sessionStorage.removeItem('paymentData');
            if (data.success) {
              toast.success('재구독이 성공적으로 완료되었습니다.');
              navigate('/admin/user/subscription');
            } else {
              toast.error(data.error || '재구독 처리에 실패했습니다. 다시 시도해주세요.');
              navigate('/admin/user/subscription');
            }
          },
          onError: (error) => {
            sessionStorage.removeItem('paymentData');
            toast.error('재구독 처리 중 오류가 발생했습니다.');
            navigate('/admin/user/subscription');
          }
        }
      );
      return;
    }

    const authKey = searchParams.get('authKey');
    const customerKey = searchParams.get('customerKey');

    if (!authKey || !customerKey) {
      toast.error('오류가 발생했습니다. 다시 시도해주세요.');
      navigate('/admin/payment/plans');
      return;
    }


    // 카드 변경 처리
    if (paymentData.isUpdatingCard) {
      updateCard(
        { authKey, customerKey },
        {
          onSuccess: (data) => {
            sessionStorage.removeItem('paymentData');
            if (data.success) {
              toast.success('카드가 성공적으로 변경되었습니다.');
              navigate('/admin/user/subscription');
            } else {
              toast.error(data.error || '카드 변경에 실패했습니다. 다시 시도해주세요.');
              navigate('/admin/user/subscription');
            }
          },
          onError: (error) => {
            sessionStorage.removeItem('paymentData');
            toast.error('카드 변경 중 오류가 발생했습니다.');
            navigate('/admin/user/subscription');
          }
        }
      );
      return;
    }

    // 일반 결제 또는 구독 전환 처리
    const mutationData = {
      authKey,
      customerKey,
      paymentData: {
        plan: paymentData.plan,
        billingCycle: paymentData.billingCycle,
        selectedTemplate: paymentData.selectedTemplate
      }
    };

    const mutation = paymentData.isConversion ? convertToSubscription : issueBilling;
    const successMessage = paymentData.isConversion 
      ? '구독이 성공적으로 전환되었습니다.'
      : '결제가 성공적으로 완료되었습니다.';
    
    mutation(mutationData, {
      onSuccess: (data) => {
        sessionStorage.removeItem('paymentData');
        if (data.success) {
          toast.success(successMessage);
          navigate('/admin');
        } else {
          toast.error(data.error || '처리에 실패했습니다. 다시 시도해주세요.');
          navigate('/admin');
        }
      },
      onError: (error) => {
        sessionStorage.removeItem('paymentData');
        toast.error('처리 중 오류가 발생했습니다.');
        navigate('/admin/payment/plans');
      }
    });
  }, [searchParams, issueBilling, convertToSubscription, updateCard, navigate]);

  return (
    <div className="min-h-screen text-center flex justify-center items-center">
      {isPending ? '처리 중입니다... 페이지를 이동하지 마세요.' : '잠시만 기다려주세요... 페이지를 이동하지 마세요.'}
    </div>
  );
};

export default PaymentSuccess;

// import { useEffect } from 'react';
// import { useNavigate, useSearchParams } from 'react-router-dom';
// import { useConvertToSubscription, useIssueBilling } from '@/builder/queries/paymentQueries';
// import toast from 'react-hot-toast';

// const PaymentSuccess = () => {
//   const navigate = useNavigate();
//   const [searchParams] = useSearchParams();
//   const { mutate: issueBilling, isPending: isIssuePending } = useIssueBilling();
//   const { mutate: convertToSubscription, isPending: isConvertPending } = useConvertToSubscription();

//   const isPending = isIssuePending || isConvertPending;

//   useEffect(() => {
//     const authKey = searchParams.get('authKey');
//     const customerKey = searchParams.get('customerKey');

//     const paymentDataString = sessionStorage.getItem('paymentData');
//     if (!paymentDataString || !authKey || !customerKey) {
//       toast.error('오류가 발생했습니다. 다시 시도해주세요.');
//       navigate('/admin/payment/plans');
//       return;
//     }
//     const paymentData = JSON.parse(paymentDataString);

//     const mutationData = {
//       authKey,
//       customerKey,
//       paymentData: {
//         plan: paymentData.plan,
//         billingCycle: paymentData.billingCycle,
//         selectedTemplate: paymentData.selectedTemplate
//       }
//     };

//     const mutation = paymentData.isConversion ? convertToSubscription : issueBilling;
    
//     mutation(mutationData, {
//       onSuccess: (data) => {
//         sessionStorage.removeItem('paymentData');
//         if (data.success) {
//           toast.success('카드가 성공적으로 등록되었습니다.');
//           navigate('/admin');
//         } else {
//           toast.error(data.error || '카드 등록에 실패했습니다. 다시 시도해주세요.');
//           navigate('/admin');
//         }
//       },
//       onError: (error) => {
//         sessionStorage.removeItem('paymentData');
//         toast.error('카드 등록 중 오류가 발생했습니다.');
//         navigate('/admin/payment/plans');
//       }
//     });
//   }, [searchParams, issueBilling, convertToSubscription, navigate]);

//   return (
//     <div className="min-h-screen text-center flex justify-center items-center">
//       {isPending ? '카드등록 중입니다...' : '잠시만 기다려주세요...'}
//     </div>
//   );
// };

// export default PaymentSuccess;