import { useMutation } from '@tanstack/react-query';
import { api } from '../utils/api';
import { handleQueryError } from '../utils/queryErrorHandler';
import { useNavigate } from 'react-router-dom';

interface SlackMessagePayload {
  message: string;
  type: 'DEFAULT' | 'DNS';
}

export const useDomainMessage = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (payload: SlackMessagePayload) => {
      const { data } = await api.post('/api/domain/message', payload);
      return data;
    },
    onSuccess: () => {
      // toast.success('알림이 전송되었습니다.');
      console.log('메세지 전송에 성공했습니다.')
    },
    onError: (error) => handleQueryError(error, navigate)
  });
};