import { useState } from 'react';
import { ChevronDown, Eye, File } from 'lucide-react';
import DomainPurchaseGuide from './DomainPurchaseGuide';
import toast from 'react-hot-toast';
import { useUser } from '@/builder/stores/authStore';
import { useTemplateStore } from '@/shared/stores/templateStore';
import { useNavigate } from 'react-router-dom';
import { useDomainMessage } from '@/builder/queries/domainQueries';

interface ButtonProps {
 onClick: () => void;
 children: React.ReactNode;
 className?: string;
}

const Button = ({ onClick, children, className = '' }: ButtonProps) => (
 <button
   onClick={onClick}
   className={`w-full p-4 text-left border rounded-lg font-medium text-gray-700 bg-white hover:bg-gray-50 ${className}`}
 >
   {children}
 </button>
);

interface QuestionProps {
 children: React.ReactNode;
}

const Question = ({ children }: QuestionProps) => (
 <h2 className="text-lg font-semibold mb-4">{children}</h2>
);

interface InputFieldProps {
 label: string;
 [key: string]: any;
}

const InputField = ({ label, ...props }: InputFieldProps) => (
 <div className="space-y-2">
   <label className="block text-sm text-gray-700">{label}</label>
   <input
     className="w-full p-2 border rounded-md"
     {...props}
   />
 </div>
);

interface SubmitButtonProps {
 onClick: () => void;
 children?: React.ReactNode;
}

const SubmitButton = ({ onClick, children = "제출하기" }: SubmitButtonProps) => (
 <div className='flex justify-center'>
   <button
     onClick={onClick}
     className="w-[120px] bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 mt-4"
   >
     {children}
   </button>
 </div>
);

interface PurchaseGuideProps {
 isGuideOpen: boolean;
 setIsGuideOpen: (value: boolean) => void;
}

const PurchaseGuide = ({ isGuideOpen, setIsGuideOpen }: PurchaseGuideProps) => (
 <div className="space-y-4">
   <button
     onClick={() => setIsGuideOpen(!isGuideOpen)}
     className="w-full p-4 flex justify-between items-center bg-white text-gray-700 border rounded-lg hover:bg-gray-50"
   >
     <div className='flex gap-2'>
       <File/> <span className="font-medium">구입 가이드 보기</span>
     </div>
     <ChevronDown className={`w-5 h-5 transition-transform ${isGuideOpen ? 'transform rotate-180' : ''}`} />
   </button>
   
   {isGuideOpen && (
     <div className="border rounded-lg bg-white p-6">
       <DomainPurchaseGuide />
     </div>
   )}
 </div>
);

interface DirectDNSSetupProps {
 onSubmit: () => void;
}

const DirectDNSSetup = ({ onSubmit }: DirectDNSSetupProps) => (
 <div className="space-y-4">
   <div className="bg-white p-4 rounded-lg border">
     <p className="text-gray-700 leading-relaxed">
       직접 DNS 설정을 원하시면 아래의 제출 버튼을 눌러주세요.<br/>
       그러면 가입한 이메일로 네임서버가 전송됩니다. (영업일 2~3일 소요)<br/>
       해당 네임서버로 도메인 DNS 설정을 해주시길 바랍니다.
     </p>
   </div>
   <SubmitButton onClick={onSubmit} />
 </div>
);

interface RequestDNSSetupProps {
 hasDomain: boolean;
 domainProvider: string;
 providerId: string;
 providerPassword: string;
 setDomainProvider: (value: string) => void;
 setProviderId: (value: string) => void;
 setProviderPassword: (value: string) => void;
 onSubmit: () => void;
}

const RequestDNSSetup = ({
 hasDomain,
 domainProvider,
 providerId,
 providerPassword,
 setDomainProvider,
 setProviderId,
 setProviderPassword,
 onSubmit
}: RequestDNSSetupProps) => (
 <div className="space-y-4">
   {hasDomain && (
     <InputField 
       label="도메인 구입사이트 이름 (예: 카페24, 가비아, 닷홈 등)"
       type="text"
       value={domainProvider}
       onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDomainProvider(e.target.value)}
     />
   )}
   <InputField 
     label={`${hasDomain ? '해당 사이트' : '닷홈'} ID`}
     type="text"
     value={providerId}
     onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProviderId(e.target.value)}
   />
   <InputField 
     label={`${hasDomain ? '해당 사이트' : '닷홈'} 패스워드`}
     type="password"
     value={providerPassword}
     onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProviderPassword(e.target.value)}
   />
   <SubmitButton onClick={onSubmit} />
 </div>
);

const DomainConnectionContent = () => {
  const navigate = useNavigate();
 const [hasDomain, setHasDomain] = useState(false);
 const [step, setStep] = useState('initial');
 const [subStep, setSubStep] = useState('');
 const [dnsSetupType, setDnsSetupType] = useState('');
 const [isGuideOpen, setIsGuideOpen] = useState(false);
 
 // DNS 설정 관련 상태
 const [domainProvider, setDomainProvider] = useState('');
 const [providerId, setProviderId] = useState('');
 const [providerPassword, setProviderPassword] = useState('');

 const domainMessage = useDomainMessage();
 const { email } = useUser() || {};
 const { selectedTemplate } = useTemplateStore();
 
 const handleSelfSetup = async () => {
   try {
     const isDirectSetup = subStep === 'direct' || dnsSetupType === 'direct';
     
     if (!isDirectSetup && (!providerId || !providerPassword)) {
       toast.error('모든 정보를 입력해주세요.');
       return;
     }

     await domainMessage.mutateAsync({ 
       message:
         isDirectSetup
           ? '(도메인 연결 | 직접)\n' +
             `1. ${email} / ${selectedTemplate.templateId}`
           : '(도메인 연결 | DNS)\n' +
             `1. ${email} / ${selectedTemplate.templateId}\n` +
             `2. ${hasDomain ? domainProvider : '닷홈'} / ${providerId} / ${providerPassword}`
       ,
       type: 'DNS'
     });
     toast.success(`요청이 완료되었습니다. ${isDirectSetup ? '가입한 이메일로 네임서버 정보가 전달됩니다. (영업일 2~3일 소요)' : '영업일 2~3일 내로 도메인 연결 및 배포가 진행됩니다.'}`)
     navigate('/admin')

   } catch (error) {
     console.error('메시지 전송 실패:', error);
   }
 };

 const renderStepContent = () => {
   if (step === 'initial') {
     return (
       <div className="space-y-4">
         <Question>Q1. 이미 구입한 도메인이 있으신가요?</Question>
         <Button
           onClick={() => {
             setHasDomain(true)
             setStep('hasDomain')
           }}
         >네</Button>
         <Button
           onClick={() => {
             setHasDomain(false)
             setStep('noDomain')
           }}
         >아니오</Button>
       </div>
     );
   }

   if (step === 'hasDomain') {
     if (!subStep) {
       return (
         <div className="space-y-4">
           <Question>
             Q2. 구입한 도메인을 사이트에 연결하기 위해서는 DNS 설정이 필요합니다. 직접 설정이 가능하신가요?
           </Question>
           <Button onClick={() => setSubStep('direct')}>
             네. 직접 DNS 설정을 하겠습니다.
           </Button>
           <Button onClick={() => setSubStep('request')}>
             아니오. 디스이즈웹에 DNS 설정을 요청합니다.
           </Button>
         </div>
       );
     }

     if (subStep === 'direct') return <DirectDNSSetup onSubmit={handleSelfSetup} />;
     if (subStep === 'request') return (
       <RequestDNSSetup
         hasDomain={hasDomain}
         domainProvider={domainProvider}
         providerId={providerId}
         providerPassword={providerPassword}
         setDomainProvider={setDomainProvider}
         setProviderId={setProviderId}
         setProviderPassword={setProviderPassword}
         onSubmit={handleSelfSetup}
       />
     );
   }

   if (step === 'noDomain') {
     if (!subStep) {
       return (
         <div className="space-y-6">
           <Question>
             Q2. 다음은 도메인 구입을 위한 내용입니다. 구입한 뒤에 맨 아래의 버튼을 눌러주세요.
           </Question>
           <PurchaseGuide isGuideOpen={isGuideOpen} setIsGuideOpen={setIsGuideOpen} />
           <Button onClick={() => setSubStep('purchased')}>위 가이드를 보고 닷홈에서 결제했습니다</Button>
         </div>
       );
     }

     if (subStep === 'purchased') {
       return (
         <div className="space-y-4">
           <Question>
             Q3. 구입한 도메인을 사이트에 연결하기 위해서는 DNS 설정이 필요합니다. 직접 설정이 가능하신가요?
           </Question>
           <Button onClick={() => setDnsSetupType('direct')}>
             네. 직접 DNS 설정을 하겠습니다.
           </Button>
           <Button onClick={() => setDnsSetupType('request')}>
             아니오. 디스이즈웹에 DNS 설정을 요청합니다.
           </Button>

           {dnsSetupType === 'direct' && <DirectDNSSetup onSubmit={handleSelfSetup} />}
           {dnsSetupType === 'request' && (
             <RequestDNSSetup
               hasDomain={hasDomain}
               domainProvider={domainProvider}
               providerId={providerId}
               providerPassword={providerPassword}
               setDomainProvider={setDomainProvider}
               setProviderId={setProviderId}
               setProviderPassword={setProviderPassword}
               onSubmit={handleSelfSetup}
             />
           )}
         </div>
       );
     }
   }

   return null;
 };

 return renderStepContent();
};

export default DomainConnectionContent;