// UpdateCardModal.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTossPayment } from '@/builder/hooks/useTossPayment';
import { useUser } from '@/builder/stores/authStore';
import toast from 'react-hot-toast';

interface UpdateCardModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UpdateCardModal = ({ isOpen, onClose }: UpdateCardModalProps) => {
  const { requestPayment, isLoading } = useTossPayment();
  const { email, ceoName } = useUser() || {};
  const navigate = useNavigate();

  const handleUpdateCard = async () => {
    try {
      const paymentData = {
        isUpdatingCard: true
      };
      sessionStorage.setItem('paymentData', JSON.stringify(paymentData));

      if (!email || !ceoName) {
        toast.error('로그인이 만료되었습니다. 다시 로그인해주세요.');
        navigate('/auth/login');
        return;
      }

      await requestPayment({
        customerEmail: email,
        customerName: ceoName,
      });

      onClose();
    } catch (error) {
      console.error('Payment error:', error);
      toast.error('카드 변경 중 오류가 발생했습니다.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">카드 변경</h2>
        <p className="text-gray-600 mb-6">
          새로운 카드로 변경하시겠습니까?
          변경된 카드는 다음 결제일부터 적용됩니다.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
          >
            취소
          </button>
          <button
            onClick={handleUpdateCard}
            disabled={isLoading}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
          >
            카드 변경하기
          </button>
        </div>
      </div>
    </div>
  );
};