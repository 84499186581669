import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CalendarDays, AlertCircle } from 'lucide-react';
import { useGroupAnimation } from '@/shared/hooks/animation/useGroupAnimation';
import DivWithAnimation from '@/templates/components/common/DivWithAnimation';
import { PlanCard } from '@/builder/components/payment/PlanCard';
import { Alert, AlertDescription } from '@/shared/components/ui/alert';

const PageContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="space-y-8 max-w-4xl mx-auto">
    {children}
  </div>
);

const UserReactiveSubscription = () => {
  const groupId = useGroupAnimation(100);
  const location = useLocation();
  const selectedTemplate = location.state?.selectedTemplate;

  return (
    <PageContainer>
      {/* 현재 사용중인 템플릿 정보 */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h1 className="text-2xl font-bold mb-2">재구독하기</h1>
        <p className="text-gray-600">
          플랜 선택과 동시에 결제가 이루어지며, 템플릿이 활성화됩니다.
        </p>
      </div>

      <Alert>
        <AlertCircle className="h-4 w-4 text-blue-500 mt-1" />
        <AlertDescription>
          재구독 회원을 위하여 연간 결제 할인을 진행합니다.
        </AlertDescription>
      </Alert>

      {/* 플랜 선택 */}
      <div className="grid md:grid-cols-2 gap-6">
        <DivWithAnimation
          animation={{
            groupId: groupId,
          }}
          className="min-h-20"
        >
          <PlanCard
            isAnnual={true}
            selectedTemplate={selectedTemplate}
            isReactive={true}
          />
        </DivWithAnimation>
        <DivWithAnimation
          animation={{
            groupId: groupId,
          }}
          className="min-h-20"
        >
          <PlanCard
            isAnnual={false}
            selectedTemplate={selectedTemplate}
            isReactive={true}
          />
        </DivWithAnimation>
      </div>
    </PageContainer>
  );
};

export default UserReactiveSubscription;