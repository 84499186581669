// PaymentCard.tsx
import React from 'react';
import { CreditCard } from 'lucide-react';

interface PaymentCardProps {
  cardInfo: {
    cardCompany: string;
    cardType: string;
    cardNumber: string;
  };
  onChangeCard: () => void;
}

export const PaymentCard = ({ cardInfo, onChangeCard }: PaymentCardProps) => {
  return (
    <div className="bg-white rounded-lg border shadow-sm">
      <div className="p-6">
        <h2 className="text-lg font-semibold mb-4">결제 수단</h2>
        <div className="p-4 border rounded-lg flex items-center space-x-4">
          <CreditCard className="w-8 h-8 text-gray-500" />
          <div>
            <p className="font-medium">{cardInfo.cardCompany} {cardInfo.cardType}카드</p>
            <p className="text-gray-600">{cardInfo.cardNumber}</p>
          </div>
        </div>
      </div>
      <div className="border-t p-4">
        <button
          onClick={onChangeCard}
          className="w-full px-4 py-2 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
        >
          카드 변경
        </button>
      </div>
    </div>
  );
};