import React, { useState } from 'react';
import { Check, ChevronDown, ChevronUp, X } from 'lucide-react';
import toast from 'react-hot-toast';
import SectionContainer from '@/templates/components/common/SectionContainer';
import ProgressCircle from '@/templates/components/indicators/progress/ProgressCircle';
import stepsData from '@/builder/config/steps.json'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRegister } from '@/builder/queries/authQueries';

const Card = ({ children, className = '' }: any) => (
  <div className={`bg-slate-50 border-2 border-slate-200 rounded-xl shadow-lg ${className}`}>
    {children}
  </div>
);

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTemplate = location.state?.selectedTemplate;

  const steps = stepsData.data;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
    ceoName: '',
    companyName: '',
    taxId: '',
    phoneNumber: '',
    selectedTemplate: selectedTemplate || {},
    message: '',
    couponCode: '',
    isAbove14: false,
    agreedToService: false,
    agreedToPrivacy: false,
    agreedToMarketing: false,
    isTermsExpanded: false,
    agreedToAll: false
  });

  const [serviceAgreed, setServiceAgreed] = useState(false);

  const passwordsMatch = formState.password && formState.passwordConfirm && 
                        formState.password === formState.passwordConfirm;

  const validateCouponCode = (code: string): boolean => {
    if (!code.startsWith('thisisfree')) return false;
    if (code === 'thisisfree') return false;    // thisisfree만 입력한 경우는 invalid
    const months = parseInt(code.replace('thisisfree', ''));
    return !isNaN(months) && months >= 1 && months <= 12;
  };  
  const couponMatch = validateCouponCode(formState.couponCode);

  const handleAgreeToAll = (checked: any) => {
    setFormState(prev => ({
      ...prev,
      agreedToAll: checked,
      agreedToService: checked,
      agreedToPrivacy: checked,
      agreedToMarketing: checked
    }));
  };


  const validateForm = () => {
    if (!formState.isAbove14) {
      toast.error('만 14세 이상만 가입할 수 있습니다.');
      return false;
    }

    if (!formState.agreedToService || !formState.agreedToPrivacy) {
      toast.error('필수 약관에 동의해주세요.');
      return false;
    }

    if (!formState.email || !formState.password || !formState.ceoName) {
      toast.error('모든 내용을 입력해 주세요.');
      return false;
    }

    if (formState.password.length < 8) {
      toast.error('비밀번호는 8자 이상이어야 합니다.');
      return false;
    }

    if (!passwordsMatch) {
      toast.error('비밀번호가 일치하지 않습니다.');
      return false;
    }

    if (!serviceAgreed) {
      toast.error('서비스 이용 동의가 필요합니다.');
      return false;
    }

    return true;
  };

  const { mutate: register, isPending } = useRegister();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isSubmitting) return;  // 이미 제출 중이면 리턴

    // 폼 유효성 검사
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);  // 제출 시작
    const toastId = toast.loading('회원가입 중입니다...');
    register(
      { 
        email: formState.email,
        password: formState.password,
        ceoName: formState.ceoName,
        companyName: formState.companyName,
        taxId: formState.taxId,
        phoneNumber: formState.phoneNumber,
        selectedTemplate: selectedTemplate,
        couponCode: formState.couponCode,
        agreedToService: formState.agreedToService,
        agreedToPrivacy: formState.agreedToPrivacy,
        agreedToMarketing: formState.agreedToMarketing
      },
      {
        onSuccess: () => {
          toast.success('회원가입이 완료되었습니다.', { id: toastId });
          navigate('/auth/login');
        },
        onError: (error: any) => {
          const errorMessage = error.response?.data?.error || '회원가입에 실패했습니다';
          toast.error(errorMessage);
          
          if (errorMessage.includes('already exists')) {
            toast.error('이미 사용 중인 이메일입니다', { id: toastId });
          }
        },
        onSettled: () => {
          setIsSubmitting(false);  // 성공/실패 상관없이 제출 상태 해제
        }
      }
    );

    // 쿠폰 코드
    if (couponMatch) {
      return
    }
  };

  return (
    <SectionContainer
      backgroundImage={{
        src: '',
        styles: {
          overlay: {
            color: '#000000',
          }
        }
      }}
    >
      <div className="mb-28">
        <ProgressCircle
          steps={steps}
          currentStep={2}
        />
      </div>

      <div className="container mx-auto">
        <h1 className="text-4xl font-bold text-center text-white mb-12">회원정보 입력</h1>
        <div className="flex flex-col lg:flex-row gap-8 md:gap-12">
          {/* Left Column - Forms */}
          <div className="lg:w-3/5 space-y-8 md:space-y-12">

            {/* <div>
              <h2 className="text-xl text-slate-200 font-normal mb-4">1. 플랜을 선택하세요.</h2>
              <Card className="p-6 space-y-4">
                <div>
                  <label className="block text-base font-medium text-gray-700">선택한 템플릿</label>
                  <div className="flex items-center gap-2 bg-slate-400 border-gray-300 rounded-md shadow-md mt-1 py-2 px-3 ">
                    {selectedTemplate ? (
                      <>
                        <span>{selectedTemplate.name}</span>
                        <a
                          href={selectedTemplate.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-red-500 hover:underline text-base"
                        >
                          [미리보기]
                        </a>
                      </>
                    ) : (
                      <span>템플릿을 선택해주세요</span>
                    )}
                  </div>
                </div>
              </Card>
            </div> */}



            {/* Sign Up */}
            <div>
              <h2 className="text-xl text-slate-200 font-normal mb-4">1. 회원정보를 입력하세요.</h2>
              <Card className="p-6">
                <div className="space-y-4">
                  <div>
                    <label className="block text-base font-medium text-gray-700">이메일</label>
                    <input
                      type="email"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-md p-2"
                      value={formState.email}
                      onChange={(e) => setFormState(prev => ({ ...prev, email: e.target.value }))}
                    />
                  </div>
                  <div className="relative">
                    <label className="block text-base font-medium text-gray-700">비밀번호</label>
                    <input
                      type="password"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-md p-2"
                      value={formState.password}
                      placeholder='8자 이상 작성'
                      onChange={(e) => setFormState(prev => ({ ...prev, password: e.target.value }))}
                    />
                  </div>
                  <div className="relative">
                    <label className="block text-base font-medium text-gray-700">비밀번호 확인</label>
                    <div className="relative">
                      <input
                        type="password"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-md p-2"
                        value={formState.passwordConfirm}
                        placeholder=''
                        onChange={(e) => setFormState(prev => ({ ...prev, passwordConfirm: e.target.value }))}
                      />
                      {formState.passwordConfirm && (
                        <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                          {passwordsMatch ? (
                            <Check className="text-green-500 w-5 h-5" />
                          ) : (
                            <X className="text-red-500 w-5 h-5" />
                          )}
                        </div>
                      )}
                    </div>
                    {formState.passwordConfirm && !passwordsMatch && (
                      <p className="text-red-500 text-sm mt-1">비밀번호가 일치하지 않습니다.</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-base font-medium text-gray-700">성함</label>
                    <input
                      type="text"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-md p-2"
                      value={formState.ceoName}
                      onChange={(e) => setFormState(prev => ({ ...prev, ceoName: e.target.value }))}
                    />
                  </div>
                  <div>
                    <label className="block text-base font-medium text-gray-700">연락처</label>
                    <input
                      type="number"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-md p-2"
                      value={formState.phoneNumber}
                      onChange={(e) => setFormState(prev => ({ ...prev, phoneNumber: e.target.value }))}
                      placeholder="01012345678"
                    />
                  </div>
                </div>
              </Card>
            </div>

            {/* Terms */}
            <div>
              <h2 className="text-xl text-slate-200 font-normal mb-4">2. 약관을 확인하세요.</h2>

              <Card className="p-6">
                <div className="space-y-4">
                  <div className="flex items-start">
                    <input
                      type="checkbox"
                      className="mt-1 mr-2"
                      checked={serviceAgreed}
                      onChange={(e) => setServiceAgreed(e.target.checked)}
                    />
                    <label className="text-base">
                      아래의 내용에 대해 동의합니다:
                      <ul className="md:ml-2 mt-2 leading-relaxed text-gray-600">
                        <li className='text-red-500'>• 불법업종으로 확인되는 경우 이용이 제한될 수 있습니다.</li>
                        <li>• 도메인은 별도 구매가 필요합니다. (구매 가이드를 전달드립니다)</li>
                        <li>• 직접 업로드 이미지 저장 용량은 총 50장입니다. (각 2MB 이하)</li>
                      </ul>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="age14"
                      className="mr-2"
                      checked={formState.isAbove14}
                      onChange={(e) => setFormState(prev => ({ ...prev, isAbove14: e.target.checked }))}
                    />
                    <label htmlFor="age14">만 14세 이상입니다</label>
                  </div>

                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          id="agreeToAll"
                          checked={formState.agreedToAll}
                          onChange={(e) => handleAgreeToAll(e.target.checked)}
                          className="rounded"
                        />
                        <label htmlFor="agreeToAll" className="font-medium">모든 약관에 동의합니다</label>
                      </div>
                      <button
                        type="button"
                        onClick={() => setFormState(prev => ({ ...prev, isTermsExpanded: !prev.isTermsExpanded }))}
                        className="text-gray-500 hover:text-gray-700"
                      >
                        {formState.isTermsExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                      </button>
                    </div>

                    {formState.isTermsExpanded && (
                      <div className="space-y-3 pl-4">
                        <div className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            id="serviceTerms"
                            checked={formState.agreedToService}
                            onChange={(e) => {
                              setFormState(prev => ({
                                ...prev,
                                agreedToService: e.target.checked,
                                agreedToAll: e.target.checked && prev.agreedToPrivacy && prev.agreedToMarketing
                              }));
                            }}
                          />
                          <label htmlFor="serviceTerms">서비스 이용약관 동의 (필수)</label>
                          <a
                            href="https://husky-coffee-14c.notion.site/149c3ee6db8d8009bd14f4c2a94b5de9"
                            target="_blank"
                            className="text-red-500 hover:underline text-base"
                          >
                            [약관보기]
                          </a>
                        </div>

                        <div className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            id="privacyTerms"
                            checked={formState.agreedToPrivacy}
                            onChange={(e) => {
                              setFormState(prev => ({
                                ...prev,
                                agreedToPrivacy: e.target.checked,
                                agreedToAll: e.target.checked && prev.agreedToService && prev.agreedToMarketing
                              }));
                            }}
                          />
                          <label htmlFor="privacyTerms">개인정보 처리방침 동의 (필수)</label>
                          <a
                            href="https://husky-coffee-14c.notion.site/149c3ee6db8d8019a5fcdfb5fa1bff1e"
                            target="_blank"
                            className="text-red-500 hover:underline text-base"
                          >
                            [약관보기]
                          </a>
                        </div>

                        <div className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            id="marketingTerms"
                            checked={formState.agreedToMarketing}
                            onChange={(e) => {
                              setFormState(prev => ({
                                ...prev,
                                agreedToMarketing: e.target.checked,
                                agreedToAll: e.target.checked && prev.agreedToService && prev.agreedToPrivacy
                              }));
                            }}
                          />
                          <label htmlFor="marketingTerms">마케팅 정보 수신 동의 (선택)</label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </div>
          </div>

          {/* Right Column - Order Summary */}
          <div className="lg:w-2/5">
            <div className="sticky top-8">
              <div>
                <h2 className="text-xl text-slate-200 font-normal mb-4">선택 내역</h2>
                <Card className="p-6">
                  <div className="space-y-4">
                    
                      <div className="flex justify-between font-medium">
                        <span className="text-gray-700">선택한 템플릿</span>
                        <span>{selectedTemplate ? (
                      <>
                        <span>{selectedTemplate.name}</span>
                        <a
                          href={selectedTemplate.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ml-2 text-red-500 hover:underline text-base"
                        >
                          [미리보기]
                        </a>
                      </>
                    ) : (
                      <>
                        <span>템플릿을 선택해주세요</span>
                        <Link
                          to="/selection"
                          rel="noopener noreferrer"
                          className="ml-2 text-red-500 hover:underline text-base"
                        >
                          [뒤로가기]
                        </Link>
                      </>
                    )}</span>
                      </div>

                    <div className="border-t mt-4"></div>

                    <div className="relative">
                      <input
                        type="text"
                        className="block w-full border-gray-300 rounded-md shadow-md p-2"
                        value={formState.couponCode}
                        placeholder="추천인 코드"
                        onChange={(e) => setFormState(prev => ({ ...prev, couponCode: e.target.value }))}
                      />
                      {formState.couponCode && (
                        <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                          {couponMatch ? (
                            <Check className="text-green-500 w-5 h-5" />
                          ) : (
                            <X className="text-red-500 w-5 h-5" />
                          )}
                        </div>
                      )}
                    </div>
                    {formState.couponCode
                      ? (couponMatch
                        ? <p className="text-green-500 text-sm ">쿠폰이 적용되었습니다.</p>
                        : <p className="text-red-500 text-sm ">쿠폰코드가 일치하지 않습니다.</p>)
                      : ""
                    }
                    
                    <button 
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      className="w-full py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors disabled:bg-gray-400"
                    >
                      {isSubmitting ? '가입중...' : '무료 회원가입'}
                    </button>
                    <div className='mt-2 text-right'>
                      <a
                        href='https://husky-coffee-14c.notion.site/FAQ-14bc3ee6db8d802c8728fc6b13199a2f'
                        target='_blank'
                        className="font-light text-sm text-gray-500 underline"
                      >오류가 발생했어요.</a>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default Register;