export const PER_MONTH_WITHOUT_VAT = {
  MONTHLY: 20000,    // 1개월 구독 금액
  YEARLY: 12000     // 12개월 구독 금액
} as const;


export const SUBSCRIPTION_AMOUNTS = {
  MONTHLY: PER_MONTH_WITHOUT_VAT.MONTHLY * 1.1,    // 1개월 구독 금액
  YEARLY: (PER_MONTH_WITHOUT_VAT.YEARLY * 12) * 1.1     // 12개월 구독 금액
} as const;

export const BILLING_CYCLES = {
  MONTHLY: 1,
  YEARLY: 12
} as const;

export const SUBSCRIPTION_NAMES = {
  MONTHLY: '월간 구독',
  YEARLY: '연간 구독'
} as const;

// 구독 기간에 따른 금액 계산 함수
export const getBillingAmount = (billingCycle: number): number => {
  switch (billingCycle) {
    case BILLING_CYCLES.MONTHLY:
      return SUBSCRIPTION_AMOUNTS.MONTHLY;
    case BILLING_CYCLES.YEARLY:
      return SUBSCRIPTION_AMOUNTS.YEARLY;
    default:
      throw new Error('Invalid billing cycle');
  }
};

// 구독 기간에 따른 주문명 생성 함수
export const getOrderName = (billingCycle: number): string => {
  switch (billingCycle) {
    case BILLING_CYCLES.MONTHLY:
      return SUBSCRIPTION_NAMES.MONTHLY;
    case BILLING_CYCLES.YEARLY:
      return SUBSCRIPTION_NAMES.YEARLY;
    default:
      throw new Error('Invalid billing cycle');
  }
};