export const quillStyles = `
  /* 에디터 내용 영역의 스타일 재설정 */
  .ql-editor {
    /* 기본 텍스트 스타일 */
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    color: #000 !important;
  }
  
  /* 모든 인라인 스타일 무시 */
  .ql-editor * {
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    letter-spacing: inherit !important;
    color: inherit !important;
    text-align: inherit !important;
    
    /* 이탤릭과 밑줄 스타일 제거 */
    font-style: normal !important;
    text-decoration: none !important;
  }

  /* em 태그와 u 태그에 대한 추가 오버라이드 */
  .ql-editor em,
  .ql-editor u {
    font-style: normal !important;
    text-decoration: none !important;
  }
    
  /* Color Picker 관련 스타일 추가 */
  .ql-toolbar input[type="color"] {
    padding: 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 30px;
    width: 40px;
  }

  .ql-toolbar input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  .ql-toolbar input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }
`;