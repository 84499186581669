import React from 'react';
import EditableWrapper from '@/builder/components/editor/EditableWrapper';
import { usePageStore } from '@/shared/stores/pageStore';
import { useTemplateStore } from '@/shared/stores/templateStore';

const PAGE_COMPONENTS = {
 template1: () => import('@/templates/template1/pages'),
 template2: () => import('@/templates/template2/pages'),
 template3: () => import('@/templates/template3/pages'),
 template4: () => import('@/templates/template4/pages'),
};

const useTemplateComponents = () => {
 const [PageComponents, setPageComponents] = React.useState<any>(null);
 const { selectedTemplate } = useTemplateStore();

 React.useEffect(() => {
   const loadComponents = async () => {
     const templateType = selectedTemplate?.type || 'template1';
     const importFunc = PAGE_COMPONENTS[templateType as keyof typeof PAGE_COMPONENTS];
     
     if (importFunc) {
       const module = await importFunc();
       setPageComponents(module);
     }
   };

   loadComponents();
 }, [selectedTemplate]);

 return PageComponents;
};

interface PageWrapperProps {
 children: React.ReactNode;
}

const PageWrapper = ({ children }: PageWrapperProps) => {
 const PageComponents = useTemplateComponents();

 if (!PageComponents) return null;

 return (
   <EditableWrapper isEditMode={true}>
     <div className="flex flex-col min-h-screen">
       <div className="flex-grow">{children}</div>
       <PageComponents.Footer />
     </div>
   </EditableWrapper>
 );
};

const PageRenderer = () => {
 const currentPage = usePageStore((state) => state.currentPage);
 const PageComponents = useTemplateComponents();

 if (!PageComponents) return null;

 const renderPage = () => {
   switch (currentPage) {
     case '':
       return (
         <PageWrapper>
           <PageComponents.Home />
         </PageWrapper>
       );

     case 'about':
       return (
         <PageWrapper>
           <PageComponents.AboutHome />
         </PageWrapper>
       );

     case 'about/team':
       return (
         <PageWrapper>
           <PageComponents.AboutTeam />
         </PageWrapper>
       );

     case 'about/history':
       return (
         <PageWrapper>
           <PageComponents.AboutHistory />
         </PageWrapper>
       );

     case 'about/location':
       return (
         <PageWrapper>
           <PageComponents.AboutLocation />
         </PageWrapper>
       );

     case 'services':
       return (
         <PageWrapper>
           <PageComponents.ServicesHome />
         </PageWrapper>
       );

     case 'products':
       return (
         <PageWrapper>
           <PageComponents.ProductsHome />
         </PageWrapper>
       );

     case 'contact':
       return (
         <PageWrapper>
           <PageComponents.ContactHome />
         </PageWrapper>
       );

     default:
      return <div>이 페이지는 존재하지 않습니다. 이는 메뉴명 변경용으로 넣어둔 요소이니 참고바랍니다.</div>;
   }
 };

 return renderPage();
};

export default PageRenderer;

