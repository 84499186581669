import { useEffect, useRef } from 'react';
import { useAnimationStore } from '@/shared/stores/animationStore';

export const useGroupAnimation = (delay: number = 300) => {
  const createGroup = useAnimationStore(state => state.createGroup);
  // useRef를 사용하여 컴포넌트 생명주기 동안 일관된 groupId 유지
  const groupIdRef = useRef<string>(`group1-${Math.random()}`);

  useEffect(() => {
    createGroup(groupIdRef.current, delay);
  }, [createGroup, delay]);

  return groupIdRef.current;
};

// export const useGroupAnimation = (delay: number = 300) => {
//   const createGroup = useAnimationStore(state => state.createGroup);
//   const groupId = `group1-${Math.random()}`;

//   useEffect(() => {
//     createGroup(groupId, delay);
//   }, [createGroup, groupId, delay]);

//   return groupId;
// };