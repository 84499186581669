import { DEFAULT_ANIMATION } from "@/shared/constants/styles";
import { useAnimation } from "@/shared/hooks/animation/useAnimation";


const DivWithAnimation = ({
  children,
  // groupId,
  className,
  animation = {}
}: any) => {

  const [ref, animationStyles] = useAnimation({
    ...DEFAULT_ANIMATION,  // 기본값을 먼저 펼치고
    ...animation          // 전달받은 값으로 덮어쓰기
  });
  // const [ref, animationStyles] = useAnimation(
  //   {
  //     groupId,
  //     ...DEFAULT_ANIMATION
  //   }
  // );
 

  return (
    <div
      ref={ref}
      style={animationStyles}
      className={className}
    >
      {children}
    </div>
);
};

export default DivWithAnimation;